import React from "react";
import {
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react';
import Table, { COLUMN_TYPE } from "components/table/Table";


export default function AddForm({ data }: { data?: any }) {

    const tableColumnsData = [
        { type: COLUMN_TYPE.DATETIME, label: 'Fecha', name: 'created_at', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
        { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
    ];

    return (<>
        <Grid
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
            }}
            gap={6}
            rowGap={12}
        >
            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        ID:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.id}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Nombre:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.name}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Perfil:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.settlementProfileID}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        AUM:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        ${data.AUM?.toLocaleString()}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Activo:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.isActive ? 'Si' : 'No'}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Default:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.isDefault ? 'Si' : 'No'}
                </Text>
            </GridItem>


            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Creado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.created_at).toLocaleDateString() + ' ' + new Date(data.created_at).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Actualizado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.updated_at).toLocaleDateString() + ' ' + new Date(data.updated_at).toLocaleTimeString()}
                </Text>
            </GridItem>

        </Grid>
        <br />
        <br />

        <div className="text-l font-bold text-navy-700 dark:text-white mt-10">
            AUM Historico:
        </div>
        <Grid>
            <GridItem>
                <Table
                    name={`Partner_AUM`}
                    request={false}
                    data={data.AUMHistory}
                    columns={tableColumnsData}
                    addExport={true}
                />
            </GridItem>
        </Grid>
    </>);
}