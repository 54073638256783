import List from "./components/List";
import { PartnersProvider } from './context/providers/PartnersProvider';

const Tables = () => {
  return (
    <PartnersProvider>
      <List />
    </PartnersProvider>
  );
};

export default Tables;
