import EditForm from "./components/EditForm";
import { PartnersProvider } from './context/providers/PartnersProvider';

const EditFormView = () => {
  return (
    <PartnersProvider>
      <EditForm></EditForm>
    </PartnersProvider>
  );
};

export default EditFormView;
