import { partnerContextType, Action } from '../types/partner';
import { transationTypes } from '../actions/PartnerActionTypes';

//the initial state of the user
export const initialState: partnerContextType = {
  request: false,
  notification: null,
  error: null,
  partners: { meta:{}, links: {}, data: [] },
  partner: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: partnerContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.PARTNERS_GET:
      return {
        ...state,
        request: true,
        error: null,
        partner: null
      };
    case transationTypes.PARTNERS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        partners: action.data,
        partner: null
      };


    //GET
    case transationTypes.PARTNER_GET:
      return {
        ...state,
        request: true,
        partner: null
      };
    case transationTypes.PARTNER_GET_SUCCESS:
      return {
        ...state,
        request: false,
        partner: action.data,
      };


    //ADD
    case transationTypes.PARTNER_ADD:
      return {
        ...state,
        request: true,
        partner: null
      };

    case transationTypes.PARTNER_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        partner: action.data,
      };




    //NOTIFY
    case transationTypes.PARTNER_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.PARTNER_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.PARTNER_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.PARTNER_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.PARTNER_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.PARTNER_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.PARTNER_UPDATE_GET_SUCCESS:
      return {
        ...state,
        partner: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};