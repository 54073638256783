import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Clients from "views/admin/clients";

import Users from "views/admin/users";
import EditUser from "views/admin/users/edit";


import CircuitTransfers from "views/admin/circuitTransfer";
import AddCircuitTransfer from "views/admin/circuitTransfer/add";
import EditCircuitTransfer from "views/admin/circuitTransfer/edit";


import Accounts from "views/admin/accounts";
import EditAccount from "views/admin/accounts/edit";
import ViewAccount from "views/admin/accounts/view";


import Transactions from "views/admin/transactions";
import EditTransactions from "views/admin/transactions/edit";

import Settlements from "views/admin/settlements";
import EditSettlements from "views/admin/settlements/edit";

import Deposits from "views/admin/deposits";
import EditDeposits from "views/admin/deposits/edit";

import Withdrawals from "views/admin/redemptions";
import EditWithdrawals from "views/admin/redemptions/edit";

import Fees from "views/admin/fees";

import Partners from "views/admin/partners";
import EditPartners from "views/admin/partners/edit";

import BankAccounts from "views/admin/bankAccounts";
import EditBankAccounts from "views/admin/bankAccounts/edit";



// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdAccountBalanceWallet,
  MdPerson,
  MdPeople,
  MdLock,
  MdCurrencyExchange,
  MdSupervisorAccount,
  MdAttachMoney,
  MdOutlineRemoveRoad,
  MdAddchart,
  MdMoney,
  MdPeopleAlt,
  MdPriceCheck,
  MdPriceChange
} from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },

  {
    name: "Usuarios",
    layout: "/admin",
    path: "clients",
    rol: 'admin',
    icon: <MdSupervisorAccount className="h-6 w-6" />,
    component: <Clients />,
  },

  {
    name: "Circuito de Transferencia",
    layout: "/admin",
    hide: true,
    path: "circuit-transfer",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <CircuitTransfers />,
  },

  {
    name: "Agregar Transacción",
    hide: true,
    layout: "/admin",
    path: "circuit-transfer/add",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <AddCircuitTransfer />,
  },
  
  {
    name: "Editar Transacción",
    hide: true,
    layout: "/admin",
    path: "circuit-transfer/edit/:id",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <EditCircuitTransfer />,
  },

  {
    name: "Sign In",
    hide: true,
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },

  {
    name: "Perfil",
    hide: true,
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },


  {
    name: "DriveWealth Usuarios",
    layout: "/admin",
    path: "users",
    icon: <MdPeople className="h-6 w-6" />,
    component: <Users />,
  },

  {
    name: "Editar Usuario",
    hide: true,
    layout: "/admin",
    path: "users/view/:id/:screen?",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <EditUser />,
  },

  {
    name: "DriveWealth Cuentas",
    layout: "/admin",
    path: "accounts",
    icon: <MdAccountBalanceWallet className="h-6 w-6" />,
    component: <Accounts />,
  },

  {
    name: "Editar Cuenta",
    hide: true,
    layout: "/admin",
    path: "accounts/edit/:id",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <EditAccount />,
  },
 
  {
    name: "Ver Cuenta",
    hide: true,
    layout: "/admin",
    path: "accounts/view/:id/:screen?",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <ViewAccount />,
  },

  {
    name: "Transacciones",
    layout: "/admin",
    path: "transactions",
    icon: <MdAttachMoney className="h-6 w-6" />,
    component: <Transactions />,
  },

  {
    name: "Detalle DriveWealth Transacciones",
    hide: true,
    layout: "/admin",
    path: "transactions/view/:id",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <EditTransactions />,
  },

  {
    name: "Liquidaciones",
    layout: "/admin",
    path: "settlements",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <Settlements />,
  },

  {
    name: "Detalle Liquidacion",
    hide: true,
    layout: "/admin",
    path: "settlement/:id",
    icon: <MdCurrencyExchange className="h-6 w-6" />,
    component: <EditSettlements />,
  },

  {
    name: "Depositos",
    layout: "/admin",
    path: "deposits",
    icon: <MdAddchart className="h-6 w-6" />,
    component: <Deposits />,
  },

  {
    name: "Detalle Deposito",
    hide: true,
    layout: "/admin",
    path: "deposit/:id",
    icon: <MdAddchart className="h-6 w-6" />,
    component: <EditDeposits />,
  },

  {
    name: "Retiros",
    layout: "/admin",
    path: "withdrawals",
    icon: <MdOutlineRemoveRoad className="h-6 w-6" />,
    component: <Withdrawals />,
  },

  {
    name: "Detalle Retiro",
    hide: true,
    layout: "/admin",
    path: "withdrawal/:id",
    icon: <MdOutlineRemoveRoad className="h-6 w-6" />,
    component: <EditWithdrawals />,
  },
  
  {
    name: "Comisiones",
    layout: "/admin",
    path: "fees",
    icon: <MdMoney className="h-6 w-6" />,
    component: <Fees />,
  },

  {
    name: "Partners",
    layout: "/admin",
    path: "partners",
    rol: 'admin',
    icon: <MdPeopleAlt className="h-6 w-6" />,
    component: <Partners />,
  },

  {
    name: "Detalle Partners",
    hide: true,
    layout: "/admin",
    rol: 'admin',
    path: "partner/:id",
    icon: <MdPeopleAlt className="h-6 w-6" />,
    component: <EditPartners />,
  },

  {
    name: "Cuentas Bancarias",
    layout: "/admin",
    path: "bank-accounts",
    rol: 'admin',
    icon: <MdPriceChange className="h-6 w-6" />,
    component: <BankAccounts />,
  },

  {
    name: "Detalle Cuenta Bancaria",
    hide: true,
    layout: "/admin",
    rol: 'admin',
    path: "bank-accounts/:id",
    icon: <MdPriceChange className="h-6 w-6" />,
    component: <EditBankAccounts />,
  },

];
export default routes;
