import React, { useEffect, useMemo, useState } from "react";
import { useUser } from '../context/providers/UserProvider';
import fetchData from './../../../../helpers/fetchData';
import { Input } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react'
import { COLUMN_TYPE } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { actionTypes } from '../context/reducers/UsersReducer';

export default function List() {

  const { state: { users, request }, dispatch } = useUser();
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    fetchData('users')
      .then(data => dispatch({ type: actionTypes.SET_USERS, users: data }))
      .catch(error => {/*console.error(error)*/ });
  }, [dispatch]);

  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'Nombre de Usuario', name: 'username' },
    { type: COLUMN_TYPE.TEXT, label: 'Rol', name: 'rol' },
    { type: COLUMN_TYPE.BOOLEAN, label: 'Activo', name: 'isActive' },
    { type: COLUMN_TYPE.DATETIME, label: 'Fecha Creación', name: 'created_at' }
  ];

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Buscar por texto" /></GridItem>
    <GridItem w='100%'>
    </GridItem>
    <GridItem w='100%'>
    </GridItem>
    <GridItem w='100%' />
  </Grid>, [searchText, setSearchText]);

  const gridData = useMemo(() => {


    if (searchText && searchText?.length) {
      const regexp = new RegExp(searchText, 'gi');
      return users.filter((data) => {
        return data.username.match(regexp)
          || data.rol.match(regexp);
      })
    }

    return users;
  }, [users, searchText]);

  return (<Table
    name='usuarios'
    request={request}
    data={gridData}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    addExport={false}
  />);
};
