import { Ipartner, NotifyType, IPartnerGrid } from './../types/partner.d';
import { transationTypes } from './PartnerActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.PARTNER_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.PARTNER_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET PARTNERS
export const partnersGet = () => {
  return {
    type: transationTypes.PARTNERS_GET
  }
};

export const partnersSuccess = (data: IPartnerGrid) => {
  return {
    type: transationTypes.PARTNERS_GET_SUCCESS,
    data
  }
};

export const partnersError = (data: Error) => {
  return {
    type: transationTypes.PARTNER_SET_ERROR,
    data
  }
};


//GET PARTNER
export const partnerGet = () => {
  return {
    type: transationTypes.PARTNER_GET
  }
};

export const partnerGetSuccess = (data: Ipartner) => {
  return {
    type: transationTypes.PARTNER_GET_SUCCESS,
    data
  }
};

export const partnerGetError = (data: Error) => {
  return {
    type: transationTypes.PARTNER_SET_ERROR,
    data
  }
};


