import { Iaccount, NotifyType, AccountDocument, IaccountGrid } from './../types/account.d';
import { transationTypes } from './AccountActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.ACCOUNT_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.ACCOUNT_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};

export const notifyClear = () => {
  return {
    type: transationTypes.ACCOUNT_NOTIFY_CLEAR
  }
};


//GET ACCOUNTS
export const accountsGet = () => {
  return {
    type: transationTypes.ACCOUNTS_GET
  }
};

export const accountsSuccess = (data: IaccountGrid) => {
  return {
    type: transationTypes.ACCOUNTS_GET_SUCCESS,
    data
  }
};

export const accountsClear = () => {
  return {
    type: transationTypes.ACCOUNTS_CLEAR
  }
};

export const accountsError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};




//DELETE ACCOUNT
export const accountDelete = () => {
  return {
    type: transationTypes.ACCOUNT_DELETE_GET
  }
};

export const accountDeleteSuccess = (data: any) => {
  return {
    type: transationTypes.ACCOUNT_DELETE_GET_SUCCESS,
    data
  }
};

export const accountDeleteError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};


//GET ACCOUNT
export const accountGet = () => {
  return {
    type: transationTypes.ACCOUNT_GET
  }
};

export const accountGetSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_GET_SUCCESS,
    data
  }
};

export const accountClear = () => {
  return {
    type: transationTypes.ACCOUNT_CLEAR
  }
};

export const accountGetError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};

export const accountClearError = () => {
  return {
    type: transationTypes.ACCOUNT_CLEAR_ERROR
  }
};




//UPDATE ACCOUNT
export const accountUpdate = () => {
  return {
    type: transationTypes.ACCOUNT_UPDATE_GET
  }
};

export const accountUpdateSuccess = (data: any) => {
  return {
    type: transationTypes.ACCOUNT_UPDATE_GET_SUCCESS,
    data
  }
};

export const accountUpdateError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};


//ADD ACCOUNT
export const accountAdd = () => {
  return {
    type: transationTypes.ACCOUNT_ADD
  }
};

export const accountAddSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_ADD_SUCCESS,
    data
  }
};

export const accountAddError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};

//GET PERFORMANCE
export const accountPerformanceGet = () => {
  return {
    type: transationTypes.ACCOUNT_PERFORMANCE_GET
  }
};

export const accountPerformanceSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_PERFORMANCE_GET_SUCCESS,
    data
  }
};

export const accountPerformanceError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_PERFORMANCE_GET_ERROR,
    data
  }
};

//GET ACCOUNT DOCUMENTS
export const accountStatementsGet = () => {
  return {
    type: transationTypes.ACCOUNT_STATEMENTS_GET
  }
};

export const accountStatementsSuccess = (data: AccountDocument[]) => {
  return {
    type: transationTypes.ACCOUNT_STATEMENTS_GET_SUCCESS,
    data
  }
};

export const accountTaxFormsGet = () => {
  return {
    type: transationTypes.ACCOUNT_TAXFORMS_GET
  }
};

export const accountTaxFormsSuccess = (data: AccountDocument[]) => {
  return {
    type: transationTypes.ACCOUNT_TAXFORMS_GET_SUCCESS,
    data
  }
};

export const accountDocumentsError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};

//GET ACCOUNT DOCUMENTS
export const accountDocumentUrlGet = () => {
  return {
    type: transationTypes.ACCOUNT_DOCUMENT_URL_GET
  }
};

export const accountDocumentUrlSuccess = (data: Object) => {
  return {
    type: transationTypes.ACCOUNT_DOCUMENT_URL_GET_SUCCESS,
    data
  }
};

export const accountDocumentUrlError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_SET_ERROR,
    data
  }
};

//GET DEPOSITS
export const accountDepositsGet = () => {
  return {
    type: transationTypes.ACCOUNT_DEPOSITS_GET
  }
};

export const accountDepositsSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_DEPOSITS_GET_SUCCESS,
    data
  }
};

export const accountDepositsError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_DEPOSITS_GET_ERROR,
    data
  }
};

//GET Withdrawals
export const accountWithdrawalsGet = () => {
  return {
    type: transationTypes.ACCOUNT_WITHDRAWALS_GET
  }
};

export const accountWithdrawalsSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_WITHDRAWALS_GET_SUCCESS,
    data
  }
};

//GET TRANSACTIONS
export const accountTransactionGet = () => {
  return {
    type: transationTypes.ACCOUNT_TRANSACTIONS_GET
  }
};

export const accountTransactionSuccess = (data: Iaccount) => {
  return {
    type: transationTypes.ACCOUNT_TRANSACTIONS_GET_SUCCESS,
    data
  }
};

export const accountTransactionError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_TRANSACTIONS_GET_ERROR,
    data
  }
};

export const accountWithdrawalsError = (data: Error) => {
  return {
    type: transationTypes.ACCOUNT_WITHDRAWALS_GET_ERROR,
    data
  }
};
