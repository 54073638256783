import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { MdPageview, MdOutlineSaveAlt, MdPersonOutline, MdOutlineLoupe } from "react-icons/md";
import { useBankAccount } from './../context/providers/BankAccountsProvider';
import { useNavigate } from "react-router-dom";
import * as bankAccountActions from './../context/actions/BankAccountActions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Input, Button, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { SortingState } from "@tanstack/react-table";
import EvaluationModal from './EvaluationModal';
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';

export enum statusesValues {
  UNRELIABLE_STATEMENT = "UNRELIABLE_STATEMENT",
  WRONG_STATEMENT = "WRONG_STATEMENT",
  MISSING_STATEMENT = "MISSING_STATEMENT",
  BENEFICIARY_MISMATCH = "BENEFICIARY_MISMATCH",
  DATA_MISMATCH = "DATA_MISMATCH",
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
};

export enum statusesLabel {
  UNRELIABLE_STATEMENT = "UNRELIABLE_STATEMENT - El comprobante no esta completo o no es claro",
  WRONG_STATEMENT = "WRONG_STATEMENT - El comprobante no coincide con la cuenta declarada",
  MISSING_STATEMENT = "MISSING_STATEMENT - Falta el comprobante",
  APPROVED = "APPROVED - Cuenta aprobada",
  BENEFICIARY_MISMATCH = "BENEFICIARY_MISMATCH - El propietario no coincide",
  DATA_MISMATCH = "DATA_MISMATCH - Error en los datos suministrados de la cuenta",
  REJECTED = "Rechazada - Cuenta rechazada",
  PENDING = "PENDING - Cuenta pendiente de evaluación",
};

export default function List({ userId }: { userId?: string }) {

  const navigate = useNavigate();
  const { state: { bankAccounts, request }, dispatch } = useBankAccount();
  const [toSearchDate, setToSearchDate] = useState<string>();
  const [fromSearchDate, setFromSearchDate] = useState<string>();
  const [searchState, setSearchState] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [comfirmIsOpen, setComfirmIsOpen] = useState<boolean>(false);
  const [comfirmData, setComfirmData] = useState<any>();

  const comfirmApprobed = (info: any) => {
    setComfirmData(info);
    setComfirmIsOpen(true);
  };

  const setCustomValue = (data: any) => {
    const value: statusesValues = data.getValue();
    const categoryLabel = statusesLabel[value];
    return categoryLabel || value;
  };

  const tableColumnsData = useMemo(() => ([
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Nombre', name: 'bankAccountNickname', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Usuario', name: 'brokerUser.email', export: true },
    { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Evaluacion', name: 'evaluation', export: true, setCustomValue },
    { type: COLUMN_TYPE.TEXT, label: 'Nro Cuenta', name: 'bankAccountNumber', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'createdAt', export: true },
    { type: COLUMN_TYPE.DATETIME, label: 'Fecha Actualización', name: 'updatedAt', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', getActions: (info) => {
        const bankData = info.row.original;

        return [
          {
            title: 'Ver Usuario',
            onClick: (info: any) => navigate(`/admin/users/view/${bankData.brokerUser.id}`),
            icon: <MdPersonOutline color="#422afb" className="h-8 w-8" />,
            isLoading: request
          },
          {
            title: 'Ver Detalles',
            onClick: (info: any) => navigate(`/admin/bank-accounts/${info?.row?.original?.id}`),
            icon: <MdPageview color="#422afb" className="h-8 w-8" />,
            isLoading: request
          },
          {
            title: 'Evaluar',
            isHidden: userId,
            onClick: (info: any) => comfirmApprobed(bankData),
            icon: <MdOutlineLoupe color="brown" className="h-8 w-8 green" />,
            isLoading: request
          },
        ];
      }
    }
  ]), [userId]);

  const totalItems = useMemo(() => { return bankAccounts?.meta?.totalItems }, [bankAccounts]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`bank-accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(bankAccountActions.bankAccountsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}broker/bank-accounts/search?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchState?.length ? `filter.evaluation=$eq:${searchState}&` : '';
    url += userId?.length ? `filter.brokerUserID=$eq:${userId}&` : '';

    switch (true) {
      case !!fromSearchDate?.length && !!toSearchDate?.length:
        url += `filter.createdAt=$btw:${fromSearchDate},${toSearchDate}T23:59:59`;
        break;
      case !!fromSearchDate?.length:
        url += `filter.createdAt=$gte:${fromSearchDate}`;
        break;
      case !!toSearchDate?.length:
        url += `filter.createdAt=$lte:${toSearchDate}T23:59:59`;
        break;
    }

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, userId]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const onSearch = ({ userId, searchText, searchState, sorting, fromSearchDate, toSearchDate }: { userId: string, searchText: string, searchState: string, sorting: SortingState, fromSearchDate: string, toSearchDate: string }) => {

    let url = searchUrl;

    fetchData(`bank-accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(bankAccountActions.bankAccountsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    if (comfirmIsOpen) {
      return;
    }

    let url = 'bank-accounts?';

    url += userId?.length ? `filter.brokerUserID=$eq:${userId}` : '';


    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'broker/bank-accounts/search?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, searchState, sorting, fromSearchDate, toSearchDate, userId })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, comfirmIsOpen, userId]);

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${bankAccounts?.meta?.totalItems}`;

    fetchData(`bank-accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'lotes');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Desde</Text><Input defaultValue={fromSearchDate} onChange={(e) => setFromSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Desde" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Hasta</Text><Input defaultValue={toSearchDate} onChange={(e) => setToSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Hasta" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchState} onChange={(e) => setSearchState(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por evaluacion*'>
        <option value={statusesValues.BENEFICIARY_MISMATCH}>{statusesLabel.BENEFICIARY_MISMATCH}</option>
        <option value={statusesValues.DATA_MISMATCH}>{statusesLabel.DATA_MISMATCH}</option>
        <option value={statusesValues.MISSING_STATEMENT}>{statusesLabel.MISSING_STATEMENT}</option>
        <option value={statusesValues.UNRELIABLE_STATEMENT}>{statusesLabel.UNRELIABLE_STATEMENT}</option>
        <option value={statusesValues.WRONG_STATEMENT}>{statusesLabel.WRONG_STATEMENT}</option>
        <option value={statusesValues.PENDING}>{statusesLabel.PENDING}</option>
        <option value={statusesValues.APPROVED}>{statusesLabel.APPROVED}</option>
        <option value={statusesValues.REJECTED}>{statusesLabel.REJECTED}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
  </Grid>, [totalItems, searchText, searchState, fromSearchDate, toSearchDate, searchUrl, bankAccounts]);

  const BoxContent = useMemo(() => <Grid display={'flex'} gap={6}>
    {bankAccounts?.meta?.brief ?
      <>
        <GridItem><Text>Total: <b>{`$${bankAccounts.meta.brief.totalAmount?.toLocaleString()}`}</b></Text></GridItem>
        <GridItem>Depositos: <b>{`$${bankAccounts.meta.brief.totalDeposits?.toLocaleString()}`}</b></GridItem>
        <GridItem>Retiros: <b>{`$${bankAccounts.meta.brief.totalWithdrawals?.toLocaleString()}`}</b></GridItem>
      </>
      : null
    }

  </Grid>, [bankAccounts]);


  return (<>
    <Table
      request={request}
      data={bankAccounts}
      columns={tableColumnsData}
      accordionContent={AccordionContent}
      onPaginate={onPaginate}
      onSort={setSorting}
      boxContent={BoxContent}
    />
    <EvaluationModal
      userID={comfirmData?.brokerUser?.id}
      evaluationID={comfirmData?.id}
      setComfirmIsOpen={setComfirmIsOpen}
      comfirmIsOpen={comfirmIsOpen}

    />
  </>);
}
