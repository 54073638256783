import React from "react";
import { useUser } from '../context/providers/UsersProvider';
import Spinner from '../../../../components/spinner';
import {
    Text,
    Grid,
    GridItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionPanel,
    AccordionIcon,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

export default function AddForm(): React.ReactElement {
    const { state: { user, request } } = useUser();

    return (<Grid
        hidden={!user}
        templateColumns={{
            base: 'repeat(4, 1fr)',
            md: 'repeat(8, 1fr)',
            lg: 'repeat(12, 1fr)',
        }}
        gap={6}
        rowGap={12}
    >



        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Estado:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.status?.name} - {user?.status?.description}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Tipo:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.userType?.name} - {user?.userType?.description}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Padre IB:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.parentIB?.id} - {user?.parentIB?.name}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        WLPID
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.wlpID}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Creado
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.createdWhen}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Actualizado
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.updatedBy} {user?.updatedWhen}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        userNoteQty
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {user?.userNoteQty}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={16}>


            <Accordion>

                {
                    user?.documents?.map((doc)=>(<AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    {doc.type} <small>{doc.description}</small>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                        <UnorderedList>
                            {Object.keys(doc.data).map((dataKey)=>{

                                if(doc.type === 'TAX_INFO' && dataKey === 'accounts') {
                                    return Object.keys(doc.data[dataKey]).map((accountDataKey)=>(<ListItem>{accountDataKey}: {doc.data[dataKey][accountDataKey]?.taxRecipientCode} - {doc.data[dataKey][accountDataKey]?.taxStatusCode}</ListItem>))
                                }

                                return <ListItem>{dataKey}: {doc.data[dataKey]?.toString()}</ListItem>

                            })}
                            </UnorderedList>
                        </AccordionPanel>
                    </AccordionItem>))
                }
            </Accordion>


        </GridItem>

    </Grid>);
}