import React from "react";
import { transationTypes } from './../actions/TableActionTypes';
import {
	SortingState,
  } from "@tanstack/react-table";

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

export type Action = {
	type: transationTypes;
	data?: any;
};

type PaginateDataMeta = {
	currentPage?: number;
	itemsPerPage?: number;
	sortBy?: any;
	totalItems?: number
	totalPages?: number;
};

type PaginateDataLinks = {
	first?: string;
	current?: string;
	last?: string;
	next?: string;
	previous?: string;
};

type PaginateData = {
	data: any[];
	links: PaginateDataLinks;
	meta: PaginateDataMeta;
	resourse?: string;
};

export type TABLE_COLUMN_TYPE = {
	type: COLUMN_TYPE,
	label: string,
	name: string,
	setCustomValue?: Function
	actions?: TABLE_COLUMN_ACTION_TYPE[],
	export?: boolean,
	hidden?: boolean
};

export type TABLE_TYPE = {
	resource?: string;
	name: string;
	data: any;
	columns: TABLE_COLUMN_TYPE[];
	request: boolean;
	addExport: boolean;
	paginate?: PaginateData;
	onSearch?: Function;
	onPaginate?: Function;
	onSort?: React.useState<SortingState>;
};

type PaginateData2 = {
	[key: string]: PaginateData
}

export type tableContextType = {
	request: boolean,
	notification: {
		title: string,
		type: NotifyType
	},
	error: Error | null,
	data: PaginateData,
	data2: PaginateData2,
	searchText?: string
};