import AddForm from "./components/AddForm";
import { CircuitTransfersProvider } from './context/providers/CircuitTransfersProvider';

const AddFormView = () => {
  return (
    <CircuitTransfersProvider>
      <AddForm></AddForm>
    </CircuitTransfersProvider>
  );
};

export default AddFormView;
