import React from "react";
import { useAccount } from '../context/providers/AccountsProvider';
import {
    FormControl,
    Button,
    Grid,
    GridItem,
    Center,
    TableContainer,
    Table,
    TableCaption,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Tfoot,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    ExpandedIndex,
} from '@chakra-ui/react';
import {
    MdFilterList,
    MdFilterListOff
} from "react-icons/md";
import * as accountActions from './../context/actions/AccountActions';
import Calendar from 'components/calendar/MiniCalendar';
import fetchData from './../../../../helpers/fetchData';
import BackButton from "components/button/Back";

type PerformanceType = {
    realizedDayPL: Number;
    unrealizedDayPL: Number;
    cumRealizedPL: Number;
    date: string;
    equity: Number;
    cash: Number;
    deposits: Number;
    withdrawals: Number;
    fees: Number;
}

export default function PerformanceForm() {
    const { state: { request, performance, account }, dispatch } = useAccount();
    const [index,setIndex] = React.useState<number>(null);
    const [date, setDate] = React.useState<[Date, Date]>([null, null]);
    const [showError, setShowError] = React.useState<boolean>(false);

    const submit = async () => {

        if (!account.id) {
            setShowError(true);
            dispatch(accountActions.notifyError('Completar los datos requeridos'))
            return;
        }

        try {
            if(!date[0] || !date[1]) {
                return ;
            }

            const firstRangeDate: Date = date[0];
            const from = date[0].toLocaleDateString("default", { year: "numeric" }) + date[0].toLocaleDateString("default", { month: "2-digit" }) + date[0].toLocaleDateString("default", { day: "2-digit" });
            const secondRangeDate: Date = date[1];
            const to = secondRangeDate.toLocaleDateString("default", { year: "numeric" }) + secondRangeDate.toLocaleDateString("default", { month: "2-digit" }) + secondRangeDate.toLocaleDateString("default", { day: "2-digit" });

            dispatch(accountActions.accountPerformanceGet());
            dispatch(accountActions.accountPerformanceSuccess(await fetchData(`accounts/${account.id}/performance?from=${from}&to=${to}`)));

            //navigate(`/admin/accounts/view/${account.id}/performance?from=${from}&to=${to}`)
            setIndex(null)
        } catch (error: any) {
            dispatch(accountActions.accountPerformanceError(new Error(error.statusText || error.message || error)))
        }


    }

    React.useEffect(()=>{

        if(date[0] === date[1]) {
            setIndex(0)
            return;
        }

        submit();
    },[date])

    return (<Center>
        <Grid
            templateColumns={{
                base: 'repeat(1, 1fr)'
            }}
            gap={1}
            content="center"
            className="w-full"
        >

            <GridItem w='100%'>
                <Accordion onChange={(index: ExpandedIndex) => { setIndex(Number(index)) }} index={index} allowToggle className="my-5">
                    <AccordionItem>
                        {({ isExpanded = false }) => (
                            <>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                        </Box>
                                        {isExpanded ? (
                                            <MdFilterListOff fontSize='24px' />
                                        ) : (
                                            <MdFilterList fontSize='24px' />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>

                                    <Grid
                                        templateColumns={{
                                            base: 'repeat(1, 1fr)'
                                        }}
                                        gap={6}
                                    >
                                        <GridItem w='100%'>
                                            <FormControl isInvalid={showError} className="mb-5 items-center flex flex-col">
                                                <Calendar
                                                    onChange={(a: any, b: any) => {
                                                        if (!a[1]) {
                                                            return;
                                                        }
                                                        const firstRangeDate: Date = a[0];
                                                        const firstRangeDateString = firstRangeDate.toLocaleDateString("default", { year: "numeric" }) + firstRangeDate.toLocaleDateString("default", { month: "2-digit" }) + firstRangeDate.toLocaleDateString("default", { day: "2-digit" });
                                                        const secondRangeDate: Date = a[1];
                                                        const secondRangeDateString = secondRangeDate.toLocaleDateString("default", { year: "numeric" }) + secondRangeDate.toLocaleDateString("default", { month: "2-digit" }) + secondRangeDate.toLocaleDateString("default", { day: "2-digit" });
                                                        setDate([firstRangeDate, secondRangeDate]);
                                                    }}
                                                    value={date}
                                                    selectRange={true}
                                                    maxDate={new Date()}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem display={'none'}>
                                            <Grid
                                                templateColumns={{
                                                    base: 'repeat(2, 1fr)'
                                                }}
                                                gap={2}
                                                content="center"
                                            >
                                                <GridItem w='100%'>
                                                    <Button
                                                        className="mt-5 max-w-lg center"
                                                        isLoading={request}
                                                        colorScheme="blue"
                                                        variant='solid'
                                                        onClick={submit}
                                                    > Aceptar
                                                    </Button>
                                                </GridItem>
                                                <GridItem w='100%'>
                                                    <BackButton request={request} />
                                                </GridItem>
                                            </Grid>
                                        </GridItem>
                                    </Grid>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
            </GridItem>
            <GridItem>
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>Performance de la cuenta: {account.accountNo} ({date[0] ? date[0].toLocaleDateString("default", { year: "numeric" })+ '-' + date[0].toLocaleDateString("default", { month: "2-digit" }) + '-' + date[0].toLocaleDateString("default", { day: "2-digit" }):''} - {date[1] ? date[1].toLocaleDateString("default", { year: "numeric" })+ '-' + date[1].toLocaleDateString("default", { month: "2-digit" }) + '-' + date[1].toLocaleDateString("default", { day: "2-digit" }):''})</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Dinero</Th>
                                <Th>Profit/Loss Realizado</Th>
                                <Th>Fecha</Th>
                                <Th>deposits</Th>
                                <Th>equity</Th>
                                <Th>Comisiones</Th>
                                <Th>PL realizado</Th>
                                <Th>PL no realizado</Th>
                                <Th>Retiros</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {performance?.performance.map((performanceData: PerformanceType, key) => <Tr key={key}>
                                <Td>$ {Number(performanceData?.cash)?.toLocaleString()}</Td>
                                <Td>{performanceData.cumRealizedPL as React.ReactNode}</Td>
                                <Td>{performanceData.date as React.ReactNode}</Td>
                                <Td>{performanceData.deposits as React.ReactNode}</Td>
                                <Td>{performanceData.equity as React.ReactNode}</Td>
                                <Td>{performanceData.fees as React.ReactNode}</Td>
                                <Td>{performanceData.realizedDayPL as React.ReactNode}</Td>
                                <Td>{performanceData.unrealizedDayPL as React.ReactNode}</Td>
                                <Td>{performanceData.withdrawals as React.ReactNode}</Td>
                            </Tr>)}
                        </Tbody>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    </Center>);
}