import UserAccountsTable from "./../../accounts/index";
import { useParams } from "react-router-dom";

const UserAccounts = () => {
  const { id } = useParams();

  return (
    !!id ? <UserAccountsTable userId={id} /> : null
  );
};

export default UserAccounts;
