export enum transationTypes {
    FEE_NOTIFY_CLEAR = 'FEE_NOTIFY_CLEAR',
    FEE_NOTIFY = 'FEE_NOTIFY',

    FEES_GET = 'FEES_GET',
    FEES_GET_SUCCESS = 'FEES_GET_SUCCESS',

    FEE_GET = 'FEE_GET',
    FEE_GET_SUCCESS = 'FEE_GET_SUCCESS',

    FEE_ADD = 'FEE_ADD',
    FEE_ADD_SUCCESS = 'FEE_ADD_SUCCESS',

    FEE_DELETE_GET = 'FEE_DELETE_GET',
    FEE_DELETE_GET_SUCCESS = 'FEE_DELETE_GET_SUCCESS',

    FEE_UPDATE_GET = 'FEE_UPDATE_GET',
    FEE_UPDATE_GET_SUCCESS = 'FEE_UPDATE_GET_SUCCESS',

    FEE_SET_ERROR = 'FEE_SET_ERROR',
};
