import List from "./components/List";
import { UsersProvider } from './context/providers/UserProvider';

const ClientList = () => {
  return (
    <UsersProvider>
      <List />
    </UsersProvider>
  );
};

export default ClientList;
