import { transationTypes } from '../actions/RedemptionActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface Iredemption {
	id?: string;
	created: string;
	date: string;
	status: string;
	amount: string;
	type: string;
}

export type Action = {
	type: transationTypes;
	data?: any;
};

export type IRepemptionGrid = {
	data: Iredemption[];
	meta: any;
	links: any;
};

export type redemptionContextType = {
	redemptions: IRepemptionGrid;
	redemption: Iredemption;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};