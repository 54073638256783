import { circuitTransferContextType, Action } from '../types/circuitTransfer';
import { transationTypes } from '../actions/CircuitTransferActionTypes';

//the initial state of the user
export const initialState: circuitTransferContextType = {
  request: false,
  updated: false,
  deleted: false,
  notification: null,
  error: null,
  circuitTransfers: {data:[]},
  circuitTransfer: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: circuitTransferContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.CIRCUIT_TRANSFERS_GET:
      return {
        ...state,
        updated: false,
        deleted: false,
        request: true,
        circuitTransfer: null
      };
    case transationTypes.CIRCUIT_TRANSFERS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        circuitTransfers: action.data,
      };


    //GET
    case transationTypes.CIRCUIT_TRANSFER_GET:
      return {
        ...state,
        request: true,
        circuitTransfer: null
      };
    case transationTypes.CIRCUIT_TRANSFER_GET_SUCCESS:
      return {
        ...state,
        request: false,
        circuitTransfer: action.data,
      };


    //ADD
    case transationTypes.CIRCUIT_TRANSFER_ADD:
      return {
        ...state,
        request: true,
        circuitTransfer: null
      };

    case transationTypes.CIRCUIT_TRANSFER_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        circuitTransfer: action.data,
      };




    //NOTIFY
    case transationTypes.CIRCUIT_TRANSFER_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.CIRCUIT_TRANSFER_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.CIRCUIT_TRANSFER_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.CIRCUIT_TRANSFER_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.CIRCUIT_TRANSFER_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.CIRCUIT_TRANSFER_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.CIRCUIT_TRANSFER_UPDATE_GET_SUCCESS:
      return {
        ...state,
        circuitTransfer: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};