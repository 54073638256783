import { IcircuitTransfer, NotifyType, ITransactionGrid } from './../types/circuitTransfer.d';
import { transationTypes } from './CircuitTransferActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};

export const notifyClear = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_NOTIFY_CLEAR
  }
};


//GET CIRCUIT_TRANSFERS
export const circuitTransfersGet = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFERS_GET
  }
};

export const circuitTransfersSuccess = (data: ITransactionGrid) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFERS_GET_SUCCESS,
    data
  }
};

export const circuitTransfersError = (data: Error) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_SET_ERROR,
    data
  }
};




//DELETE CIRCUIT_TRANSFER
export const circuitTransferDelete = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_DELETE_GET
  }
};

export const circuitTransferDeleteSuccess = (data: any) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_DELETE_GET_SUCCESS,
    data
  }
};

export const circuitTransferDeleteError = (data: Error) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_SET_ERROR,
    data
  }
};


//GET CIRCUIT_TRANSFER
export const circuitTransferGet = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_GET
  }
};

export const circuitTransferGetSuccess = (data: IcircuitTransfer) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_GET_SUCCESS,
    data
  }
};

export const circuitTransferGetError = (data: Error) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_SET_ERROR,
    data
  }
};




//UPDATE CIRCUIT_TRANSFER
export const circuitTransferUpdate = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_UPDATE_GET
  }
};

export const circuitTransferUpdateSuccess = (data: any) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_UPDATE_GET_SUCCESS,
    data
  }
};

export const circuitTransferUpdateError = (data: Error) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_SET_ERROR,
    data
  }
};


//ADD CIRCUIT_TRANSFER
export const circuitTransferAdd = () => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_ADD
  }
};

export const circuitTransferAddSuccess = (data: IcircuitTransfer) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_ADD_SUCCESS,
    data
  }
};

export const circuitTransferAddError = (data: Error) => {
  return {
    type: transationTypes.CIRCUIT_TRANSFER_SET_ERROR,
    data
  }
};
