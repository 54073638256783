import React, { useCallback, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import { useAccount } from '../context/providers/AccountsProvider';
import fetchData from './../../../../helpers/fetchData';
import * as accountActions from './../context/actions/AccountActions';
import { Iaccount, IAccountUpdateDto, statusesLabel, statusesValues } from "../context/types/account.d";
import { Tag, Button, Text, Select, Container, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input } from '@chakra-ui/react'
import { MdPersonOutline } from "react-icons/md";
import Spinner from "components/spinner";
import BackButton from "components/button/Back";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Divider  } from '@chakra-ui/react';


export default function EditForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { dispatch, state: { request, account, error } } = useAccount();
    const [accountStatus, setAccountStatus] = useState<string>();
    const [nickname, setNickname] = useState<string>();
    const [accountStatusComment, setAccountStatusComment] = useState<string>();
    const [showError, setShowError] = React.useState<boolean>(false);
    const isLoading = useMemo(() => {
        return !account || request || error;
    }, [account, request, error]);

    React.useEffect(() => {
        dispatch(accountActions.accountsClear());
    }, [dispatch]);


    const getData = useCallback(() => {
        dispatch(accountActions.accountGet());
        fetchData(`accounts/${id}`)
            .then((data: any) => {
                dispatch(accountActions.accountGetSuccess(data));
            })
            .catch((error: any) => dispatch(accountActions.accountGetError(new Error(error.statusText || error.message || error))));
    }, [dispatch, id]);

    React.useEffect(() => {
        getData();
    }, [getData]);

    React.useEffect(() => {
        if (!account) {
            return;
        }

        setNickname(account.nickname);
        setAccountStatus(account.status.name);
    }, [account, setAccountStatus, setNickname]);

    const onSubmit = async (accountData: IAccountUpdateDto) => {
        try {
            dispatch(accountActions.accountUpdate());
            dispatch(accountActions.accountUpdateSuccess(await fetchData(`accounts/${account.id}`, 'PATCH', accountData)));
            dispatch(accountActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
            setTimeout(() => navigate('/admin/accounts'), 500);
        } catch (error: any) {
            dispatch(accountActions.accountUpdateError(new Error(error.statusText || error.message || error)))
        }
    };
    
    const submit = async () => {

        const isEditStatusFormError = accountStatus && (accountStatus !== account.status.name) && !accountStatusComment;

        if (isEditStatusFormError || !nickname) {
            setShowError(true);
            dispatch(accountActions.notifyError('Completar los datos requeridos'))
            return;
        }

        const editStatusData = accountStatus !== account.status.name && accountStatusComment ? {
            status: accountStatus,
            statusComment: accountStatusComment,
            statusChangeReason:"PARTNER_RESTRICTED"
        } : {};

        const accountUpdateData: IAccountUpdateDto = {
            ...editStatusData,
            nickname,
        };

        onSubmit(accountUpdateData);
    }

    enum ScreenIndexType {
        default = 'default',
        documents = 'documents',
    };
    
    const defaultScreenIndex: ScreenIndexType = ScreenIndexType.default;
    
    type ScreenIndexValueType = {
        default: number;
        documents: number;
    };
    
    const screenIndexValue: ScreenIndexValueType = {
        default: 0,
        documents: 1,
    };

    const [screenIndexType, setScreenIndexType] = React.useState<ScreenIndexType>(defaultScreenIndex)


    return (<>

        <Spinner hidden={!isLoading} />
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div style={{ visibility: !!account ? 'visible' : 'hidden' }} className="text-xl font-bold text-navy-700 dark:text-white flex justify-center gap-2 items-center">
                    <Text hidden={!account}>Editar Cuenta: {account?.accountNo} </Text>
                </div>
                <div className={`flex items-center gap-5`}>
                    <BackButton request={!!(request || !account?.userID)} />
                </div>
            </div>

            <Tabs style={{ visibility: !!account ? 'visible' : 'hidden' }} onChange={(index: number) => {
                    setScreenIndexType(Object.keys(screenIndexValue).find((e) => screenIndexValue[e as ScreenIndexType] === index) as ScreenIndexType);
                }} index={screenIndexValue[screenIndexType]} isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab>Datos Personales</Tab>
                    <Tab>Documentos</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                    {(screenIndexValue[screenIndexType] === screenIndexValue.default) ?             <Container centerContent>

                        <FormControl isInvalid={showError && !accountStatus} className="mb-5">
                            <FormLabel>Estado</FormLabel>
                            <Select disabled={request} value={accountStatus} onChange={(e) => setAccountStatus(e.target?.selectedOptions?.[0]?.value)} placeholder='Estado'>
                                <option value={statusesValues.open}>{statusesLabel.open}</option>
                                <option value={statusesValues.closed}>{statusesLabel.closed}</option>
                                <option value={statusesValues.frozen}>{statusesLabel.frozen}</option>
                                <option value={statusesValues.openNoNewTrades}>{statusesLabel.openNoNewTrades}</option>
                                <option value={statusesValues.pending}>{statusesLabel.pending}</option>
                            </Select>
                            <FormErrorMessage>Requerido</FormErrorMessage>
                            <FormHelperText hidden={!!accountStatus}>Estado de la cuenta.</FormHelperText>
                        </FormControl>

                        <FormControl isInvalid={showError && !accountStatusComment} className="mb-5">
                            <FormLabel>Comentario</FormLabel>
                            <Input disabled={request} value={accountStatusComment} onChange={(e) => setAccountStatusComment(e.target.value)} type='text' />
                            <FormErrorMessage>Requerido</FormErrorMessage>
                            <FormHelperText hidden={!!accountStatusComment}>Comentario para el cambio de estado.</FormHelperText>
                        </FormControl>

                        <FormControl isInvalid={showError && !nickname} className="mb-5">
                            <FormLabel>Nickname</FormLabel>
                            <Input disabled={request} value={nickname} onChange={(e) => setNickname(e.target.value)} type='text' />
                            <FormErrorMessage>Requerido</FormErrorMessage>
                            <FormHelperText hidden={!!nickname}>Nickname de la cuenta.</FormHelperText>
                        </FormControl>


                        {account ? <Button
                            className="mt-5 max-w-lg center"
                            isLoading={request}
                            colorScheme="blue"
                            variant='solid'
                            onClick={submit}
                        >
                            {!account ? 'Agregar' : 'Editar'}
                        </Button> : <BackButton request={request} />}
                        </Container> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.documents) ? <Text>2</Text> : null}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    </>);
}