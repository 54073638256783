import { NotifyType, PaginateData } from './../types/table.d';
import { tableTypes } from './TableActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: tableTypes.TABLES_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: tableTypes.TABLES_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};

export const notifyClear = () => {
  return {
    type: tableTypes.TABLES_NOTIFY_CLEAR
  }
};


//GET TABLES
export const tablesGet = () => {
  return {
    type: tableTypes.TABLES_GET
  }
};

export const tablesSuccess = (data: PaginateData) => {
  return {
    type: tableTypes.TABLES_GET_SUCCESS,
    data
  }
};

export const tablesClear = () => {
  return {
    type: tableTypes.TABLES_CLEAR
  }
};

export const tablesError = (data: Error) => {
  return {
    type: tableTypes.TABLES_SET_ERROR,
    data
  }
};

export const tablesSetSearchText = (text: string) => {
  return {
    type: tableTypes.TABLES_SEARCH_TEXT,
    data: text
  }
}


