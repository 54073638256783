import EditForm from "./components/EditForm";
import { AccountsProvider } from './context/providers/AccountsProvider';

const EditFormView = () => {
  return (
    <AccountsProvider>
      <EditForm></EditForm>
    </AccountsProvider>
  );
};

export default EditFormView;
