import { transationTypes } from '../actions/DepositActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface Ideposit {
	id?: string;
	created: string;
	date: string;
	status: string;
	amount: string;
	type: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export type IDepositGrid = {
    data?: Ideposit[];
	links: any;
	meta: any;
};

export type depositContextType = {
    deposits: IDepositGrid;
	deposit: Ideposit;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};