import EditForm from "./components/EditForm";
import { SettlementsProvider } from './context/providers/SettlementsProvider';

const EditFormView = () => {
  return (
    <SettlementsProvider>
      <EditForm></EditForm>
    </SettlementsProvider>
  );
};

export default EditFormView;
