import { accountContextType, Action } from '../types/account';
import { transationTypes } from './../actions/AccountActionTypes';

//the initial state of the user
export const initialState: accountContextType = {
  request: false,
  updated: false,
  statements: [],
  deposits: [],
  withdrawals: [],
  taxforms: [],
  transactions: [],
  performance: null,
  documentsUrl: {},
  deleted: false,
  notification: null,
  error: null,
  errorMessage: null,
  accounts: { data: [], links: {}, meta: {} },
  account: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: accountContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.ACCOUNTS_GET:
      return {
        ...state,
        updated: false,
        deleted: false,
        request: true,
        account: null,
        error: false
      };


    //GET
    case transationTypes.ACCOUNTS_CLEAR:
      return {
        ...state,
        accounts: { data: [], links: {}, meta: {} },
      };
    case transationTypes.ACCOUNTS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        accounts: action.data,
      };
    case transationTypes.ACCOUNT_GET:
      return {
        ...state,
        request: true,
        account: null,
        error: false
      };
    case transationTypes.ACCOUNT_CLEAR:
      return {
        ...state,
        account: null
      };
    case transationTypes.ACCOUNT_GET_SUCCESS:
      return {
        ...state,
        request: false,
        account: action.data,
        statements: [],
        deposits: [],
        withdrawals: [],
        taxforms: [],
        transactions: [],
        performance: null
      };


    //ADD
    case transationTypes.ACCOUNT_ADD:
      return {
        ...state,
        request: true,
        account: null,
        error: false
      };

    case transationTypes.ACCOUNT_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        account: action.data,
      };




    //NOTIFY
    case transationTypes.ACCOUNT_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.ACCOUNT_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.ACCOUNT_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
        errorMessage: action.data?.message,
      };


    //DELETE
    case transationTypes.ACCOUNT_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false,
        error: false
      };

    case transationTypes.ACCOUNT_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.ACCOUNT_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false,
        error: false
      };

    case transationTypes.ACCOUNT_UPDATE_GET_SUCCESS:
      return {
        ...state,
        account: null,
        request: false,
        updated: true
      };


    //TRANSACTIONS
    case transationTypes.ACCOUNT_TRANSACTIONS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        transactions: action.data,
      };

    case transationTypes.ACCOUNT_TRANSACTIONS_GET:
      return {
        ...state,
        request: true,
        transactions: null,
        error: false
      };
    case transationTypes.ACCOUNT_TRANSACTIONS_GET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };

    case transationTypes.ACCOUNT_CLEAR_ERROR:
      return {
        ...state,
        request: false,
        error: null,
        errorMessage: null
      };


    //DOCUMENTS
    case transationTypes.ACCOUNT_STATEMENTS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        statements: action.data,
      };

    case transationTypes.ACCOUNT_STATEMENTS_GET:
      return {
        ...state,
        request: true,
        statements: [],
        error: false
      };

    case transationTypes.ACCOUNT_TAXFORMS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        taxforms: action.data,
      };

    case transationTypes.ACCOUNT_TAXFORMS_GET:
      return {
        ...state,
        request: true,
        taxforms: [],
        error: false
      };

    //DOCUMENT URL
    case transationTypes.ACCOUNT_DOCUMENT_URL_GET_SUCCESS:
      return {
        ...state,
        request: false,
        documentsUrl: {
          ...state.documentsUrl,
          ...action.data
        },
      };

    case transationTypes.ACCOUNT_DOCUMENT_URL_GET:
      return {
        ...state,
        request: true,
        error: false
      };

    //DEPOSITS
    case transationTypes.ACCOUNT_DEPOSITS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        deposits: action.data,
      };

    case transationTypes.ACCOUNT_DEPOSITS_GET:
      return {
        ...state,
        request: true,
        deposits: null,
        error: false
      };
    case transationTypes.ACCOUNT_DEPOSITS_GET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };

    //WITHDRAWALS
    case transationTypes.ACCOUNT_WITHDRAWALS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        withdrawals: action.data,
      };

    case transationTypes.ACCOUNT_WITHDRAWALS_GET:
      return {
        ...state,
        request: true,
        withdrawals: null,
        error: false
      };
    case transationTypes.ACCOUNT_WITHDRAWALS_GET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };



    default:
      return state;
  }
};