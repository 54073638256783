import { transationTypes } from './../actions/SettlementActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface Isettlement {
	id?: string;
	category: string;
	created: string;
	date: string;
	status: string;
	totalAmount: string;
	updated: string;
	updatedBy: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export interface ITransactionGrid {
	data: Isettlement[];
	meta: any;
	links:any;
}

export type settlementContextType = {
    settlements: ITransactionGrid;
	settlement: Isettlement;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};