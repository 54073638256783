import { Ideposit, NotifyType } from './../types/deposit.d';
import { transationTypes } from './DepositActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.DEPOSIT_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.DEPOSIT_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET DEPOSITS
export const depositsGet = () => {
  return {
    type: transationTypes.DEPOSITS_GET
  }
};

export const depositsSuccess = (data: Ideposit[]) => {
  return {
    type: transationTypes.DEPOSITS_GET_SUCCESS,
    data
  }
};

export const depositsError = (data: Error) => {
  return {
    type: transationTypes.DEPOSIT_SET_ERROR,
    data
  }
};


//GET DEPOSIT
export const depositGet = () => {
  return {
    type: transationTypes.DEPOSIT_GET
  }
};

export const depositGetSuccess = (data: Ideposit) => {
  return {
    type: transationTypes.DEPOSIT_GET_SUCCESS,
    data
  }
};

export const depositGetError = (data: Error) => {
  return {
    type: transationTypes.DEPOSIT_SET_ERROR,
    data
  }
};


//PATH
export const redemptionPatch = () => {
  return {
    type: transationTypes.DEPOSIT_PATCH
  }
};

export const redemptionPatchSuccess = (data: any) => {
  return {
    type: transationTypes.DEPOSIT_PATCH_SUCCESS,
    data
  }
};

export const redemptionPatchError = (data: Error) => {
  return {
    type: transationTypes.DEPOSIT_SET_ERROR,
    data
  }
};



