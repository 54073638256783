import React, { ReactNode, useMemo } from "react";
import {
  MdCancel,
  MdTimer,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
  MdLockOpen,
  MdOutlineCheck,
  MdOutlineBlock,
  MdLockOutline,
  MdAssignmentLate,
  MdOutlineRadioButtonChecked,
  MdErrorOutline
} from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  Updater,
  TableState
} from "@tanstack/react-table";
import Spinner from "components/spinner";
import { Text, Button, Box, Select } from '@chakra-ui/react'
import { TABLE_TYPE } from './context/types/table';

const getColumnDataValue = ({ columnData, element }: { columnData: any, element: any }) => {
  try {
    let columnValueData = '';

    if (columnData.name.indexOf('.') === -1) {
      columnValueData = element[columnData.name];
    } else {
      const propertyParts = columnData.name.split('.');
      columnValueData = propertyParts.reduce((acc: any, act: any) => acc[act], element);
    }

    return columnValueData.toString();

  } catch (error) {
    console.warn(error);
    console.error('GCD -ERROR WHEN TRIED TO GET VALUE');
  }

}

export const downloadTableAsCSV = (columnsData: any[], gridData: any[], name: string = 'table_data', separator: string = ',') => {
  const csv = [];
  const row: any[] = [];
  columnsData.filter((columnData) => columnData.export).forEach((columnData) => {
    row.push('"' + columnData.label.toString().replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ').replace(/"/g, '""') + '"');
  })
  csv.push(row.join(separator));
  for (let index = 0; index < gridData.length; index++) {
    const element = gridData[index];
    const row: any[] = [];
    columnsData.filter((columnData) => columnData.export).forEach((columnData) => {
      row.push('"' + getColumnDataValue({ element, columnData }).replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ').replace(/"/g, '""') + '"');
    })
    csv.push(row.join(separator));
  }

  const csv_string = csv.join('\n');
  const filename = name + '_' + new Date().toLocaleDateString() + '.csv';
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const EMPTY_TABLE_TEXT: string = 'Sin resultados';
const LOADING_TABLE_TEXT: string = 'Buscando...';

const EmptyTableText = ({ hidden, request }: { hidden: boolean, request: boolean }) => !hidden ? <Text className="pt-5" fontSize='1xl'>{request ? LOADING_TABLE_TEXT : EMPTY_TABLE_TEXT}</Text> : null;

export enum COLUMN_TYPE {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  MONEY = 'MONEY',
  STATUS = 'STATUS',
  ACTIONS = 'ACTIONS',
  BOOLEAN = 'BOOLEAN'
};

export type TABLE_COLUMN_TYPE = {
  type: COLUMN_TYPE,
  label: string,
  name: string,
  setCustomValue?: Function
  actions?: TABLE_COLUMN_ACTION_TYPE[],
  getActions?: Function,
  export?: boolean,
  hidden?: boolean
  info?: any,
};

type TABLE_COLUMN_ACTION_TYPE = {
  onClick: Function,
  icon: ReactNode,
  title: string,
  isDisabled?: boolean,
  isLoading?: boolean,
  isHidden?: boolean,
};

enum COLUMN_STATUS {
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  OPEN = 'OPEN',
  OPEN_NO_NEW_TRADES = 'OPEN_NO_NEW_TRADES',
  FROZEN = 'FROZEN',
  SUCCESSFUL = 'SUCCESSFUL'
};

enum COLUMN_STATUS_LABELS {
  ACTIVE = 'Activa',
  CLOSED = 'Cerrada',
  PENDING = 'Pendiente',
  APPROVED = 'Aprobado',
  OPEN = 'Abierta',
  OPEN_NO_NEW_TRADES = 'Abierta Limitada',
  FROZEN = 'Congelada',
  FAILED = 'Fallida',
  SUCCESSFUL = 'Exitosa'
};

const getColumnStatusLabel = (status: COLUMN_STATUS) => {
  switch (status) {
    case COLUMN_STATUS.PENDING:
      return COLUMN_STATUS_LABELS.PENDING;
    case COLUMN_STATUS.APPROVED:
      return COLUMN_STATUS_LABELS.APPROVED;
    case COLUMN_STATUS.OPEN:
      return COLUMN_STATUS_LABELS.OPEN;
    case COLUMN_STATUS.CLOSED:
      return COLUMN_STATUS_LABELS.CLOSED;
    case COLUMN_STATUS.OPEN_NO_NEW_TRADES:
      return COLUMN_STATUS_LABELS.OPEN_NO_NEW_TRADES;
    case COLUMN_STATUS.FROZEN:
      return COLUMN_STATUS_LABELS.FROZEN;
    case COLUMN_STATUS.FAILED:
      return COLUMN_STATUS_LABELS.FAILED;
    case COLUMN_STATUS.SUCCESSFUL:
      return COLUMN_STATUS_LABELS.SUCCESSFUL;
    case COLUMN_STATUS.ACTIVE:
      return COLUMN_STATUS_LABELS.ACTIVE;

    default:
      return status;
  }
}

const getColumnCellStatusContent = (columnData: TABLE_COLUMN_TYPE) => {

  const statusValue = getColumnDataValue({ columnData, element: columnData.info.row.original });
  const statusLabel = getColumnStatusLabel(statusValue as COLUMN_STATUS);

  switch (statusValue) {
    case COLUMN_STATUS.CLOSED:
      return <><MdLockOutline className="text-red-500 me-1 dark:text-red-300" />{statusLabel}</>
    case COLUMN_STATUS.PENDING:
      return <><MdTimer className="text-blue-500 me-1 dark:text-blue-300" />{statusLabel}</>
    case COLUMN_STATUS.OPEN:
      return <><MdLockOpen className="text-green-500 me-1 dark:text-green-300" /> {statusLabel}</>
    case COLUMN_STATUS.APPROVED:
      return <><MdOutlineCheck className="text-green-500 me-1 dark:text-green-300" /> {statusLabel}</>
    case COLUMN_STATUS.OPEN_NO_NEW_TRADES:
      return <><MdAssignmentLate className="text-blue-500 me-1 dark:text-blue-300" /> {statusLabel}</>
    case COLUMN_STATUS.FROZEN:
      return <><MdOutlineBlock className="text-yellow-500 me-1 dark:text-yellow-300" /> {statusLabel}</>
    case COLUMN_STATUS.FAILED:
      return <><MdErrorOutline className="text-red-500 me-1 dark:text-red-300" /> {statusLabel}</>
    case COLUMN_STATUS.SUCCESSFUL:
      return <><MdOutlineCheck className="text-green-500 me-1 dark:text-green-300" /> {statusLabel}</>

    default:
      return <><MdOutlineRadioButtonChecked className="text-blue-500 me-1 dark:blue-blue-300" />{statusLabel}</>
  }
};

const getColumnHeader = (label: string) => <p className="text-sm font-bold text-gray-600 dark:text-white">
  {label}
</p>;

const getColumnCell = (content: string) => <div className="flex items-center">
  {content}
</div>;

const setActions = ({ actions, info }: { actions: TABLE_COLUMN_ACTION_TYPE[], info: any }) => {
  return actions.map(({ onClick, icon, isDisabled = false, isLoading = false, isHidden = false, title }: TABLE_COLUMN_ACTION_TYPE, key) => <Button
    key={key}
    isDisabled={isDisabled}
    isLoading={isLoading}
    hidden={isHidden}
    title={title}
    variant={'link'}
    colorScheme="black"
    onClick={() => onClick(info)}
  >
    {icon}
  </Button>)

}

const getTableCellFormatedValue = (columnData: TABLE_COLUMN_TYPE) => {
  const infoValue: string = columnData.info.getValue();
  switch (columnData.type) {
    case COLUMN_TYPE.DATE:
      return infoValue ? new Date(infoValue).toLocaleDateString() + ' ' + new Date(infoValue).toLocaleTimeString() : '';
    case COLUMN_TYPE.DATETIME:
      return infoValue ? new Date(infoValue).toLocaleDateString() + ' ' + new Date(infoValue).toLocaleTimeString() : '';
    case COLUMN_TYPE.MONEY:
      return infoValue ? `$${Number(infoValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    case COLUMN_TYPE.STATUS:
      return getColumnCellStatusContent(columnData);
    case COLUMN_TYPE.ACTIONS:
      return setActions({ actions: typeof columnData.getActions === 'function' ? columnData.getActions(columnData.info) : columnData.actions, info: columnData.info });
    case COLUMN_TYPE.BOOLEAN:
      return infoValue ? (
        <><MdCheckCircle className="text-green-500 me-1 dark:text-green-300" /> Active</>
      ) : (
        <><MdCancel className="text-red-500 me-1 dark:text-red-300" />Disabled</>
      );

    default:
      return columnData.setCustomValue ? columnData.setCustomValue(columnData.info) : infoValue
  }
}

const getTableColumn = (columData: TABLE_COLUMN_TYPE) => columnHelper.accessor(columData.name, {
  id: columData.name,
  header: () => getColumnHeader(columData.label),
  cell: (info) => getColumnCell(getTableCellFormatedValue({ ...columData, info })),
});

const columnHelper = createColumnHelper<any>();

export default function Table({ onSort = (data: any) => { }, data, columns, request, addExport, name, onPaginate = (url: string) => { } }: TABLE_TYPE) {

  const id = Math.random().toString(36).substr(2, 15);
  const [sorting, setSorting] = React.useState<any>([]);
  const [stateChange, setStateChange] = React.useState<boolean>(false);

  const columnsData = useMemo(() => columns.filter((columnData: any) => !columnData.hidden).map(getTableColumn), [columns]);

  const gridData = useMemo(() => {
    const gridData = Array.isArray(data?.data) ? data.data : Array.isArray(data) ? data : [];
    return gridData;
  }, [data]);

  const table = useReactTable({
    data: gridData,
    columns: columnsData,
    onSortingChange: (sortValue:any)=> {
      setSorting(sortValue());
      onSort(sortValue());
    },
    onStateChange: (updater: Updater<TableState>)=>{
      setStateChange(!stateChange)
    },
    state: {
      sorting: sorting
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const nextPageDisable = useMemo(() => {
    return data && data?.links ? !data?.links?.next : !table.getCanNextPage()
  }, [table, data, stateChange]);

  const previousPageDisable = useMemo(() => {
    return data && data?.links ? !data?.links?.previous : !table.getCanPreviousPage()
  }, [table, data, stateChange]);

  const firstPageDisable = useMemo(() => {
    return data && data?.links ? !data?.links?.first : !table.getCanPreviousPage()
  }, [table, data, stateChange]);

  const lastPageDisable = useMemo(() => {
    return data && data?.links ? !data?.links?.last : !table.getCanNextPage()
  }, [table, data, stateChange]);

  const goToNextPage = () => {
    if (!data || !data?.links) {
      return table.nextPage();
    }

    onPaginate(data.links.next);
  }

  const goToPreviousPage = () => {
    if (!data || !data?.links) {
      return table.previousPage();
    }

    onPaginate(data.links.previous);
  }

  const goToLastPage = () => {
    if (!data || !data?.links) {
      return table.setPageIndex(table.getPageCount() - 1)
    }

    onPaginate(data.links.last);
  }

  const goToFirstPage = () => {
    if (!data || !data?.links) {
      return table.setPageIndex(0)
    }

    onPaginate(data.links.first);
  }

  return (
    <>
      <Spinner hidden={!request} />
      <div className="overflow-x-scroll xl:overflow-x-hidden">
        <table id={id} className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tr hidden={!!gridData?.length} className="flex-col text-center">
            <td colSpan={columnsData.length - 1}><EmptyTableText request={request} hidden={!!gridData?.length} /></td>
          </tr>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="divider"></div>
        <Box className="flex items-center gap-2 justify-center m-2 mt-10">
          <Button
            isDisabled={firstPageDisable}
            onClick={() => goToFirstPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${firstPageDisable
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdFirstPage className="h-6 w-6 " />
          </Button>

          <Button
            isDisabled={previousPageDisable}
            onClick={() => goToPreviousPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${previousPageDisable
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdChevronLeft className="h-6 w-6 " />
          </Button>

          <strong className="ml-1">
              {data?.meta?.currentPage ? data.meta.currentPage : table.getState().pagination.pageIndex + 1} de{' '}
              {data?.meta?.totalPages || table.getPageCount()}
            </strong>

          <Button
            isDisabled={nextPageDisable}
            onClick={() => goToNextPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${nextPageDisable
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdChevronRight className="h-6 w-6 " />
          </Button>


          <Button
            isDisabled={lastPageDisable}
            onClick={() => goToLastPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${lastPageDisable
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdLastPage className="h-6 w-6 " />
          </Button>

          <Select
            display={'none'}
            placeholder="Filas"
            width={'75px'}
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[data?.meta?.itemsPerPage || 5, 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </div>
    </>
  );
}
