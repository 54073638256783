import React, { createContext, ReactNode } from 'react';
import { circuitTransferContextType } from '../types/circuitTransfer';
import { initialState, reducer } from '../reducers/CircuitTransfersReducer';
import { useSessionReducer } from '../../../../../hooks/useSessionReducer';
import { useToast } from '@chakra-ui/react';
import * as circuitTransferActions from '../actions/CircuitTransferActions';

export const circuitTransfersContext = createContext<{
  state: circuitTransferContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useCircuitTransfer = () => {
  const context = React.useContext(circuitTransfersContext)
  if (context === undefined) {
      throw new Error('useCircuitTransfer must be used within a CircuitTransferProvider')
  }
  return context
};

export const CircuitTransfersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'CircuitTransfersProvider');
  const toast = useToast();
  const { error, notification } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });


  }, [error, toast]);

  React.useEffect(() => {
    if (!notification) {
      return;
    }

    toast({
      title: notification.title,
      status: notification.type,
      isClosable: true,
    });

    dispatch(circuitTransferActions.notifyClear())

  }, [notification, toast, dispatch]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <circuitTransfersContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </circuitTransfersContext.Provider>
  );
};
