import EditForm from "./components/EditForm";
import { TransactionsProvider } from './context/providers/Provider';

const EditFormView = () => {
  return (
    <TransactionsProvider>
      <EditForm></EditForm>
    </TransactionsProvider>
  );
};

export default EditFormView;
