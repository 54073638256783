import React, { createContext, ReactNode } from 'react';
import { authContextType } from '../types/auth';
import { initialState, reducer } from './Reducer';
import {useSessionReducer} from '../../../hooks/useSessionReducer';

export const authContext = createContext<{
  state: authContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => {}
});

export const useAuth = () => {
  const context = React.useContext(authContext)
  if (context === undefined) {
      throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'AuthProvider');

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <authContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </authContext.Provider>
  );
};
