import { depositContextType, Action } from './../types/deposit.d';
import { transationTypes } from '../actions/DepositActionTypes';

//the initial state of the user
export const initialState: depositContextType = {
  request: false,
  notification: null,
  error: null,
  deposits: { meta: {}, links: {}, data: [] },
  deposit: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: depositContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.DEPOSITS_GET:
      return {
        ...state,
        request: true,
        error: null,
        deposit: null
      };
    case transationTypes.DEPOSITS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        deposits: action.data,
      };


    //GET
    case transationTypes.DEPOSIT_GET:
      return {
        ...state,
        request: true,
        deposit: null
      };
    case transationTypes.DEPOSIT_GET_SUCCESS:
      return {
        ...state,
        request: false,
        deposit: action.data,
      };


    //ADD
    case transationTypes.DEPOSIT_ADD:
      return {
        ...state,
        request: true,
        deposit: null
      };

    case transationTypes.DEPOSIT_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        deposit: action.data,
      };




    //NOTIFY
    case transationTypes.DEPOSIT_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.DEPOSIT_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.DEPOSIT_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.DEPOSIT_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.DEPOSIT_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.DEPOSIT_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.DEPOSIT_UPDATE_GET_SUCCESS:
      return {
        ...state,
        deposit: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};