import React, { createContext, ReactNode } from 'react';
import { depositContextType } from './../types/deposit.d';
import { initialState, reducer } from '../reducers/DepositsReducer';
import { useSessionReducer } from '../../../../../hooks/useSessionReducer';
import { useToast } from '@chakra-ui/react';

export const depositsContext = createContext<{
  state: depositContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useDeposit = () => {
  const context = React.useContext(depositsContext)
  if (context === undefined) {
      throw new Error('useDeposit must be used within a DepositProvider')
  }
  return context
};

export const DepositsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'DepositsProvider');
  const toast = useToast();
  const { error } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });


  }, [error, toast]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <depositsContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </depositsContext.Provider>
  );
};
