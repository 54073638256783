import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useUser } from '../context/providers/UsersProvider';
import fetchData from './../../../../helpers/fetchData';
import * as userActions from './../context/actions/UserActions';
import { Iuser } from "../context/types/user";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag } from '@chakra-ui/react'
import UserForm from './Form';
import UserDocuments from './Documents';
import UserKYC from './KYC';
import Accounts from './Accounts';
import BankAccounts from './BankAccounts';
import BackButton from "components/button/Back";

enum ScreenIndexType {
    default = 'default',
    cuentas = 'cuentas',
    transacciones = 'transacciones',
    documentos = 'documentos',
};

const defaultScreenIndex: ScreenIndexType = ScreenIndexType.default;

type ScreenIndexValueType = {
    default: number;
    cuentas: number;
    transacciones: number;
    documentos: number;
};

const screenIndexValue: ScreenIndexValueType = {
    default: 0,
    cuentas: 1,
    transacciones: 2,
    documentos: 3
};


export default function EditForm() {
    const { id, screen = 'default' } = useParams();
    const navigate = useNavigate();
    const { dispatch, state: { request, user, error } } = useUser();
    const [screenIndexType, setScreenIndexType] = React.useState<ScreenIndexType>((screen as ScreenIndexType) || defaultScreenIndex)
   
    React.useEffect(() => {
        if (id && !request && !user && !error) {
            dispatch(userActions.userGet());
            fetchData(`dwusers/${id}`)
                .then((data: any) => dispatch(userActions.userGetSuccess(data)))
                .catch((error: any) => dispatch(userActions.userGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, user, error]);

    const onSubmit = async (userData: Iuser) => {
        try {
            dispatch(userActions.userUpdate());
            dispatch(userActions.userUpdateSuccess(await fetchData(`users/${user.id}`, 'PATCH', { ...user, ...userData })));
            dispatch(userActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
            setTimeout(() => navigate('/admin/users'), 500);
        } catch (error: any) {
            dispatch(userActions.userUpdateError(new Error(error.statusText || error.message || error)))
        }
    };

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Usuario: <Tag variant='outline' color={user?.status?.name === 'APPROVED' ? 'green' : 'Highlight'} >{user?.status?.name}</Tag>
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !user)} />

            <Tabs onChange={(index: number) => {
                setScreenIndexType(Object.keys(screenIndexValue).find((e) => screenIndexValue[e as ScreenIndexType] === index) as ScreenIndexType);
            }} index={screenIndexValue[screenIndexType]} isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab>Información</Tab>
                    <Tab>Cuentas</Tab>
                    <Tab>Cuentas Bancarias</Tab>
                    <Tab>KYC</Tab>
                    <Tab>Documentos</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <UserForm />
                    </TabPanel>
                    <TabPanel>
                        <Accounts />
                    </TabPanel>
                    <TabPanel>
                        <BankAccounts />
                    </TabPanel>
                    <TabPanel>
                        {(!user) ? null : <UserKYC />}
                    </TabPanel>
                    <TabPanel>
                        {(!user) ? null : <UserDocuments />}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    </>);
}