import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { MdPageview, MdOutlineSaveAlt } from "react-icons/md";
import { useTransaction } from './../context/providers/Provider';
import { useNavigate } from "react-router-dom";
import * as Actions from './../context/actions/Actions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Input, Button, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { SortingState } from "@tanstack/react-table";
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';

export default function List() {

  const navigate = useNavigate();
  const { state: { transactions, request }, dispatch } = useTransaction();
  const [toSearchDate, setToSearchDate] = useState<string>();
  const [fromSearchDate, setFromSearchDate] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchType, setSearchType] = useState<string>();

  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Tipo', name: 'type', export: true },
    { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'ID Cuenta', name: 'brokerAccount.id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'No Cuenta', name: 'brokerAccount.accountNo', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'ID Usuario', name: 'brokerAccount.brokerUser.id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Correo', name: 'brokerUser.email', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Nombre', name: 'brokerUser.fullName', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'createdAt', export: true },
    { type: COLUMN_TYPE.DATETIME, label: 'Fecha Actualización', name: 'updatedAt', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', actions: [
        {
          title: 'Ver Detalles',
          onClick: (info: any) => navigate(`/admin/transactions/view/${info?.row?.original?.id}`),
          icon: <MdPageview color="#422afb" className="h-8 w-8" />,
          isLoading: request
        }
      ]
    }
  ];

  const totalItems = useMemo(() => { return transactions?.meta?.totalItems }, [transactions]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`transactions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(Actions.transactionsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}transactions/search?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchType?.length ? `filter.type=$eq:${searchType}&` : '';

    switch (true) {
      case !!fromSearchDate?.length && !!toSearchDate?.length:
        url += `filter.createdAt=$btw:${fromSearchDate},${toSearchDate}T23:59:59`;
        break;
      case !!fromSearchDate?.length:
        url += `filter.createdAt=$gte:${fromSearchDate}`;
        break;
      case !!toSearchDate?.length:
        url += `filter.createdAt=$lte:${toSearchDate}T23:59:59`;
        break;
    }

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, sorting, fromSearchDate, toSearchDate, searchType]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const onSearch = ({ searchText, sorting, fromSearchDate, toSearchDate, searchType }: { searchText: string, sorting: SortingState, fromSearchDate: string, toSearchDate: string, searchType: string }) => {
    if (!searchText?.length && !fromSearchDate?.length && !toSearchDate?.length && !sorting?.length && !searchType.length) {
      return
    }

    let url = searchUrl;

    fetchData(`transactions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(Actions.transactionsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    let url = 'transactions?';

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'transactions/search?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    if (!onSearch || (!searchText?.length && !fromSearchDate?.length && !toSearchDate?.length && !searchType?.length)) {
      fetchData(url)
        .then((data: any) => dispatch(Actions.transactionsSuccess(data)))
        .catch((error: any) => dispatch(Actions.transactionsError(new Error(error.statusText || error.message || error))));
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, sorting, fromSearchDate, toSearchDate, searchType })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, sorting, fromSearchDate, toSearchDate, searchType]);

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${transactions?.meta?.totalItems}`;

    fetchData(`transactions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'lotes');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Desde</Text><Input defaultValue={fromSearchDate} onChange={(e) => setFromSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Desde" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Hasta</Text><Input defaultValue={toSearchDate} onChange={(e) => setToSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Hasta" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchType} onChange={(e) => setSearchType(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por Tipo'>
        <option value='UNKNOWN'>{'Desconocido'}</option>
        <option value='CSD'>{'Cash Disbursement'}</option>
        <option value='DIV'>{'Dividend'}</option>
        <option value='DIVTAX'>{'Dividend tax'}</option>
        <option value='MERGER_ACQUISITION'>{'Shares Merger/Acquisition'}</option>
        <option value='SLIP'>{'Securities Lending Rebate'}</option>
        <option value='FEE'>{'FEE'}</option>
        <option value='CSR'>{'Instant Funding'}</option>
        <option value='JNLC'>{'Funding'}</option>
        <option value='INT'>{'Interest'}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
  </Grid>, [totalItems, searchText, searchType, fromSearchDate, toSearchDate, searchUrl, transactions]);

  const BoxContent = useMemo(() => <Grid display={'flex'} gap={6}>
    {transactions?.meta?.brief ?
      <>
        <GridItem><Text>Total: <b>{ `$${transactions.meta.brief.amount?.toLocaleString()}` }</b></Text></GridItem>
      </>
      : null
    }

  </Grid>, [transactions]);


  return (<Table
    request={request}
    data={transactions}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    onPaginate={onPaginate}
    onSort={setSorting}
    boxContent={BoxContent}
  />);
}
