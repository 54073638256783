import EditForm from "./components/EditForm";
import { DepositsProvider } from './context/providers/DepositsProvider';

const EditFormView = () => {
  return (
    <DepositsProvider>
      <EditForm></EditForm>
    </DepositsProvider>
  );
};

export default EditFormView;
