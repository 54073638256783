import React, { createContext, ReactNode } from 'react';
import { userContextType } from '../types/user';
import { initialState, reducer } from '../reducers/UsersReducer';
import {useSessionReducer} from '../../../../../hooks/useSessionReducer';

export const usersContext = createContext<{
  state: userContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => {}
});

export const useUser = () => {
  const context = React.useContext(usersContext)
  if (context === undefined) {
      throw new Error('useUser must be used within a UserProvider')
  }
  return context
};

export const UsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'UsersProvider');

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <usersContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </usersContext.Provider>
  );
};
