import React from 'react';

import InputField from "components/fields/InputField";
import Alert from "components/alert/index";
import { authContext } from './context/Provider';
import { actionTypes } from './context/Reducer';
import fetchData from './../../helpers/fetchData'
import { Navigate } from "react-router-dom";
import { useToast } from '@chakra-ui/react';

export default function SignIn() {

  const { state: { data, auth } } = React.useContext(authContext);
  const { dispatch } = React.useContext(authContext);
  const [username, setUsername] = React.useState(null);
  const [request, setRequest] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [error, setError] = React.useState(null);
  const toast = useToast();

  React.useEffect(() => {

    if (!auth?.access_token) {
      return;
    }

    fetchData('auth/profile', 'GET', {}, {
      'Authorization': `Bearer ${auth?.access_token}`
    })
      .then(data => dispatch({ type: actionTypes.SET_USER_DATA, data }))
      .catch(error => setError(error));
  }, [auth, dispatch]);

  const logIn = async () => { 
    if (!username || !username) {
      toast({
        title: 'Completar los datos requeridos',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setError(null);
    try {
      setRequest(true);
      const data = await fetchData('auth/login', "POST", { username, password });
      dispatch({ type: actionTypes.SET_USER, data })
      setRequest(false);
    } catch (error) {
      setError(error)
      setRequest(false);
    }
  };

  if (data) {
    return <Navigate to="/" replace />
  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Iniciar sesión
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Ingrese su correo electrónico y contraseña para iniciar sesión
        </p>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Nombre de usuario*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          onChange={(event: React.ChangeEvent) => setUsername(((event.target) as HTMLInputElement).value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Contraseña*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          onChange={(event: React.ChangeEvent) => setPassword(((event.target) as HTMLInputElement).value)}
        />
        <button disabled={request} onClick={logIn} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Ingresar
        </button>
        {error ? <Alert title="Intentelo nuevamente" description='Compruebe las credenciales' icon={true}></Alert> : null}
      </div>
    </div>
  );
}
