import List from "./components/List";
import { DepositsProvider } from './context/providers/DepositsProvider';

const Tables = () => {
  return (
    <DepositsProvider>
      <List />
    </DepositsProvider>
  );
};

export default Tables;
