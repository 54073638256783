import React, { useMemo } from "react";
import { useAccount } from '../context/providers/AccountsProvider';
import {
    FormControl,
    Button,
    Grid,
    GridItem,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    ExpandedIndex,
} from '@chakra-ui/react';
import {
    MdFilterList,
    MdFilterListOff
} from "react-icons/md";
import * as accountActions from './../context/actions/AccountActions';
import Calendar from 'components/calendar/MiniCalendar';
import fetchData from './../../../../helpers/fetchData';
import { COLUMN_TYPE } from "components/table/Table";
import Table from "components/table";
import BackButton from "components/button/Back";

export default function TransactionsForm() {
    const { state: { request, transactions, account }, dispatch } = useAccount();

    const [index, setIndex] = React.useState<number>(null);
    const [date, setDate] = React.useState<[Date, Date]>([null, null]);
    const [showError, setShowError] = React.useState<boolean>(false);


    const submit = async () => {

        if (!account.id) {
            setShowError(true);
            dispatch(accountActions.notifyError('Completar los datos requeridos'))
            return;
        }

        try {
            if (!date[0] || !date[1]) {
                return;
            }

            const firstRangeDate: Date = date[0];
            const from = date[0].toLocaleDateString("default", { year: "numeric" }) + '-' + firstRangeDate.toLocaleDateString("default", { month: "2-digit" }) + '-' + firstRangeDate.toLocaleDateString("default", { day: "2-digit" });
            const secondRangeDate: Date = date[1];
            const to = secondRangeDate.toLocaleDateString("default", { year: "numeric" }) + '-' + secondRangeDate.toLocaleDateString("default", { month: "2-digit" }) + '-' + secondRangeDate.toLocaleDateString("default", { day: "2-digit" });

            dispatch(accountActions.accountTransactionGet());
            dispatch(accountActions.accountTransactionSuccess(await fetchData(`accounts/${account.id}/transactions?from=${from}&to=${to}`)));

            setIndex(null)
        } catch (error: any) {
            dispatch(accountActions.accountTransactionError(new Error(error.statusText || error.message || error)))
        }


    }

    React.useEffect(() => {

        if (date[0] === date[1]) {
            setIndex(0)
            return;
        }

        submit();
    }, [date]);

    const gridData = useMemo(() => {
        if (!Array.isArray(transactions)) {
            return [];
        }

        return transactions.map((depositData) => ({
            ...depositData,
            accountID: account.id
        }))
    }, [transactions, account])

    const tableColumnsData = [
        { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'finTranID', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'accountID', name: 'accountID', export: true, hidden: true },
        { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'tranAmount', export: true },
        { type: COLUMN_TYPE.MONEY, label: 'Balance de la cuenta', name: 'accountBalance', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'Tipo', name: 'finTranTypeID', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'Comentario', name: 'comment', export: true },
        { type: COLUMN_TYPE.DATE, label: 'Fecha', name: 'tranWhen', export: true }
    ];

    return (<Center>
        <Grid
            templateColumns={{
                base: 'repeat(1, 1fr)'
            }}
            gap={1}
            content="center"
            className="w-full"
        >

            <GridItem w='100%'>
                <Accordion onChange={(index: ExpandedIndex) => { setIndex(Number(index)) }} index={index} allowToggle className="my-5">
                    <AccordionItem>
                        {({ isExpanded = false }) => (
                            <>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                        </Box>
                                        {isExpanded ? (
                                            <MdFilterListOff fontSize='24px' />
                                        ) : (
                                            <MdFilterList fontSize='24px' />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>

                                    <Grid
                                        templateColumns={{
                                            base: 'repeat(1, 1fr)'
                                        }}
                                        gap={6}
                                    >
                                        <GridItem w='100%'>
                                            <FormControl isInvalid={showError} className="mb-5 items-center flex flex-col">
                                                <Calendar
                                                    onChange={(a: any, b: any) => {
                                                        if (!a[1]) {
                                                            return;
                                                        }
                                                        const firstRangeDate: Date = a[0];
                                                        const secondRangeDate: Date = a[1];
                                                        setDate([firstRangeDate, secondRangeDate]);
                                                    }}
                                                    value={date}
                                                    selectRange={true}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem display={'none'}>
                                            <Grid
                                                templateColumns={{
                                                    base: 'repeat(2, 1fr)'
                                                }}
                                                gap={2}
                                                content="center"
                                            >
                                                <GridItem w='100%'>
                                                    <Button
                                                        className="mt-5 max-w-lg center"
                                                        isLoading={request}
                                                        colorScheme="blue"
                                                        variant='solid'
                                                        onClick={submit}
                                                    > Aceptar
                                                    </Button>
                                                </GridItem>
                                                <GridItem w='100%'>
                                                    <BackButton request={request} />
                                                </GridItem>
                                            </Grid>
                                        </GridItem>
                                    </Grid>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
            </GridItem>
            <GridItem>
                <Table name={`transacciones_${account.accountNo}`} request={request} data={gridData} columns={tableColumnsData} addExport={true} />
            </GridItem>
        </Grid>
    </Center>);
}
