import React, { useEffect } from "react";
import { useUser } from '../context/providers/UsersProvider';
import { Link, useParams } from "react-router-dom";
import * as userActions from './../context/actions/UserActions';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import fetchData from './../../../../helpers/fetchData';
import { MdPageview, MdOpenInNew } from "react-icons/md";

export default function UserDocuments() {
    const { state: { documents, request, documentsUrl }, dispatch } = useUser();
    const { id } = useParams();

    useEffect(() => {
        if (!id || !dispatch) {
            return;
        }
        dispatch(userActions.userDocumentsGet());
        fetchData(`dwusers/${id}/documents`)
            .then((data: any) => dispatch(userActions.userDocumentsSuccess(data)))
            .catch((error: any) => dispatch(userActions.userDocumentsError(new Error(error.statusText || error.message || error))));
    }, [dispatch, id]);


    const getDocumentUrl = async (documentId: string) => {

        dispatch(userActions.userDocumentUrlGet());
        fetchData(`dwusers/${id}/document/${documentId}/url`)
            .then((data: any) => dispatch(userActions.userDocumentUrlSuccess({ [documentId]: data })))
            .catch((error: any) => dispatch(userActions.userDocumentsError(new Error(error.statusText || error.message || error))));
    }

    return (<TableContainer>
        <Table variant='simple'>
            <TableCaption>{documents?.length ? documents.length : 'Sin'} Documentos</TableCaption>
            <Thead>
                <Tr>
                    <Th><b>Recibido</b></Th>
                    <Th><b>Estado</b></Th>
                    <Th><b>Tipo</b></Th>
                    <Th><b>Acciones</b></Th>
                </Tr>
            </Thead>
            <Tbody>
                {documents.map((document, key) => {
                    return <Tr key={key}>
                        <Td>{new Date(document.receivedWhen).toLocaleDateString() + ' ' + new Date(document.receivedWhen).toLocaleTimeString()}<br />{document.comment?.length ? `${document.comment}` : null}</Td>
                        <Td>{document.status?.name}<br />{document.status?.description?.length ? `${document.status?.description}` : null}</Td>
                        <Td>{document.type?.name} {document.side?.length ? ` (${document.side})` : null}</Td>
                        <Td>
                            <button
                                hidden={!!(documentsUrl as any)?.[document.documentID]}
                                disabled={request}
                                onClick={() => getDocumentUrl(document.documentID)}
                                className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                    ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                    : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                    } linear justify-center rounded-lg font-bold transition duration-200`}
                            >
                                <MdPageview className="h-6 w-6" />
                            </button>


                            <Link
                                target="_blank"
                                to={(documentsUrl as any)?.[document.documentID]?.url}
                                hidden={!(documentsUrl as any)?.[document.documentID]}
                                className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                    ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                    : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                    } linear justify-center rounded-lg font-bold transition duration-200`}
                            >
                                <MdOpenInNew className="h-6 w-6" />
                            </Link>
                        </Td>
                    </Tr>
                })}
            </Tbody>
        </Table>
    </TableContainer>
    );
}