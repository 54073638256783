import React, { useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import { useAccount } from '../context/providers/AccountsProvider';
import fetchData from './../../../../helpers/fetchData';
import * as accountActions from './../context/actions/AccountActions';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, Button, Divider, Text } from '@chakra-ui/react'
import AccountForm from './Form';
import AccountTransactions from './Transactions';
import AccountPerformance from './Performance';
import AccountStatements from './Statements';
import AccountTaxForms from './TaxForms';
import DepositsForms from './Deposits';
import WithdrawalsForms from './Redemptions';
import { MdPersonOutline } from "react-icons/md";
import Spinner from "components/spinner";
import BackButton from "components/button/Back";

enum ScreenIndexType {
    default = 'default',
    performance = 'performance',
    transacciones = 'transacciones',
    depositos = 'depositos',
    retiros = 'retiros',
    declaraciones = 'declaraciones',
    formularios = 'formularios',
};

const defaultScreenIndex: ScreenIndexType = ScreenIndexType.default;

type ScreenIndexValueType = {
    default: number;
    performance: number;
    transacciones: number;
    depositos: number;
    retiros: number;
    declaraciones: number;
    formularios: number;
};

const screenIndexValue: ScreenIndexValueType = {
    default: 0,
    performance: 1,
    transacciones: 2,
    depositos: 3,
    retiros: 4,
    declaraciones: 5,
    formularios: 6
};


export default function ViewForm() {
    const { id, screen = 'default' } = useParams();
    const navigate = useNavigate();
    const { dispatch, state: { request, account, error } } = useAccount();
    const [screenIndexType, setScreenIndexType] = React.useState<ScreenIndexType>((screen as ScreenIndexType) || defaultScreenIndex)

    const isLoading = useMemo(() => {
        return !account || request || error;
    }, [account, request, error]);

    React.useEffect(() => {
        dispatch(accountActions.accountsClear());
    }, [dispatch]);


    const getData = useCallback(() => {
        dispatch(accountActions.accountGet());
        fetchData(`accounts/${id}`)
            .then((data: any) => {
                dispatch(accountActions.accountGetSuccess(data));
            })
            .catch((error: any) => dispatch(accountActions.accountGetError(new Error(error.statusText || error.message || error))));
    }, [dispatch, id]);

    React.useEffect(() => {
        getData();
    }, [getData]);


    return (<>
        <Spinner hidden={!isLoading} />
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div style={{ visibility: !!account ? 'visible' : 'hidden' }} className="text-xl font-bold text-navy-700 dark:text-white flex justify-center gap-2 items-center">
                    <Text hidden={!account}>Cuenta: {account?.accountNo} </Text>
                    <Tag variant='outline' color={account?.status?.name === 'OPEN' ? 'green' : 'Highlight'} >{account?.status?.name}</Tag>

                    <button
                        hidden={!account?.userID}
                        disabled={request}
                        onClick={() => navigate(`/admin/users/view/${account?.userID}`)}
                        className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                            : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                            } linear justify-center rounded-lg font-bold transition duration-200`}
                    >
                        <MdPersonOutline className="h-6 w-6" />
                    </button>
                </div>
                <div className={`flex items-center gap-5`}>
                    <Button
                        onClick={() => { navigate(`/admin/accounts/edit/${account?.id}`) }}
                        className={`text-xl hover:cursor-pointer py-2 px-4 ${request || !account?.userID
                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                            : "bg-lightPrimary text-brand-400 hover:text-brand-700 dark:bg-lightPrimary/40 dark:text-white dark:hover:text-lightPrimary dark:hover:bg-white/20 dark:active:bg-white/10"
                            } linear justify-center rounded-lg font-medium`}
                    >
                        Editar
                    </Button>
                    <BackButton request={!!(request || !account?.userID)} />
                </div>
            </div>

            <Tabs style={{ visibility: !!account ? 'visible' : 'hidden' }} onChange={(index: number) => {
                setScreenIndexType(Object.keys(screenIndexValue).find((e) => screenIndexValue[e as ScreenIndexType] === index) as ScreenIndexType);
            }} index={screenIndexValue[screenIndexType]} isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab>Información</Tab>
                    <Tab>Performance</Tab>
                    <Tab>Transacciones</Tab>
                    <Tab>Depositos</Tab>
                    <Tab>Retiros</Tab>
                    <Tab>Resumenes</Tab>
                    <Tab>Formularios</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AccountForm />
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.performance) ? <AccountPerformance /> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.transacciones) ? <AccountTransactions /> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.depositos) ? <DepositsForms /> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.retiros) ? <WithdrawalsForms /> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.declaraciones) ? <AccountStatements /> : null}
                    </TabPanel>
                    <TabPanel>
                        {(screenIndexValue[screenIndexType] === screenIndexValue.formularios) ? <AccountTaxForms /> : null}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    </>);
}