import List from "./components/List";
import { SettlementsProvider } from './context/providers/SettlementsProvider';

const Tables = () => {
  return (
    <SettlementsProvider>
      <List />
    </SettlementsProvider>
  );
};

export default Tables;
