import React, { useEffect, useMemo, useState } from "react";
import Table from "components/table/TableWithAccordion";
import { COLUMN_TYPE } from "components/table/Table";
import { useFee } from '../context/providers/FeeProvider';
import { useNavigate } from "react-router-dom";
import fetchData from '../../../../helpers/fetchData';
import { Input, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { SortingState } from "@tanstack/react-table";
import * as depositActions from '../context/actions/FeeActions';
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';


export default function List() {

  const { state: { fees, request }, dispatch } = useFee();
  const [toSearchDate, setToSearchDate] = useState<string>();
  const [fromSearchDate, setFromSearchDate] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);


  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'Mes', name: 'month', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Año', name: 'year', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Mantenimiento Cuentas', name: 'details.maintenance.accounts', export: true },
    { type: COLUMN_TYPE.MONEY, label: 'Mantenimiento Monto', name: 'details.maintenance.partialAmount', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Apertura Cuentas', name: 'details.opening.accounts', export: true},
    { type: COLUMN_TYPE.MONEY, label: 'Apertura Monto', name: 'details.opening.partialAmount', export: true},
    { type: COLUMN_TYPE.MONEY, label: 'Monto Total', name: 'totalAmount', export: true}
  ];

  const totalItems = useMemo(() => { return fees?.meta?.totalItems }, [fees]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`accounts/fee-estimations?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(depositActions.feesSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const onSearch = ({ sorting, fromSearchDate, toSearchDate }: { sorting: SortingState, fromSearchDate: string, toSearchDate: string }) => {
    if (!fromSearchDate?.length && !toSearchDate?.length && !sorting?.length) {
      return
    }

    let url = `${`${baseB2BUrl}broker/accounts/fee-estimations?`}`;

    switch (true) {
      case !!fromSearchDate?.length && !!toSearchDate?.length:
        url += `desde=${fromSearchDate}&hasta=${toSearchDate}T23:59:59`;
        break;
      case !!fromSearchDate?.length:
        url += `desde=${fromSearchDate}`;
        break;
      case !!toSearchDate?.length:
        url += `hasta=${toSearchDate}T23:59:59`;
        break;
    }

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    fetchData(`accounts/fee-estimations?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(depositActions.feesSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    let url = 'accounts/fee-estimations?';

    if (!onSearch || (!fromSearchDate?.length && !toSearchDate?.length)) {
      fetchData(url)
        .then((data: any) => dispatch(depositActions.feesSuccess(data)))
        .catch((error: any) => dispatch(depositActions.feesError(new Error(error.statusText || error.message || error))));
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ sorting, fromSearchDate, toSearchDate })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [sorting, fromSearchDate, toSearchDate]);

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Desde</Text><Input defaultValue={fromSearchDate} onChange={(e) => setFromSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Desde" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Hasta</Text><Input defaultValue={toSearchDate} onChange={(e) => setToSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Hasta" /></GridItem>
    <GridItem w='100%'>
    </GridItem>
    <GridItem w='100%'>
    </GridItem>
  </Grid>, [totalItems, fromSearchDate, toSearchDate]);

  return (<Table
    request={request}
    data={fees}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    onPaginate={onPaginate}
    onSort={setSorting}
  />);
}
