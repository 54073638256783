import { Dispatch, Reducer, useCallback, useEffect, useReducer } from 'react';

const initializer =
  (key: string) =>
  <T>(initial: T) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) return initial;
    return JSON.parse(stored);
  };

export const useSessionReducer = <T extends object, A>(
  reducer: Reducer<T, A>,
  initialState: T,
  key: string,
): [T, Dispatch<A>, VoidFunction] => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer(key));
  const clearValue = useCallback(() => sessionStorage.removeItem(key), [key]);

  useEffect(() => {
    if(!key){
      return;
    }
    
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, dispatch, clearValue];
};