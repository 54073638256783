export enum tableTypes {
    TABLES_NOTIFY_CLEAR = 'TABLES_NOTIFY_CLEAR',
    TABLES_NOTIFY = 'TABLES_NOTIFY',

    TABLES_GET = 'TABLES_GET',
    TABLES_GET_SUCCESS = 'TABLES_GET_SUCCESS',
    TABLES_CLEAR = 'TABLES_CLEAR',

    TABLES_SEARCH_TEXT = 'TABLES_SEARCH_TEXT',
    

    TABLES_SET_ERROR = 'TABLES_SET_ERROR',
    TABLES_CLEAR_ERROR = 'TABLES_CLEAR_ERROR',
};
