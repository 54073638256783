import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { usePartner } from '../context/providers/PartnersProvider';
import PartnerForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as partnerActions from './../context/actions/PartnerActions';
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, partner, error } } = usePartner();

    React.useEffect(() => {
        if (!request && partner?.id != id) {
            dispatch(partnerActions.partnerGet());
            fetchData(`partners/${id}`)
                .then((data: any) => dispatch(partnerActions.partnerGetSuccess(data)))
                .catch((error: any) => dispatch(partnerActions.partnerGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, partner]);

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Detalle del Partner
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !partner) } />
            {(!partner) ? null : <PartnerForm data={partner} />}
        </Card>
    </>);
}