import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  MdPageview,
  MdPersonOutline,
  MdEdit
} from "react-icons/md";
import { useAccount } from '../context/providers/AccountsProvider';
import { useNavigate } from "react-router-dom";
import * as accountActions from './../context/actions/AccountActions';
import fetchData from './../../../../helpers/fetchData';
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { statusesLabel, statusesValues } from "./../context/types/account.d";
import { Grid, GridItem, Text, Button, Select, Input } from '@chakra-ui/react';
import { MdOutlineSaveAlt } from "react-icons/md";
import { SortingState } from "@tanstack/react-table";
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';

export default function List({ userId }: { userId?: string }) {

  const navigate = useNavigate();
  const { state: { accounts, request }, dispatch } = useAccount();
  const [searchText, setSearchText] = useState<string>();
  const [searchState, setSearchState] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);


  React.useEffect(() => {
    dispatch(accountActions.accountClear());
  }, [dispatch]);

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}broker/accounts?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchState?.length ? `filter.status=$eq:${searchState}&` : '';

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, searchState, sorting]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const getData = useCallback(() => {
    if (userId) {
      return;
    }

    let url = 'accounts?'

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'accounts?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    dispatch(accountActions.accountsGet());
    fetchData(`${url}`)
      .then((data: any) => dispatch(accountActions.accountsSuccess(data)))
      .catch((error: any) => dispatch(accountActions.accountsError(new Error(error.statusText || error.message || error))));

  }, [dispatch, userId, sorting]);

  const getDataByUserId = useCallback(() => {
    if (!userId) {
      return
    }

    dispatch(accountActions.accountsGet());
    fetchData(`dwusers/${userId}/accounts`)
      .then((data: any) => dispatch(accountActions.accountsSuccess(data)))
      .catch((error: any) => dispatch(accountActions.accountsError(new Error(error.statusText || error.message || error))));

  }, [dispatch, userId]);

  useEffect(() => {
    return getDataByUserId();
  }, [getDataByUserId]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(accountActions.accountsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const onSearch = ({ searchText, searchState, sorting }: { searchText: string, searchState: string, sorting: SortingState }) => {
    if (!searchText?.length && !searchState?.length) {
      return
    }

    let url = searchUrl;

    fetchData(`accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(accountActions.accountsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${accounts?.meta?.totalItems}`;
    fetchData(`accounts?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'cuentas');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    if (!onSearch || (!searchText?.length && !searchState?.length)) {
      getData();
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, searchState, sorting })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, getData, searchState, sorting]);


  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Correo', name: userId ? 'email' : 'brokerUser.email', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Nombre', name: userId ? 'fullName' : 'brokerUser.fullName', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Cuenta', name: 'accountNo', export: true },
    { type: COLUMN_TYPE.MONEY, label: 'Balance', name: 'balance', export: true },
    { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status.name', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: userId ? 'createdWhen' : 'createdAt', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', actions: [
        {
          title: 'Ver Usuario',
          onClick: (info: any) => navigate(`/admin/users/view/${info.row.original.userID}`),
          icon: <MdPersonOutline color="#422afb" className="h-8 w-8" />,
          isLoading: request,
          isHidden: !!userId
        },
        {
          title: 'Ver Detalles',
          onClick: (info: any) => {
            navigate(`/admin/accounts/view/${info.row.original.id}`)
          },
          icon: <MdPageview color="#422afb" className="h-8 w-8" />,
          isLoading: request
        },
        {
          title: 'Editar',
          onClick: (info: any) => {
            navigate(`/admin/accounts/edit/${info.row.original.id}`)
          },
          icon: <MdEdit color="#422afb" className="h-8 w-8" />,
          isLoading: request
        }
      ]
    }
  ];

  const totalItems = useMemo(() => { return accounts?.meta?.totalItems }, [accounts]);

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchState} onChange={(e) => setSearchState(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por estado'>
        <option value={statusesValues.open}>{statusesLabel.open}</option>
        <option value={statusesValues.closed}>{statusesLabel.closed}</option>
        <option value={statusesValues.frozen}>{statusesLabel.frozen}</option>
        <option value={statusesValues.openNoNewTrades}>{statusesLabel.openNoNewTrades}</option>
        <option value={statusesValues.pending}>{statusesLabel.pending}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
    <GridItem w='100%' />
  </Grid>, [searchText, setSearchText, searchState, setSearchState, accounts, searchUrl]);

  const BoxContent = useMemo(() => <Grid display={'flex'} gap={6}>
    {accounts?.meta?.brief ?
      <>
        <GridItem><Text>Total: <b>{`$${accounts.meta.brief.balanceAmount?.toLocaleString()}`}</b></Text></GridItem>
      </>
      : null
    }

  </Grid>, [accounts]);

  return (<Table
    request={request}
    data={accounts}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    onPaginate={onPaginate}
    onSort={setSorting}
    boxContent={BoxContent}
  />);
};
