import React, { useState, useMemo, useEffect } from "react";
import { useRedemption } from './../context/providers/RedemptionProvider';
import * as redemptionActions from './../context/actions/RedemptionActions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Text } from "@chakra-ui/react";
import Modal from "components/modal";
import { typesLabel, typesValues } from './enums';

const UNKNOW_ERROR_TEXT = 'Unknow Error';
const INTERNAL_SERVER_ERROR_TEXT = 'Internal Server Error';

export default function EvaluationModal({ title = "Enviar Solicitud de Retiro", comfirmIsOpen, setComfirmIsOpen, data }: { title?: string, data: any, comfirmIsOpen?: boolean, setComfirmIsOpen: Function }) {

  const { dispatch } = useRedemption();
  const [error, setError] = useState<Error>();
  const [type, setType] = useState<string>();
  const [isLoading, setSsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (comfirmIsOpen && data?.type) {
      setType(typesValues[data.type]);
      setError(null);
    }
  }, [comfirmIsOpen, data])

  const isSameNameAccount = useMemo(() => {
    return data?.brokerAccount?.brokerUser?.fullName?.toUpperCase() === data?.bankAccount?.accountHolderName?.toUpperCase()
  }, [data]);

  const changeBankAccountEvaluation = async () => {
    try {
      if (!data || !type) {
        return setError(new Error('Completar el formulario'));
      }
      setSsLoading(true);

      dispatch(redemptionActions.redemptionPatch());
      dispatch(redemptionActions.redemptionPatchSuccess(await fetchData(`funding/redemptions/${data.qID}/sent`, 'PUT', {
        type: type
      })));

      dispatch(redemptionActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
      setComfirmIsOpen(false);
      setType(null);
      setError(null);
      setSsLoading(false);

    } catch (error: any) {
      if (!error.statusText && error.status) {
        switch (error.status) {
          case 500:
            error.statusText = INTERNAL_SERVER_ERROR_TEXT
            break;

          default:
            error.statusText = UNKNOW_ERROR_TEXT
            break;
        }
      }
      setError(new Error(error.statusText));
      dispatch(redemptionActions.notifyError(error.statusText || error.message || error))
      setSsLoading(false);

    }
  };

  return (<>
    <Modal
      isLoading={isLoading}
      isOpen={comfirmIsOpen}
      onClose={(result) => {
        if (!result) {
          setComfirmIsOpen(false)
          return;
        }

        changeBankAccountEvaluation();
      }}
      children={<div>
        <div hidden={isSameNameAccount}>
          <Text>Nombre de Usuario: {data?.brokerAccount?.brokerUser?.fullName}</Text>
          <Text>Nombre Cuenta de Banco: {data?.bankAccount?.accountHolderName}</Text>
          <Text hidden={isSameNameAccount} color={'red'}>Solo se permiten los retiros a cuentas del mismo nombre</Text>
        </div>
        <br />
        <Select disabled={true} placeContent={'Declaración'} value={type} onChange={(e) => setType(e.target?.selectedOptions?.[0]?.value)} placeholder='Tipo*'>
          <option value={typesValues.ACH}>{typesLabel.ACH}</option>
          <option value={typesValues.WIRE}>{typesLabel.WIRE}</option>
          <option value={typesValues.BULK_FUNDING}>{typesLabel.BULK_FUNDING}</option>
        </Select>

        <br />
        <div hidden={!error}>
          <Text color={'red'}>{error?.message}</Text>
        </div>
      </div>}
      title={title}
    />
  </>);
};
