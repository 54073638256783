import { tableContextType, Action, PaginateData } from './../types/table';
import { tableTypes } from './../actions/TableActionTypes';

//the initial state of the user
export const initialState: tableContextType = {
  request: false,
  notification: null,
  error: null,
  data: { data: [], links: {}, meta: {} },
  data2: null,
  searchText: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: tableContextType, action: Action) => {

  switch (action.type) {

    case tableTypes.TABLES_GET:
      return {
        ...state,
        request: true,
        data: { data: [], links: {}, meta: {} } as PaginateData,
        error: null
      };

    case tableTypes.TABLES_CLEAR:
      return {
        ...state,
        data: { data: [], links: {}, meta: {} } as PaginateData,
        searchText: null
      };

    case tableTypes.TABLES_GET_SUCCESS:
      return {
        ...state,
        request: false,
        data: action.data,
        data2: {
          ...state.data2,
          [action.data.resource]: action.data
        }
      };

    case tableTypes.TABLES_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case tableTypes.TABLES_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    case tableTypes.TABLES_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data
      };

    case tableTypes.TABLES_SEARCH_TEXT:
      return {
        ...state,
        request: false,
        searchText: action.data
      };


    default:
      return state;
  }
};