import React from "react";
import Card from "components/card";
import { TABLE_COLUMN_TYPE } from "components/table/Table";
import Table from "components/table";
import {
  MdFilterList,
  MdFilterListOff,
} from "react-icons/md";
import { Box } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';

type PaginateDataMeta = {
  currentPage: number;
  itemsPerPage: number;
  sortBy: any;
  totalItems: number
  totalPages: number;
};

type PaginateDataLinks = {
  current?: string;
  last?: string;
  next?: string;
};

type PaginateData = {
  data: any;
  links: PaginateDataLinks;
  meta: PaginateDataMeta;
};

type TableWithAccordionType = {
  accordionContent: any;
  boxContent?: any;
  data?: any;
  columns: TABLE_COLUMN_TYPE[];
  request: boolean;
  addExport?: boolean;
  name?: string;
  paginate?: PaginateData;
  resource?: string;
  onSearch?: Function;
  onPaginate?: Function;
  onSort?: Function;
};

export default function TableWithAccordion({ onSort, onSearch, onPaginate, resource, paginate, columns, data, request, accordionContent, addExport, name, boxContent = <></> }: TableWithAccordionType) {

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <Accordion allowToggle className="my-5">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='right'>
                    Filtros
                  </Box>
                  {isExpanded ? (
                    <MdFilterListOff fontSize='24px' />
                  ) : (
                    <MdFilterList fontSize='24px' />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {accordionContent}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <Table onSort={onSort} onSearch={onSearch} onPaginate={onPaginate} resource={resource} paginate={paginate} name={name} request={request} data={data} columns={columns} addExport={addExport} />
      <Box>
        {boxContent}
      </Box>
    </Card>
  );
}
