export enum transationTypes {
    DEPOSIT_NOTIFY_CLEAR = 'DEPOSIT_NOTIFY_CLEAR',
    DEPOSIT_NOTIFY = 'DEPOSIT_NOTIFY',

    DEPOSITS_GET = 'DEPOSITS_GET',
    DEPOSITS_GET_SUCCESS = 'DEPOSITS_GET_SUCCESS',

    DEPOSIT_GET = 'DEPOSIT_GET',
    DEPOSIT_GET_SUCCESS = 'DEPOSIT_GET_SUCCESS',

    DEPOSIT_ADD = 'DEPOSIT_ADD',
    DEPOSIT_ADD_SUCCESS = 'DEPOSIT_ADD_SUCCESS',

    DEPOSIT_DELETE_GET = 'DEPOSIT_DELETE_GET',
    DEPOSIT_DELETE_GET_SUCCESS = 'DEPOSIT_DELETE_GET_SUCCESS',

    DEPOSIT_UPDATE_GET = 'DEPOSIT_UPDATE_GET',
    DEPOSIT_UPDATE_GET_SUCCESS = 'DEPOSIT_UPDATE_GET_SUCCESS',

    DEPOSIT_SET_ERROR = 'DEPOSIT_SET_ERROR',

    DEPOSIT_PATCH = 'DEPOSIT_PATCH',
    DEPOSIT_PATCH_SUCCESS = 'DEPOSIT_PATCH_SUCCESS'
};
