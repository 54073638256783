import { FeeContextType, Action } from './../types/fee.d';
import { transationTypes } from '../actions/FeeActionTypes';

//the initial state of the user
export const initialState: FeeContextType = {
  request: false,
  notification: null,
  error: null,
  fees: { meta: {}, links: {}, data: [] },
  fee: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: FeeContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.FEES_GET:
      return {
        ...state,
        request: true,
        error: null,
        fee: null
      };
    case transationTypes.FEES_GET_SUCCESS:
      return {
        ...state,
        request: false,
        fees: action.data,
      };


    //GET
    case transationTypes.FEE_GET:
      return {
        ...state,
        request: true,
        fee: null
      };
    case transationTypes.FEE_GET_SUCCESS:
      return {
        ...state,
        request: false,
        fee: action.data,
      };


    //ADD
    case transationTypes.FEE_ADD:
      return {
        ...state,
        request: true,
        fee: null
      };

    case transationTypes.FEE_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        fee: action.data,
      };




    //NOTIFY
    case transationTypes.FEE_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.FEE_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.FEE_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.FEE_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.FEE_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.FEE_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.FEE_UPDATE_GET_SUCCESS:
      return {
        ...state,
        fee: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};