import { transationTypes } from './../actions/CircuitTransferActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface IcircuitTransfer {
	id?: string;
	accountID: string;
	moneyType: string;
	amount: string;
	status: string;
	transactionType: string;
	createdAt: string;
	updatedAt?: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export interface ITransactionGrid {
	data: IcircuitTransfer[]
}

export type circuitTransferContextType = {
    circuitTransfers: ITransactionGrid;
	circuitTransfer: IcircuitTransfer;
	request: boolean;
	updated: boolean;
	deleted: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};