import AccountList from "./components/List";
import { AccountsProvider } from './context/providers/AccountsProvider';

const Accounts = ({userId}:{userId?:string}) => {
  return (
    <AccountsProvider>
      <AccountList userId={userId}/>
    </AccountsProvider>
  );
};

export default Accounts;
