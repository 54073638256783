import React from "react";
import { useCircuitTransfer } from '../context/providers/CircuitTransfersProvider';
import { useNavigate } from "react-router-dom";
import {
    Container,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Button,
} from '@chakra-ui/react';
import { IcircuitTransfer } from "../context/types/circuitTransfer";
import * as circuitTransferActions from './../context/actions/CircuitTransferActions';
import BackButton from "components/button/Back";


export default function AddForm({ data, onSubmit }: { data?: IcircuitTransfer, onSubmit: Function }) {
    const { state, dispatch } = useCircuitTransfer();
    const navigate = useNavigate();

    const [showError, setShowError] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<string>(data?.status || 'pending');
    const [type, setType] = React.useState<string>(data?.transactionType || undefined);
    const [moneyType, setMoneyType] = React.useState<string>(data?.moneyType || 'usd');
    const [amount, setAmount] = React.useState<number>(Number(data?.amount) || 0);
    const [account, setAccount] = React.useState<string>(data?.accountID || undefined);

    const isEdited = React.useMemo<boolean>(()=> {
        if(!data) {
            return true
        }

        const isEdited: boolean = data.transactionType !== type || moneyType !== data.moneyType || Number(data.amount) !== amount || account !== data.accountID || data.status !== status;
        return isEdited;
    }, [data, type, moneyType, amount, account, status]);

    const volver = () => {
        navigate('/admin/circuit-transfer')
    }

    const submit = async () => {

        if (!type || !moneyType || !amount || !account || !status) {
            setShowError(true);
            dispatch(circuitTransferActions.notifyError('Completar los datos requeridos'))
            return;
        }

        const circuitTransferData: IcircuitTransfer = {
            accountID: account,
            status: status,
            createdAt: (new Date()).toString(),
            transactionType: type,
            moneyType,
            amount: amount.toString()
        }

        onSubmit(circuitTransferData);
    }

    return (<Container centerContent>

        <FormControl isInvalid={showError && !account} className="mb-5">
            <FormLabel>Cuenta</FormLabel>
            <Input disabled={!!data} min="0" value={account} onChange={(e) => setAccount(e.target.value)} type='text' />
            <FormErrorMessage>Requerido</FormErrorMessage>
            <FormHelperText hidden={!!data}>Cuenta de la transaccion.</FormHelperText>
        </FormControl>

        <FormControl isInvalid={showError && !type} className="mb-5">
            <FormLabel>Tipo</FormLabel>
            <Select disabled={!!data} onChange={(e) => setType(e.target.value)} value={type} placeholder='Seleccionar el tipo de transaccion'>
                <option value="deposit">Deposito</option>
                <option value="withdraw">Retiro</option>
            </Select>
            <FormErrorMessage>Requerido</FormErrorMessage>
            <FormHelperText hidden={!!type}>Tipo de la transaccion.</FormHelperText>
        </FormControl>

        <FormControl isInvalid={showError && !status} className="mb-5">
            <FormLabel>Estado</FormLabel>
            <Select disabled={!data} onChange={(e) => setStatus(e.target.value)} value={status} placeholder='Seleccionar el estado de transaccion'>
                <option disabled={!!data} value="pending">Pendiente</option>
                <option value="requested">Solicitada</option>
                <option value="executed">Ejecutada</option>
                <option value="refused">Rechazada</option>
            </Select>
            <FormErrorMessage>Requerido</FormErrorMessage>
            <FormHelperText hidden={!!type}>Estado de la transaccion.</FormHelperText>
        </FormControl>

        <FormControl isInvalid={showError && !moneyType} className="mb-5">
            <FormLabel>Moneda</FormLabel>
            <Select disabled={!!data} value={moneyType} onChange={(e) => setMoneyType(e.target.value)} placeholder='Seleccionar la moneda'>
                <option value="usd">USD</option>
            </Select>
            <FormErrorMessage>Requerido</FormErrorMessage>
            <FormHelperText hidden={!!moneyType}>Moneda de la transaccion.</FormHelperText>
        </FormControl>

        <FormControl isInvalid={showError && !amount} className="mb-5">
            <FormLabel>Monto</FormLabel>
            <Input disabled={!!data}  min="0" value={amount} onChange={(e) => setAmount(Number(e.target.value))} type='number' />
            <FormErrorMessage>Requerido</FormErrorMessage>
            <FormHelperText hidden={!!amount}>Monto de la transaccion.</FormHelperText>
        </FormControl>

        {isEdited ? <Button
            className="mt-5 max-w-lg center"
            isLoading={state.request}
            colorScheme="blue"
            variant='solid'
            onClick={submit}
        >
            { isEdited && !data ? 'Agregar' : 'Editar' }
        </Button> : <BackButton request={state.request} />}
    </Container>);
}