import EditForm from "./components/EditForm";
import { DrivewealthUsersProvider } from './context/providers/UsersProvider';

const EditFormView = () => {
  return (
    <DrivewealthUsersProvider>
      <EditForm></EditForm>
    </DrivewealthUsersProvider>
  );
};

export default EditFormView;
