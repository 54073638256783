import List from "./components/List";
import { FeeProvider } from './context/providers/FeeProvider';

const Tables = () => {
  return (
    <FeeProvider>
      <List />
    </FeeProvider>
  );
};

export default Tables;
