import List from "./components/List";
import { DrivewealthUsersProvider } from './context/providers/UsersProvider';

const DrivewealthUserList = () => {
  return (
    <DrivewealthUsersProvider>
      <List />
    </DrivewealthUsersProvider>
  );
};

export default DrivewealthUserList;
