import { transationTypes } from './../actions/ActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface Itransaction {
	id?: string;
	amount: string;
	type: string;
	createdAt: string;
	updatedAt: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export interface ITransactionGrid {
	data: Itransaction[];
	meta: any;
	links:any;
}

export type transactionContextType = {
    transactions: ITransactionGrid;
	transaction: Itransaction;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};