import { settlementContextType, Action } from '../types/settlement';
import { transationTypes } from '../actions/SettlementActionTypes';

//the initial state of the user
export const initialState: settlementContextType = {
  request: false,
  notification: null,
  error: null,
  settlements: { meta:{}, links: {}, data: [] },
  settlement: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: settlementContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.SETTLEMENTS_GET:
      return {
        ...state,
        request: true,
        error: null,
        settlement: null
      };
    case transationTypes.SETTLEMENTS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        settlements: action.data,
      };


    //GET
    case transationTypes.SETTLEMENT_GET:
      return {
        ...state,
        request: true,
        settlement: null
      };
    case transationTypes.SETTLEMENT_GET_SUCCESS:
      return {
        ...state,
        request: false,
        settlement: action.data,
      };


    //ADD
    case transationTypes.SETTLEMENT_ADD:
      return {
        ...state,
        request: true,
        settlement: null
      };

    case transationTypes.SETTLEMENT_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        settlement: action.data,
      };




    //NOTIFY
    case transationTypes.SETTLEMENT_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.SETTLEMENT_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.SETTLEMENT_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.SETTLEMENT_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.SETTLEMENT_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.SETTLEMENT_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.SETTLEMENT_UPDATE_GET_SUCCESS:
      return {
        ...state,
        settlement: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};