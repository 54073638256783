import React, { useState, useEffect } from "react";
import {
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react';
import { MdAccountBalanceWallet, MdPersonOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Table, { COLUMN_TYPE } from "components/table/Table";


export default function AddForm({ data }: { data?: any }) {

    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);

    useEffect(() => {

        const transactionsData = Array.isArray(data?.transactions?.data) ? data?.transactions?.data : data?.transactions?.data?.breakdown;

        if (!Array.isArray(transactionsData)) {
            return;
        }

        const gridData: any = [];

        transactionsData.forEach((transactionsData: any) => {
            gridData.push(...transactionsData.transactions.map((transaction: any) => ({
                ...transaction,
                accountID: transactionsData.accountID,
                userID: transactionsData.userID,
                accountNo: transactionsData.accountNo,
                paymentID : transaction.paymentID || transaction?.details?.depositID || transaction?.details?.withdrawalID
            })));
        });
        

        setGridData(gridData);
    }, [data]);

    const tableColumnsData = [
        { type: COLUMN_TYPE.TEXT, label: 'ID Usuario', name: 'userID', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'ID Cuenta', name: 'accountID', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'Nro. Cuenta', name: 'accountNo', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'paymentID', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'Categoría', name: 'category', export: true },
        { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
        { type: COLUMN_TYPE.DATETIME, label: 'Fecha Creación', name: 'created', export: true },
        {
            type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', actions: [
                {
                    title: 'Ver Usuario',
                    onClick: (info: any) => navigate(`/admin/users/view/${info.row.original.userID}`),
                    icon: <MdPersonOutline className="h-8 w-8" />
                },
                {
                    title: 'Ver Cuenta',
                    onClick: (info: any) => navigate(`/admin/accounts/view/${info.row.original.accountID}`),
                    icon: <MdAccountBalanceWallet className="h-8 w-8" />
                }
            ]
        }
    ];

    return (<>
        <Grid
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
            }}
            gap={6}
            rowGap={12}
        >
            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        ID:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.id}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem hidden={!data.category} w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Categoría:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.category}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Monto Total:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.amounts?.total || data?.amount?.total }
                </Text>
            </GridItem>

            <GridItem hidden={!data?.amount?.debits} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Debitos:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.amount?.debits}
                </Text>
            </GridItem>

            <GridItem hidden={!data?.amount?.credits} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Creditos:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.amount?.credits}
                </Text>
            </GridItem>

            <GridItem hidden={!data?.amounts?.deposits} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Monto Total Depositado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.amounts?.deposits}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Estado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.status}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Comentario:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.statusComment}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Creado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.created || data.createdAt).toLocaleDateString() + ' ' + new Date(data.created || data.createdAt).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Actualizado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.updated || data.updatedAt).toLocaleDateString() + ' ' + new Date(data.updated || data.updatedAt).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem hidden={!data.updatedBy} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Actualizado Por:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.updatedBy}
                </Text>
            </GridItem>

            <GridItem hidden={!data.transactions?.total} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Total:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.transactions?.total}
                </Text>
            </GridItem>

            <GridItem hidden={!data.transactions?.failed} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Fallidas:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.transactions?.failed}
                </Text>
            </GridItem>

            <GridItem hidden={!data.transactions?.succeeded} w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Exitosas:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.transactions?.succeeded}
                </Text>
            </GridItem>

            <GridItem hidden={!data.breakdownReport} w='100%' colSpan={2}>
                <Text decoration={'underline'} color={'blue'} mr='2' pt='2' fontSize='sm'>
                    <a target="_BLANK" href={`${data.breakdownReport}`}>Ver Reporte</a>
                </Text>
            </GridItem>

            
        </Grid>
        <br />
        <br />
        <br />
        <Grid hidden={!gridData.length}>
            <GridItem>
                <Table
                    name={`${data.category || 'NETTING'}`}
                    request={false}
                    data={gridData}
                    columns={tableColumnsData}
                    addExport={true}
                />
            </GridItem>
        </Grid>
    </>);
}