import React from "react";
import {
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react';
import { statusesLabel } from './List';

export default function AddForm({ data }: { data?: any }) {


    return (<>
        <Grid
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
            }}
            gap={6}
            rowGap={12}
        >
            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        ID:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.id}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Nombre:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.bankAccountDetails?.bankAccountNickname}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Numero:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.bankAccountDetails?.bankAccountNumber}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Numero Ruta:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.bankAccountDetails?.bankRoutingNumber}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Nombre del banco:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.bankAccountDetails?.bankName}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Direccion del banco:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.bankAccountDetails?.bankAddress?.addressLine1}, {data.bankAccountDetails?.bankAddress?.country}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Nombre del beneficiario:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.beneficiaryDetails?.accountHolderName}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Direccion del beneficiario:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.beneficiaryDetails?.accountHolderAddress}, {data.bankAccountDetails?.beneficiaryDetails?.accountHolderCountry}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Activa:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.status === 'ACTIVE' ? 'Si' : 'No'}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Default:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data?.isDefault ? 'Si' : 'No'}
                </Text>
            </GridItem>


            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Creado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.created).toLocaleDateString() + ' ' + new Date(data.created).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Actualizado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.updated).toLocaleDateString() + ' ' + new Date(data.updated).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Evaluación:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    { data.evaluationDate ? new Date(data.evaluationDate).toLocaleDateString() + ' ' + new Date(data.updated).toLocaleTimeString(): ''} {data.evaluationDate ? '-' : ''} {statusesLabel[data.evaluation]}
                </Text>
            </GridItem>


        </Grid>
    </>);
}