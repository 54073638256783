export enum transationTypes {
    SETTLEMENT_NOTIFY_CLEAR = 'SETTLEMENT_NOTIFY_CLEAR',
    SETTLEMENT_NOTIFY = 'SETTLEMENT_NOTIFY',

    SETTLEMENTS_GET = 'SETTLEMENTS_GET',
    SETTLEMENTS_GET_SUCCESS = 'SETTLEMENTS_GET_SUCCESS',

    SETTLEMENT_GET = 'SETTLEMENT_GET',
    SETTLEMENT_GET_SUCCESS = 'SETTLEMENT_GET_SUCCESS',

    SETTLEMENT_ADD = 'SETTLEMENT_ADD',
    SETTLEMENT_ADD_SUCCESS = 'SETTLEMENT_ADD_SUCCESS',

    SETTLEMENT_DELETE_GET = 'SETTLEMENT_DELETE_GET',
    SETTLEMENT_DELETE_GET_SUCCESS = 'SETTLEMENT_DELETE_GET_SUCCESS',

    SETTLEMENT_UPDATE_GET = 'SETTLEMENT_UPDATE_GET',
    SETTLEMENT_UPDATE_GET_SUCCESS = 'SETTLEMENT_UPDATE_GET_SUCCESS',

    SETTLEMENT_SET_ERROR = 'SETTLEMENT_SET_ERROR',
};
