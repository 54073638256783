export enum transationTypes {
    BANK_ACCOUNT_NOTIFY_CLEAR = 'BANK_ACCOUNT_NOTIFY_CLEAR',
    BANK_ACCOUNT_NOTIFY = 'BANK_ACCOUNT_NOTIFY',

    BANK_ACCOUNTS_GET = 'BANK_ACCOUNTS_GET',
    BANK_ACCOUNTS_GET_SUCCESS = 'BANK_ACCOUNTS_GET_SUCCESS',

    BANK_ACCOUNT_GET = 'BANK_ACCOUNT_GET',
    BANK_ACCOUNT_GET_SUCCESS = 'BANK_ACCOUNT_GET_SUCCESS',

    BANK_ACCOUNT_ADD = 'BANK_ACCOUNT_ADD',
    BANK_ACCOUNT_ADD_SUCCESS = 'BANK_ACCOUNT_ADD_SUCCESS',

    BANK_ACCOUNT_DELETE_GET = 'BANK_ACCOUNT_DELETE_GET',
    BANK_ACCOUNT_DELETE_GET_SUCCESS = 'BANK_ACCOUNT_DELETE_GET_SUCCESS',

    BANK_ACCOUNT_UPDATE_GET = 'BANK_ACCOUNT_UPDATE_GET',
    BANK_ACCOUNT_UPDATE_GET_SUCCESS = 'BANK_ACCOUNT_UPDATE_GET_SUCCESS',

    BANK_ACCOUNT_PATCH = 'BANK_ACCOUNT_PATCH',
    BANK_ACCOUNT_PATCH_SUCCESS  = 'BANK_ACCOUNT_PATCH_SUCCESS ',

    BANK_ACCOUNT_SET_ERROR = 'BANK_ACCOUNT_SET_ERROR',
};
