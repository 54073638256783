import { Iuser, NotifyType, UserDocumentUrlDto, UserDocument } from './../types/user.d';
import { transationTypes } from './UserActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.USER_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.USER_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};

export const notifyClear = () => {
  return {
    type: transationTypes.USER_NOTIFY_CLEAR
  }
};


//GET USERS
export const usersGet = () => {
  return {
    type: transationTypes.USERS_GET
  }
};

export const usersSuccess = (data: Iuser[]) => {
  return {
    type: transationTypes.USERS_GET_SUCCESS,
    data
  }
};

export const usersError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};




//DELETE USER
export const userDelete = () => {
  return {
    type: transationTypes.USER_DELETE_GET
  }
};

export const userDeleteSuccess = (data: any) => {
  return {
    type: transationTypes.USER_DELETE_GET_SUCCESS,
    data
  }
};

export const userDeleteError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};


//GET USER
export const userGet = () => {
  return {
    type: transationTypes.USER_GET
  }
};

export const userGetSuccess = (data: Iuser) => {
  return {
    type: transationTypes.USER_GET_SUCCESS,
    data
  }
};

export const userGetError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};




//UPDATE USER
export const userUpdate = () => {
  return {
    type: transationTypes.USER_UPDATE_GET
  }
};

export const userUpdateSuccess = (data: any) => {
  return {
    type: transationTypes.USER_UPDATE_GET_SUCCESS,
    data
  }
};

export const userUpdateError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};


//ADD USER
export const userAdd = () => {
  return {
    type: transationTypes.USER_ADD
  }
};

export const userAddSuccess = (data: Iuser) => {
  return {
    type: transationTypes.USER_ADD_SUCCESS,
    data
  }
};

export const userAddError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};

//GET PERFORMANCE
export const userPerformanceGet = () => {
  return {
    type: transationTypes.USER_PERFORMANCE_GET
  }
};

export const userPerformanceSuccess = (data: Iuser) => {
  return {
    type: transationTypes.USER_PERFORMANCE_GET_SUCCESS,
    data
  }
};

export const userPerformanceError = (data: Error) => {
  return {
    type: transationTypes.USER_PERFORMANCE_GET_ERROR,
    data
  }
};

//GET USER DOCUMENTS
export const userDocumentsGet = () => {
  return {
    type: transationTypes.USER_DOCUMENTS_GET
  }
};

export const userDocumentsSuccess = (data: UserDocument[]) => {
  return {
    type: transationTypes.USER_DOCUMENTS_GET_SUCCESS,
    data
  }
};

export const userDocumentsError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};

//GET USER DOCUMENTS
export const userDocumentUrlGet = () => {
  return {
    type: transationTypes.USER_DOCUMENT_URL_GET
  }
};

export const userDocumentUrlSuccess = (data: Object) => {
  return {
    type: transationTypes.USER_DOCUMENT_URL_GET_SUCCESS,
    data
  }
};

export const userDocumentUrlError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};

//GET USER KYC
export const userKYCGet = () => {
  return {
    type: transationTypes.USER_KYC_GET
  }
};

export const userKYCSuccess = (data: Object) => {
  return {
    type: transationTypes.USER_KYC_GET_SUCCESS,
    data
  }
};

export const userKYCError = (data: Error) => {
  return {
    type: transationTypes.USER_SET_ERROR,
    data
  }
};

export const userDocumentsPost = () => {
  return {
    type: transationTypes.USER_DOCUMENTS_POST
  }
};

export const userDocumentsPostSuccess = (data: UserDocument[]) => {
  return {
    type: transationTypes.USER_DOCUMENTS_POST_SUCCESS,
    data
  }
};
