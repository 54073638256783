import List from "./components/List";
import { RedemptionProvider } from './context/providers/RedemptionProvider';

const Tables = () => {
  return (
    <RedemptionProvider>
      <List />
    </RedemptionProvider>
  );
};

export default Tables;
