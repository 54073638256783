import EditForm from "./components/EditForm";
import { RedemptionProvider } from './context/providers/RedemptionProvider';

const EditFormView = () => {
  return (
    <RedemptionProvider>
      <EditForm></EditForm>
    </RedemptionProvider>
  );
};

export default EditFormView;
