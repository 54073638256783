import React, { useEffect } from "react";
import { useUser } from '../context/providers/UsersProvider';
import { Link, useParams } from "react-router-dom";
import {
    GridItem,
    Grid,
    Text,
} from '@chakra-ui/react';
import * as userActions from './../context/actions/UserActions';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import fetchData from './../../../../helpers/fetchData';
import { MdPageview, MdOpenInNew } from "react-icons/md";

export default function UserKYC() {
    const { state: { request, documentsUrl, userKYC }, dispatch } = useUser();
    const { id } = useParams();

    useEffect(() => {
        if (!id || !dispatch) {
            return;
        }
        dispatch(userActions.userKYCGet());
        fetchData(`dwusers/${id}/kyc-status`)
            .then((data: any) => dispatch(userActions.userKYCSuccess(data)))
            .catch((error: any) => dispatch(userActions.userKYCError(new Error(error.statusText || error.message || error))));
    }, [dispatch, id]);


    const getDocumentUrl = async (documentId: string) => {

        dispatch(userActions.userDocumentUrlGet());
        fetchData(`dwusers/${id}/document/${documentId}/url`)
            .then((data: any) => dispatch(userActions.userDocumentUrlSuccess({ [documentId]: data })))
            .catch((error: any) => dispatch(userActions.userDocumentsError(new Error(error.statusText || error.message || error))));
    };

    return (<Grid
        templateColumns={{
            base: 'repeat(4, 1fr)',
            md: 'repeat(8, 1fr)',
            lg: 'repeat(12, 1fr)',
        }}
        gap={6}
        rowGap={12}
    >
        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>
                    <Text mr='2' pt='2' fontSize='sm'>
                        Estado:
                    </Text>
                </GridItem>
                <GridItem w='100%'>
                    <Text pt='2' as='b' fontSize='sm'>
                        {userKYC?.status?.name} <br />
                        {userKYC?.status?.description}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>
                    <Text mr='2' pt='2' fontSize='sm'>
                        Estado de KYC:
                    </Text>
                </GridItem>
                <GridItem w='100%'>
                    <Text pt='2' as='b' fontSize='sm'>
                        {userKYC?.kyc?.status?.name} <br />
                        {userKYC?.kyc?.status?.description}
                    </Text>
                </GridItem>
            </center>
        </GridItem>
        <GridItem hidden={!userKYC?.kyc?.statusComment?.length} w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>
                    <Text mr='2' pt='2' fontSize='sm'>
                        Comentario:
                    </Text>
                </GridItem>
                <GridItem w='100%'>
                    <Text pt='2' as='b' fontSize='sm'>
                        {userKYC?.kyc?.statusComment}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>
                    <Text mr='2' pt='2' fontSize='sm'>
                        Creado:
                    </Text>
                </GridItem>
                <GridItem w='100%'>
                    <Text pt='2' as='b' fontSize='sm'>
                        {userKYC?.kyc?.created ?
                            new Date(userKYC?.kyc?.created).toLocaleDateString() + ' ' + new Date(userKYC?.kyc?.created).toLocaleTimeString() : null}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <center>
                <GridItem w='100%'>
                    <Text mr='2' pt='2' fontSize='sm'>
                        Actualizado:
                    </Text>
                </GridItem>
                <GridItem w='100%'>
                    <Text pt='2' as='b' fontSize='sm'>
                        {userKYC?.kyc?.updated ?
                            new Date(userKYC?.kyc?.updated).toLocaleDateString() + ' ' + new Date(userKYC?.kyc?.updated).toLocaleTimeString() : null}
                    </Text>
                </GridItem>
            </center>
        </GridItem>

        <GridItem hidden={!userKYC?.kyc?.errors?.length} colSpan={12}>
            <TableContainer>
                <Table variant='simple'>
                    <TableCaption placement='top'>Errores</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Codigo</Th>
                            <Th>Mensaje</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userKYC?.kyc?.errors?.map((error, key) => {
                            return <Tr key={key}>
                                <Td>{error.code}</Td>
                                <Td>{error.description}</Td>
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </GridItem>

        <GridItem colSpan={12}>
            <TableContainer>
                <Table variant='simple'>
                    <TableCaption>Documentos</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Recibido</Th>
                            <Th>Estado</Th>
                            <Th>Tipo</Th>
                            <Th>Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userKYC?.documents?.map((document, key) => {
                            return <Tr key={key}>
                                <Td>{new Date(document.timestamp).toLocaleDateString() + ' ' + new Date(document.timestamp).toLocaleTimeString()}<br />{document.comment?.length ? `${document.comment}` : null}</Td>
                                <Td>{document.status?.name}<br />{document.status?.description?.length ? `${document.status?.description}` : null}</Td>
                                <Td>{document.type} {document.side?.length ? ` (${document.side})` : null}</Td>
                                <Td>
                                    <button
                                        hidden={!!(documentsUrl as any)?.[document.id]}
                                        disabled={request}
                                        onClick={() => getDocumentUrl(document.id)}
                                        className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                            : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                            } linear justify-center rounded-lg font-bold transition duration-200`}
                                    >
                                        <MdPageview className="h-6 w-6" />
                                    </button>


                                    <Link
                                        target="_blank"
                                        to={(documentsUrl as any)?.[document.id]?.url}
                                        hidden={!(documentsUrl as any)?.[document.id]}
                                        className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                            : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                            } linear justify-center rounded-lg font-bold transition duration-200`}
                                    >
                                        <MdOpenInNew className="h-6 w-6" />
                                    </Link>
                                </Td>
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </GridItem>

    </Grid>);
}