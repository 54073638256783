import { transationTypes } from './../actions/AccountActionTypes';

export enum statusesValues {
	pending = "PENDING",
	closed = "CLOSED",
	open = "OPEN",
	openNoNewTrades = "OPEN_NO_NEW_TRADES",
	frozen = "FROZEN",
};

export enum statusesLabel {
	pending = "Pendiente",
	closed = "Cerrada",
	open = "Abierta",
	openNoNewTrades = "Abierta Sin Operaciones",
	frozen = "Congelada",
};

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

type statusType = {
	name: string;
	description: string;
}

type tradingType = {
	name: string;
	description: string;
}

type accountType = {
	name: string;
	description: string;
}

type bodType = {
	moneyMarket: number;
	equityValue: number;
	cashBalance: number;
	cashAvailableForWithdrawal: number;
	cashAvailableForTrading: number;
	updatedWhen: string;
};

type BrokerUserType = {
	brokerID: string;
	createdAt: Date;
	email: string;
	fullName: string;
	id: string;
	partnerID: string;
	qID: string;
	status: string;
	updatedAt: Date;
};

interface Iaccount {
	id?: string;
	accountNo?: string;
	nickname?: string;
	status?: statusType;
	accountType?: accountType;
	accountManagementType?: string;
	tradingType?: tradingType;
	leverage?: number;
	commissionID?: string;
	userID?: string;
	restricted?: boolean;
	goodFaithViolations?: number;
	patternDayTrades?: number;
	freeTradeBalance?: number;
	gfvPdtExempt?: boolean;
	buyingPowerOverride?: boolean;
	bod?: bodType;
	cashBalance?: number;
	sweepInd?: boolean;
	interestFree?: boolean;
	createdWhen?: string;
	updatedWhen?: string;
	brokerUser?: BrokerUserType;
};

export interface IAccountUpdateDto {
	status?: string;
	statusComment?: string;
	nickname?: string;
	statusChangeReason? : string;
};

export interface IaccountGrid {
	data: Iaccount[];
	links: any;
	meta: any;
};

export type Action = {
	type: transationTypes;
	data?: any;
};

type PerformanceType = {
	realizedDayPL: Number;
	unrealizedDayPL: Number;
	cumRealizedPL: Number;
	date: string;
	equity: Number;
	cash: Number;
	deposits: Number;
	withdrawals: Number;
	fees: Number;
}
type AccountPerformanceType = {
	accountID: string;
	accountNo: string;
	startDate: string;
	endDate: string;
	lastUpdated: string;
	performance: PerformanceType[];
}

type TransactionType = {
	accountAmount: number;
	accountBalance: number;
	accountType: string;
	comment: string;
	dnb: boolean;
	finTranID: string;
	finTranTypeID: string;
	feeSec: number;
	feeTaf: number;
	feeBase: number;
	feeXtraShares: number;
	feeExchange: number;
	fillQty: number;
	fillPx: number;
	sendCommissionToInteliclear: boolean;
	systemAmount: number;
	tranAmount: number;
	tranSource: string;
	tranWhen: string;
	wlpAmount: number;
	wlpFinTranTypeID: string;
}

class AccountDocumentType {
	name: string;
	description: string;
}

class AccountDocumentStatus {
	name: string;
	description: string;
}

export class AccountDocument {
	documentID: string;
	comment: string;
	type: AccountDocumentType;
	receivedVia: string;
	receivedWhen: string;
	status: AccountDocumentStatus;
	side: string;
};

export class AccountDocumentUrlDto {
	url: string;
};

class StatementDocumentType {
	displayName: string;
	fileKey: string;
}

class TaxFormsDocumentType {
	displayName: string;
	fileKey: string;
}

class DepositStatusType {
	id: string;
	message: string;
	comment: string;
	updated: string;
}

export type DepositType = {
	id: string;
	type: string;
	amount: Number;
	timestamp: string;
	note: string;
	status: DepositStatusType;
}

class WithdrawalStatusType {
	id: string;
	message: string;
	comment: string;
	updated: string;
}

class WithdrawalFeeType {
	amount: string;
	created: string;
	currency: string;
	description: string;
	symbol: string;
	transactionCode: string;
	type: string;
	wlpFinTranTypeID: string;
}

export type WithdrawalType = {
	id: string;
	type: string;
	amount: Number;
	timestamp: string;
	fees: WithdrawalFeeType[];
	note: string;
	status: WithdrawalStatusType;
}

export type accountContextType = {
	accounts: IaccountGrid;
	statements: StatementDocumentType[];
	taxforms: TaxFormsDocumentType[];
	deposits: DepositType[];
	withdrawals: WithdrawalType[];
	documentsUrl: Object;
	performance: AccountPerformanceType;
	transactions: TransactionType[];
	account: Iaccount;
	request: boolean;
	updated: boolean;
	deleted: boolean;
	error: Error | null;
	errorMessage: string;
	notification: {
		title: string,
		type: NotifyType
	};
};