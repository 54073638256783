import React from "react";
import { useAccount } from '../context/providers/AccountsProvider';
import {
    Text,
    Grid,
    GridItem,
} from '@chakra-ui/react';

export default function AddForm(): React.ReactElement {
    const { state: { account } } = useAccount();

    if(!account) {
        return null;
    }

    return (<Grid
        templateColumns={{
            base: 'repeat(4, 1fr)',
            md: 'repeat(8, 1fr)',
            lg: 'repeat(12, 1fr)',
        }}
        gap={6}
        rowGap={12}
    >
        <GridItem w='100%' colSpan={2}>
            <GridItem w='100%'>

                <Text mr='2' pt='2' fontSize='sm'>
                    Número de Cuenta:
                </Text>
            </GridItem>
            <GridItem w='100%'>

                <Text pt='2' as='b' fontSize='sm'>
                    {account.accountNo}
                </Text>
            </GridItem>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Apodo:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.nickname}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Estado:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.status?.description || account.status?.name}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Tipo:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.accountType?.name}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Tipo de Administración:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.accountManagementType}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Tipo de Trading:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.tradingType?.name}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Apalancamiento:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.leverage}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Restringida:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.restricted ? 'Si' : 'No'}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Violaciones:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.goodFaithViolations}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Patrón de operaciones diarias:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.patternDayTrades}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Balance libre para comerciar:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.freeTradeBalance}
            </Text>
        </GridItem>

        <GridItem hidden={true} w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                gfvPdtExempt(Description comming soon):
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.gfvPdtExempt}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Anulación del poder adquisitivo:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.buyingPowerOverride ? 'Si' : 'No'}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Cuenta de barrido o mercado monetario:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.sweepInd ? 'Si' : 'No'}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Sin intereses:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {account.interestFree ? 'Si' : 'No'}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Actualizada:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {new Date(account.updatedWhen).toLocaleDateString() + ' ' + new Date(account.updatedWhen).toLocaleTimeString()}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Balance Actualizado:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {new Date(account.bod?.updatedWhen).toLocaleDateString() + ' ' + new Date(account.bod?.updatedWhen).toLocaleTimeString()}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Dinero disponible para trading:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {`$${account.bod?.cashAvailableForTrading?.toLocaleString()}`}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Dinero disponible para retirar:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {`$${account.bod?.cashAvailableForWithdrawal?.toLocaleString()}`}
            </Text>
        </GridItem>

        <GridItem w='100%' colSpan={2}>
            <Text mr='2' pt='2' fontSize='sm'>
                Balance de dinero:
            </Text>
            <Text pt='2' as='b' fontSize='sm'>
                {`$${account.bod?.cashBalance?.toLocaleString()}`}
            </Text>
        </GridItem>

    </Grid>);
}