export enum statusesValues {
  STARTED = "Started",
  PENDING = "Pending",
  SUCCESSFUL = "Successful",
  FAILED = "Failed",
  OTHER = "Other",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  ON_HOLD = "On_Hold",
  RETURNED = "Returned",
};

export enum statusesLabel {
  STARTED = "Iniciado",
  PENDING = "Pendiente",
  SUCCESSFUL = "Exitoso",
  FAILED = "Fallido",
  OTHER = "Otro",
  APPROVED = "Aprobado",
  REJECTED = "Rechazado",
  ON_HOLD = "En Espera",
  RETURNED = "Devuelto",
};

export enum typesValues {
  ACH = "ACH",
  WIRE = "WIRE",
  BULK_FUNDING = "BULK_FUNDING",
};

export enum typesLabel {
  ACH = "ACH",
  WIRE = "WIRE",
  BULK_FUNDING = "Bulk Funding",
};
