import React, { createContext, ReactNode } from 'react';
import { FeeContextType } from './../types/fee.d';
import { initialState, reducer } from '../reducers/FeeReducer';
import { useSessionReducer } from '../../../../../hooks/useSessionReducer';
import { useToast } from '@chakra-ui/react';

export const feeContext = createContext<{
  state: FeeContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useFee = () => {
  const context = React.useContext(feeContext)
  if (context === undefined) {
      throw new Error('useFee must be used within a FeeProvider')
  }
  return context
};

export const FeeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'FeeProvider');
  const toast = useToast();
  const { error } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });


  }, [error, toast]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <feeContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </feeContext.Provider>
  );
};
