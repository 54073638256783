import { bankAccountContextType, Action } from '../types/bankAccount';
import { transationTypes } from '../actions/BankAccountActionTypes';

//the initial state of the user
export const initialState: bankAccountContextType = {
  request: false,
  notification: null,
  error: null,
  bankAccounts: { meta:{}, links: {}, data: [] },
  bankAccount: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: bankAccountContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.BANK_ACCOUNTS_GET:
      return {
        ...state,
        request: true,
        error: null,
        bankAccount: null
      };
    case transationTypes.BANK_ACCOUNTS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        bankAccounts: action.data,
        bankAccount: null
      };


    //GET
    case transationTypes.BANK_ACCOUNT_GET:
      return {
        ...state,
        request: true,
        bankAccount: null
      };
    case transationTypes.BANK_ACCOUNT_GET_SUCCESS:
      return {
        ...state,
        request: false,
        bankAccount: action.data,
      };


    //ADD
    case transationTypes.BANK_ACCOUNT_ADD:
      return {
        ...state,
        request: true,
        bankAccount: null
      };

    case transationTypes.BANK_ACCOUNT_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        bankAccount: action.data,
      };




    //NOTIFY
    case transationTypes.BANK_ACCOUNT_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.BANK_ACCOUNT_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.BANK_ACCOUNT_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.BANK_ACCOUNT_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.BANK_ACCOUNT_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.BANK_ACCOUNT_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.BANK_ACCOUNT_UPDATE_GET_SUCCESS:
      return {
        ...state,
        bankAccount: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};