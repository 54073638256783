import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useCircuitTransfer } from '../context/providers/CircuitTransfersProvider';
import CircuitTransferForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as circuitTransferActions from './../context/actions/CircuitTransferActions';
import { IcircuitTransfer } from "../context/types/circuitTransfer";
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { dispatch, state: { request, circuitTransfer } } = useCircuitTransfer();

    React.useEffect(() => {
        if (id && !request && !circuitTransfer) {
            dispatch(circuitTransferActions.circuitTransferGet());
            fetchData(`circuit-transfer/${id}`)
                .then((data: any) => dispatch(circuitTransferActions.circuitTransferGetSuccess(data)))
                .catch((error: any) => dispatch(circuitTransferActions.circuitTransferGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, circuitTransfer]);

    const onSubmit = async (circuitTransferData: IcircuitTransfer) => {
        try {
            dispatch(circuitTransferActions.circuitTransferUpdate());
            dispatch(circuitTransferActions.circuitTransferUpdateSuccess(await fetchData(`circuit-transfer/${circuitTransfer.id}`, 'PATCH', { ...circuitTransfer, ...circuitTransferData })));
            dispatch(circuitTransferActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
            setTimeout(()=> navigate('/admin/circuit-transfer'), 500);
        } catch (error: any) {
            dispatch(circuitTransferActions.circuitTransferUpdateError(new Error(error.statusText || error.message || error)))
        }
    };

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Editar Transacción
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !circuitTransfer)} />
            {(!circuitTransfer) ? null : <CircuitTransferForm data={circuitTransfer} onSubmit={onSubmit} />}
        </Card>
    </>);
}