import { Iredemption, NotifyType } from './../types/redemption.d';
import { transationTypes } from './RedemptionActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.WITHDRAWAL_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.WITHDRAWAL_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET WITHDRAWALS
export const redemptionsGet = () => {
  return {
    type: transationTypes.WITHDRAWALS_GET
  }
};

export const redemptionsSuccess = (data: Iredemption[]) => {
  return {
    type: transationTypes.WITHDRAWALS_GET_SUCCESS,
    data
  }
};

export const redemptionsError = (data: Error) => {
  return {
    type: transationTypes.WITHDRAWAL_SET_ERROR,
    data
  }
};


//GET WITHDRAWAL
export const redemptionGet = () => {
  return {
    type: transationTypes.WITHDRAWAL_GET
  }
};

export const redemptionGetSuccess = (data: Iredemption) => {
  return {
    type: transationTypes.WITHDRAWAL_GET_SUCCESS,
    data
  }
};

export const redemptionGetError = (data: Error) => {
  return {
    type: transationTypes.WITHDRAWAL_SET_ERROR,
    data
  }
};


//PATH WITHDRAWAL
export const redemptionPatch = () => {
  return {
    type: transationTypes.WITHDRAWAL_PATCH
  }
};

export const redemptionPatchSuccess = (data: any) => {
  return {
    type: transationTypes.WITHDRAWAL_PATCH_SUCCESS,
    data
  }
};

export const redemptionPatchError = (data: Error) => {
  return {
    type: transationTypes.WITHDRAWAL_SET_ERROR,
    data
  }
};


