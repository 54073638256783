export enum transationTypes {
    PARTNER_NOTIFY_CLEAR = 'PARTNER_NOTIFY_CLEAR',
    PARTNER_NOTIFY = 'PARTNER_NOTIFY',

    PARTNERS_GET = 'PARTNERS_GET',
    PARTNERS_GET_SUCCESS = 'PARTNERS_GET_SUCCESS',

    PARTNER_GET = 'PARTNER_GET',
    PARTNER_GET_SUCCESS = 'PARTNER_GET_SUCCESS',

    PARTNER_ADD = 'PARTNER_ADD',
    PARTNER_ADD_SUCCESS = 'PARTNER_ADD_SUCCESS',

    PARTNER_DELETE_GET = 'PARTNER_DELETE_GET',
    PARTNER_DELETE_GET_SUCCESS = 'PARTNER_DELETE_GET_SUCCESS',

    PARTNER_UPDATE_GET = 'PARTNER_UPDATE_GET',
    PARTNER_UPDATE_GET_SUCCESS = 'PARTNER_UPDATE_GET_SUCCESS',

    PARTNER_SET_ERROR = 'PARTNER_SET_ERROR',
};
