import React, { createContext, ReactNode } from 'react';
import { settlementContextType } from '../types/settlement';
import { initialState, reducer } from '../reducers/SettlementsReducer';
import { useSessionReducer } from '../../../../../hooks/useSessionReducer';
import { useToast } from '@chakra-ui/react';

export const settlementsContext = createContext<{
  state: settlementContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useSettlement = () => {
  const context = React.useContext(settlementsContext)
  if (context === undefined) {
      throw new Error('useSettlement must be used within a SettlementProvider')
  }
  return context
};

export const SettlementsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'SettlementsProvider');
  const toast = useToast();
  const { error } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });


  }, [error, toast]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <settlementsContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </settlementsContext.Provider>
  );
};
