import { transationTypes } from './../actions/PartnerActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface Ipartner {
	id?: string;
	name: string;
	settlementProfileID: string;
	AUM: string;
	created_at: string;
	updated_at: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export interface IPartnerGrid {
	data: Ipartner[];
	meta: any;
	links:any;
}

export type partnerContextType = {
    partners: IPartnerGrid;
	partner: Ipartner;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};