import List from "./components/List";
import { BankAccountsProvider } from './context/providers/BankAccountsProvider';

const BankAccountTable = ({userId}:{userId?:string}) => {
  return (
    <BankAccountsProvider>
      <List userId={userId}/>
    </BankAccountsProvider>
  );
};

export default BankAccountTable;
