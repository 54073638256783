import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { MdAccountBalanceWallet, MdApproval, MdOutlineSaveAlt, MdPageview, MdPersonOutline } from "react-icons/md";
import { useRedemption } from '../context/providers/RedemptionProvider';
import { useNavigate } from "react-router-dom";
import * as redemptionActions from '../context/actions/RedemptionActions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Input, Text, Button } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { SortingState } from "@tanstack/react-table";
import SentWithdrawalModal from './SentWithdrawalModal';
import { statusesLabel, statusesValues, typesLabel, typesValues } from './enums';
import { authContext } from "views/auth/context/Provider";
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';


export default function List() {

  const navigate = useNavigate();
  const { state: { redemptions, request }, dispatch } = useRedemption();
    const { state: { data, auth } } = React.useContext(authContext);
    const [toSearchDate, setToSearchDate] = useState<string>();
  const [fromSearchDate, setFromSearchDate] = useState<string>();
  const [searchState, setSearchState] = useState<string>();
  const [searchType, setSearchType] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [comfirmIsOpen, setComfirmIsOpen] = useState<boolean>(false);
  const [comfirmData, setComfirmData] = useState<any>();
  

  const openSentWithdrawalModal = (info: any) => {
    setComfirmData(info);
    setComfirmIsOpen(true);
  };

  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
    { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status', export: true },
    { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'ID Cuenta', name: 'brokerAccount.id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'No Cuenta', name: 'brokerAccount.accountNo', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'ID Usuario', name: 'brokerAccount.brokerUser.id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Correo', name: 'brokerAccount.brokerUser.email', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Nombre', name: 'brokerAccount.brokerUser.fullName', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'created', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Actualización', name: 'updated', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', getActions: (info) => {
        const bankData = info.row.original;

        return [
          {
            title: 'Ver Usuario',
            onClick: (info: any) => navigate(`/admin/users/view/${info.row.original.brokerAccount?.brokerUser?.id}`),
            icon: <MdPersonOutline color="#422afb" className="h-8 w-8" />,
            isLoading: request
          },
          {
            title: 'Ver Cuenta',
            onClick: (info: any) => navigate(`/admin/accounts/view/${info.row.original.brokerAccount?.id}`),
            icon: <MdAccountBalanceWallet color="#422afb" className="h-8 w-8" />,
            isLoading: request
          },
          {
            title: 'Ver Detalles',
            isHidden: !bankData?.id,
            onClick: (info: any) => navigate(`/admin/withdrawal/${info?.row?.original?.id}`),
            icon: <MdPageview color="#422afb" className="h-8 w-8" />,
            isLoading: request
          },
          {
            title: 'Evaluar',
            isHidden: bankData?.id || data.rol !== 'admin',
            onClick: (info: any) => openSentWithdrawalModal(bankData),
            icon: <MdApproval color="green" className="h-8 w-8 green" />,
            isLoading: request
          },
        ];
      }
    }
  ];

  const totalItems = useMemo(() => { return redemptions?.meta?.totalItems }, [redemptions]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`funding/redemptions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(redemptionActions.redemptionsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}broker/funding/redemptions/search?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchState?.length ? `filter.status=$eq:${searchState}&` : '';
    url += searchType?.length ? `filter.type=$eq:${searchType}&` : '';

    switch (true) {
      case !!fromSearchDate?.length && !!toSearchDate?.length:
        url += `filter.created=$btw:${fromSearchDate},${toSearchDate}T23:59:59`;
        break;
      case !!fromSearchDate?.length:
        url += `filter.created=$gte:${fromSearchDate}`;
        break;
      case !!toSearchDate?.length:
        url += `filter.created=$lte:${toSearchDate}T23:59:59`;
        break;
    }

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const onSearch = ({ searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType }: { searchType: string, searchText: string, searchState: string, sorting: SortingState, fromSearchDate: string, toSearchDate: string }) => {
    if (!searchText?.length && !searchState?.length && !fromSearchDate?.length && !toSearchDate?.length && !sorting?.length && !searchType?.length) {
      return
    }

    let url = searchUrl;

    fetchData(`funding/redemptions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(redemptionActions.redemptionsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    let url = 'funding/redemptions/search?';

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'funding/redemptions/search?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    if (!onSearch || (!searchText?.length && !searchState?.length && !fromSearchDate?.length && !toSearchDate?.length && !searchType?.length)) {
      fetchData(url)
        .then((data: any) => dispatch(redemptionActions.redemptionsSuccess(data)))
        .catch((error: any) => dispatch(redemptionActions.redemptionsError(new Error(error.statusText || error.message || error))));
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType, comfirmIsOpen]);

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${redemptions?.meta?.totalItems}`;

    fetchData(`funding/redemptions?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'retiros');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Desde</Text><Input defaultValue={fromSearchDate} onChange={(e) => setFromSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Desde" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Hasta</Text><Input defaultValue={toSearchDate} onChange={(e) => setToSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Hasta" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchType} onChange={(e) => setSearchType(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por tipo'>
        <option value={typesValues.ACH}>{typesLabel.ACH}</option>
        <option value={typesValues.WIRE}>{typesLabel.WIRE}</option>
        <option value={typesValues.BULK_FUNDING}>{typesLabel.BULK_FUNDING}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Select value={searchState} onChange={(e) => setSearchState(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por estado'>
        <option value={statusesValues.STARTED}>{statusesLabel.STARTED}</option>
        <option value={statusesValues.ON_HOLD}>{statusesLabel.ON_HOLD}</option>
        <option value={statusesValues.PENDING}>{statusesLabel.PENDING}</option>
        <option value={statusesValues.APPROVED}>{statusesLabel.APPROVED}</option>
        <option value={statusesValues.FAILED}>{statusesLabel.FAILED}</option>
        <option value={statusesValues.OTHER}>{statusesLabel.OTHER}</option>
        <option value={statusesValues.REJECTED}>{statusesLabel.REJECTED}</option>
        <option value={statusesValues.RETURNED}>{statusesLabel.RETURNED}</option>
        <option value={statusesValues.SUCCESSFUL}>{statusesLabel.SUCCESSFUL}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
  </Grid>, [redemptions, searchText, fromSearchDate, toSearchDate, searchUrl]);

  const BoxContent = useMemo(() => <Grid display={'flex'} gap={6}>
    {redemptions?.meta?.brief ?
      <>
        <GridItem><Text>Total: <b>{`$${redemptions.meta.brief.amount?.toLocaleString()}`}</b></Text></GridItem>
      </>
      : null
    }

  </Grid>, [redemptions]);

  return (<>
    <Table
      request={request}
      data={redemptions}
      columns={tableColumnsData}
      accordionContent={AccordionContent}
      onPaginate={onPaginate}
      onSort={setSorting}
      boxContent={BoxContent}
    />
    <SentWithdrawalModal
      data={comfirmData}
      setComfirmIsOpen={setComfirmIsOpen}
      comfirmIsOpen={comfirmIsOpen}

    />
  </>);
}
