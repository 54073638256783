import { Itransaction, NotifyType, ITransactionGrid } from './../types/transaction.d';
import { transationTypes } from './ActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.PARTNER_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.PARTNER_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET PARTNERS
export const transactionsGet = () => {
  return {
    type: transationTypes.PARTNERS_GET
  }
};

export const transactionsSuccess = (data: ITransactionGrid) => {
  return {
    type: transationTypes.PARTNERS_GET_SUCCESS,
    data
  }
};

export const transactionsError = (data: Error) => {
  return {
    type: transationTypes.PARTNER_SET_ERROR,
    data
  }
};


//GET PARTNER
export const transactionGet = () => {
  return {
    type: transationTypes.PARTNER_GET
  }
};

export const transactionGetSuccess = (data: Itransaction) => {
  return {
    type: transationTypes.PARTNER_GET_SUCCESS,
    data
  }
};

export const transactionGetError = (data: Error) => {
  return {
    type: transationTypes.PARTNER_SET_ERROR,
    data
  }
};


