import React, { useEffect } from "react";
import { useAccount } from '../context/providers/AccountsProvider';
import { Link } from "react-router-dom";
import {
    Container,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Button,
    Grid,
    GridItem,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    ExpandedIndex,
} from '@chakra-ui/react';
import Calendar from 'components/calendar/MiniCalendar';
import { Iaccount } from "../context/types/account";
import * as accountActions from '../context/actions/AccountActions';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import fetchData from '../../../../helpers/fetchData';
import { MdPageview, MdOpenInNew, MdFilterListOff, MdFilterList } from "react-icons/md";

export default function AccountTaxForms() {
    const { state: { account, taxforms, request, documentsUrl }, dispatch } = useAccount();
    const [date, setDate] = React.useState<[Date, Date]>([null, null]);
    const [index, setIndex] = React.useState<number>(null);
   
    const getDocumentUrl = async (documentId: string) => {

        /*dispatch(accountActions.notifyError('Completar los datos requeridos'))
        return;*/

        dispatch(accountActions.accountDocumentUrlGet());
        fetchData(`accounts/${account.id}/document/${documentId}/url`)
            .then((data: any) => dispatch(accountActions.accountDocumentUrlSuccess({ [documentId]: data })))
            .catch((error: any) => dispatch(accountActions.accountDocumentsError(new Error(error.statusText || error.message || error))));
    }

    const submit = async () => {

        try {
            const firstRangeDate: Date = date[0];
            const secondRangeDate: Date = date[1];

            if (!account?.id || !firstRangeDate || !secondRangeDate) {
                return;
            }

            const from = firstRangeDate.toLocaleDateString("default", { year: "numeric" }) + '-' + firstRangeDate.toLocaleDateString("default", { month: "2-digit" }) + '-' + firstRangeDate.toLocaleDateString("default", { day: "2-digit" }) + 'T00:00:00';
            const to = secondRangeDate.toLocaleDateString("default", { year: "numeric" }) + '-' + secondRangeDate.toLocaleDateString("default", { month: "2-digit" }) + '-' + secondRangeDate.toLocaleDateString("default", { day: "2-digit" }) + 'T23:59:59';

            dispatch(accountActions.accountTaxFormsGet());
            fetchData(`accounts/${account.id}/taxforms?from=${from}&to=${to}`)
                .then((data: any) => dispatch(accountActions.accountTaxFormsSuccess(data)))
                .catch((error: any) => dispatch(accountActions.accountDocumentsError(new Error(error.statusText || error.message || error))));

        } catch (error: any) {
            dispatch(accountActions.accountDocumentsError(new Error(error.statusText || error.message || error)))
        }


    }

    React.useEffect(() => {

        if (date[0] === date[1]) {
            setIndex(0)
            return;
        }

        submit();
    }, [date])

    return (<Center>
        <Grid
            templateColumns={{
                base: 'repeat(1, 1fr)'
            }}
            gap={1}
            content="center"
        >

            <GridItem w='100%'>
                <Accordion onChange={(index: ExpandedIndex) => { setIndex(Number(index)) }} index={index} allowToggle className="my-5">
                    <AccordionItem>
                        {({ isExpanded = false }) => (
                            <>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                        </Box>
                                        {isExpanded ? (
                                            <MdFilterListOff fontSize='24px' />
                                        ) : (
                                            <MdFilterList fontSize='24px' />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>

                                    <Grid
                                        templateColumns={{
                                            base: 'repeat(1, 1fr)'
                                        }}
                                        gap={6}
                                    >
                                        <GridItem w='100%'>
                                            <FormControl className="mb-5 items-center flex flex-col">
                                                <Calendar
                                                    onChange={(a: any, b: any) => {
                                                        if (!a[1]) {
                                                            return;
                                                        }
                                                        const firstRangeDate: Date = a[0];
                                                        const secondRangeDate: Date = a[1];
                                                        setDate([firstRangeDate, secondRangeDate]);
                                                    }}
                                                    value={date}
                                                    selectRange={true}
                                                    maxDate={new Date()}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </Grid>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
            </GridItem>
            <GridItem>
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>{(taxforms?.length || !date?.[1]) ? 'Formularios de Impuestos' : `Sin Formularios de Impuestos ${date?.[0]?.toLocaleDateString("default", { year: "numeric" }) + '-' + date?.[0]?.toLocaleDateString("default", { month: "2-digit" }) + '-' + date?.[0]?.toLocaleDateString("default", { day: "2-digit" })}-${date?.[1]?.toLocaleDateString("default", { year: "numeric" }) + '-' + date?.[1]?.toLocaleDateString("default", { month: "2-digit" }) + '-' + date?.[1]?.toLocaleDateString("default", { day: "2-digit" })}`}</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Nombre</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {taxforms?.map((document, key) => {
                                return <Tr key={key}>
                                    <Td>{document.displayName}</Td>
                                    <Td>
                                        <button
                                            hidden={!!(documentsUrl as any)?.[document.fileKey]}
                                            disabled={request}
                                            onClick={() => getDocumentUrl(document.fileKey)}
                                            className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                                ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                                : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                                } linear justify-center rounded-lg font-bold transition duration-200`}
                                        >
                                            <MdPageview className="h-6 w-6" />
                                        </button>


                                        <Link
                                            target="_blank"
                                            to={(documentsUrl as any)?.[document.fileKey]?.url}
                                            hidden={!(documentsUrl as any)?.[document.fileKey]}
                                            className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                                                ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                                                : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                                } linear justify-center rounded-lg font-bold transition duration-200`}
                                        >
                                            <MdOpenInNew className="h-6 w-6" />
                                        </Link>
                                    </Td>
                                </Tr>
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    </Center>
    );
}
