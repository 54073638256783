import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useSettlement } from '../context/providers/SettlementsProvider';
import SettlementForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as settlementActions from './../context/actions/SettlementActions';
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, settlement, error } } = useSettlement();

    React.useEffect(() => {
        if (id && !request && settlement?.id !== id) {
            dispatch(settlementActions.settlementGet());
            
            const url = id.indexOf('sett') >= 0 ? `settlement/${id}` : `funding/settlements/instant-funding/${id}`;

            fetchData(url)
                .then((data: any) => dispatch(settlementActions.settlementGetSuccess(data)))
                .catch((error: any) => dispatch(settlementActions.settlementGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, settlement, error]);

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Detalle Liquidacion
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !settlement) } />
            {(!settlement) ? null : <SettlementForm data={settlement} />}
        </Card>
    </>);
}