import { transationTypes } from './../actions/UserActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

type statusType = {
	name: string;
	description: string;
}

type tradingType = {
	name: string;
	description: string;
}

type bodType = {
	moneyMarket: number;
	equityValue: number;
	cashAvailableForWithdrawal: number;
	cashAvailableForTrading: number;
	updatedWhen: string;
}

type genderType = {
	name: string;
	description: string;
}

type parentIB = {
	name: string;
	id: string;
}

type idType = {
	name: string;
	description: string;
}
type userType = {
	name: string;
	description: string;
}
type employmentStatus = {
	name: string;
	description: string;
}
type maritalStatus = {
	name: string;
	description: string;
}

export type Iuser = {
	qID?: string;
	brokerID: string;
	partnerID: string;
	id: string;
	fullName: string;
	email: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	ackSignedWhen: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	countryID: string;
	displayName: string;
	dob: string;
	firstName: string;
	gender: genderType;
	languageID: string;
	lastName: string;
	parentIB: parentIB;
	phone: string;
	referralCode: string;
	stateProvince: string;
	wlpID: string;
	zipPostalCode: string;
	idNo: string;
	idType: idType;
	userType: userType;
	usCitizen: boolean;
	renouncedUSCitizenship: boolean;
	updatedWhen: string;
	brandAmbassador: boolean;
	employerBusiness: string;
	employmentStatus: employmentStatus;
	citizenship: string;
	createdWhen: string;
	approvedWhen: string;
	approvedBy: string;
	marginDefault: number;
	maritalStatus: maritalStatus;
	ackCustomerAgreement: boolean;
	ackFindersFee: boolean;
	ackForeignFindersFee: boolean;
	ackJointCustomerAgreement: boolean;
	ackJointFindersFee: boolean;
	ackJointForeignFindersFee: boolean;
	ackJointMarketData: boolean;
	ackMarketData: boolean;
	ackSignedBy: string;
	ackExtendedHoursAgreement: boolean;
	dependents: number;
	termsOfUse: boolean;
	ackMarginAgreementWhen: string;
	badPasswordCount: number;
	director: boolean;
	employerCompany: string;
	employerIsBroker: boolean;
	employmentPosition: string;
	employmentYears: number;
	jointEmployerIsBroker: boolean;
	fundingSources: any;
	politicallyExposed: boolean;
	userNoteQty: number;
	metadata: any;
	taxTreatyWithUS: boolean;
	updatedBy: string;
	avatarURL: string;
	ackDisclosureRule14b: boolean;
	ackJointDisclosureRule14b: false;
};

type userDataDocumentType = {
	type: string;
	data: any;
	description: string;
};


export type IuserData = {
	qID: string;
	id: string;
	userType: userType;
	status: statusType;
	parentIB: parentIB;
	wlpID: string;
	referralCode: string;
	createdWhen: string;
	updatedWhen: string;
	userNoteQty: number;
	updatedBy: string
	documents: userDataDocumentType[];
	metadata: any;
}

export type Action = {
	type: transationTypes;
	data?: any;
};

type PerformanceType = {
	realizedDayPL: Number;
	unrealizedDayPL: Number;
	cumRealizedPL: Number;
	date: string;
	equity: Number;
	cash: Number;
	deposits: Number;
	withdrawals: Number;
	fees: Number;
}
type UserPerformanceType = {
	userID: string;
	userNo: string;
	startDate: string;
	endDate: string;
	lastUpdated: string;
	performance: PerformanceType[];
}

class UserDocumentType {
	name: string;
	description: string;
}

class UserDocumentStatus {
	name: string;
	description: string;
}

export class UserDocument {
	documentID: string;
	comment: string;
	type: UserDocumentType;
	receivedVia: string;
	receivedWhen: string;
	status: UserDocumentStatus;
	side: string;
};

export class UserDocumentUrlDto {
	url: string;
};

class IdentityDto {
	number: string;
	dob: string;
}

class UserKYCStatusDto {
	name: string;
	description: string;
}

class UserKYCPartnerDto {
	name: string;
	id: string;
}

class UserKYCUserAceptedDataDto {
	timestamp: string;
	acceptedBy: string;
}

class UserKYCUserApprovedDataDto {
	timestamp: string;
	approvedBy: string;
}

class UserKYCUserErrorDto {
	code: string;
	description: string;
}

class UserKYCDataDto {
	status: UserKYCStatusDto;
	errors: UserKYCUserErrorDto[];
	verificationType: string;
	approved: UserKYCUserApprovedDataDto;
	accepted: UserKYCUserAceptedDataDto;
	created: string;
	updated: string;
	statusComment: string;
};

export class UserKYCDocumentDto {
	id: string;
	comment: string;
	type: string;
	ipAddress: string;
	timestamp: string;
	status: UserDocumentStatus;
	side: string;
	errors: [];
};

class UserKYCAccountDto {
	id: string;
	accountNo: string;
	nickname: string;
	accountStatus: UserKYCStatusDto;
	accountType: UserKYCStatusDto;
	accountMgmtType: UserKYCStatusDto;
	tradingType: UserKYCStatusDto;
	leverage: number;
}


export class UserKYCDto {
	userID: string;
	firstname: string;
	lastname: string;
	identity: IdentityDto;
	accounts: UserKYCAccountDto[]
	kyc: UserKYCDataDto;
	status: UserKYCStatusDto;
	partnerID: UserKYCPartnerDto;
	documents: UserKYCDocumentDto[]
};

export interface IUserGrid {
	data: Iuser[];
	links: any;
	meta: any;
};

export type userContextType = {
	users: IUserGrid;
	documents: UserDocument[];
	performance: UserPerformanceType;
	user: IuserData;
	userKYC: UserKYCDto;
	request: boolean;
	updated: boolean;
	deleted: boolean;
	newDocument: any;
	error: Error | null;
	documentsUrl: Object;
	notification: {
		title: string,
		type: NotifyType
	};
};