import logoQuiena from "assets/img/quiena-logotipo-large.svg";

const FreeCard = () => {
  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px]">
      <div
          style={{
            backgroundImage: `url(${logoQuiena})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          className="h-[96px] w-[288px] aspect-video mx-auto rounded-lg"
        />
    </div>
  );
};

export default FreeCard;
