import { userContextType, Action } from '../types/user';
import { transationTypes } from './../actions/UserActionTypes';

//the initial state of the user
export const initialState: userContextType = {
  request: false,
  updated: false,
  deleted: false,
  performance: null,
  notification: null,
  error: null,
  users: { meta: {}, links: {}, data: [] },
  documents: [],
  user: null,
  documentsUrl: {},
  userKYC: null,
  newDocument: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: userContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.USERS_GET:
      return {
        ...state,
        updated: false,
        deleted: false,
        request: true,
        user: null,
        error: null
      };


    //GET
    case transationTypes.USERS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        users: action.data,
      };
    case transationTypes.USER_GET:
      return {
        ...state,
        request: true,
        user: null,
        documents: [],
        userKYC: null
      };
    case transationTypes.USER_GET_SUCCESS:
      return {
        ...state,
        request: false,
        user: action.data,
      };


    //ADD
    case transationTypes.USER_ADD:
      return {
        ...state,
        request: true,
        user: null
      };

    case transationTypes.USER_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        user: action.data,
      };




    //NOTIFY
    case transationTypes.USER_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.USER_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.USER_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.USER_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.USER_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.USER_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.USER_UPDATE_GET_SUCCESS:
      return {
        ...state,
        user: null,
        request: false,
        updated: true
      };


    //PERFORMANCE
    case transationTypes.USER_PERFORMANCE_GET_SUCCESS:
      return {
        ...state,
        request: false,
        performance: action.data,
      };

    case transationTypes.USER_PERFORMANCE_GET:
      return {
        ...state,
        request: true,
        performance: null
      };
    case transationTypes.USER_PERFORMANCE_GET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DOCUMENTS
    case transationTypes.USER_DOCUMENTS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        documents: action.data,
        documentsUrl: {}
      };

    case transationTypes.USER_DOCUMENTS_GET:
      return {
        ...state,
        request: true,
        documents: []
      };

    case transationTypes.USER_DOCUMENTS_POST_SUCCESS:
      return {
        ...state,
        request: false,
        newDocument: action.data
      };

    case transationTypes.USER_DOCUMENTS_POST:
      return {
        ...state,
        request: true,
        newDocument: null
      };

    //DOCUMENT URL
    case transationTypes.USER_DOCUMENT_URL_GET_SUCCESS:
      return {
        ...state,
        request: false,
        documentsUrl: {
          ...state.documentsUrl,
          ...action.data
        },
      };

    case transationTypes.USER_DOCUMENT_URL_GET:
      return {
        ...state,
        request: true
      };

    //USER KYC
    case transationTypes.USER_KYC_GET_SUCCESS:
      return {
        ...state,
        request: false,
        userKYC: action.data
      };

    case transationTypes.USER_KYC_GET:
      return {
        ...state,
        userKYC: null,
        request: true
      };


    default:
      return state;
  }
};