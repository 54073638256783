import { userContextType, Action } from './../../context/types/user';

//the initial state of the user
export const initialState: userContextType = {
  users: [],
  request: false
};

//the action we are going to take when we login that is set the user
export const actionTypes = {
  SET_USERS: 'SET_USERS',
  CLEAR_USERS: 'CLEAR_USERS'
};


//the reducer function note the parameter type annotations
export const reducer = (state: userContextType, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionTypes.CLEAR_USERS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};