import { IbankAccount, NotifyType, IBankAccountGrid } from '../types/bankAccount.d';
import { transationTypes } from './BankAccountActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.BANK_ACCOUNT_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.BANK_ACCOUNT_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET BANK_ACCOUNTS
export const bankAccountsGet = () => {
  return {
    type: transationTypes.BANK_ACCOUNTS_GET
  }
};

export const bankAccountsSuccess = (data: IBankAccountGrid) => {
  return {
    type: transationTypes.BANK_ACCOUNTS_GET_SUCCESS,
    data
  }
};

export const bankAccountsError = (data: Error) => {
  return {
    type: transationTypes.BANK_ACCOUNT_SET_ERROR,
    data
  }
};


//GET BANK_ACCOUNT
export const bankAccountGet = () => {
  return {
    type: transationTypes.BANK_ACCOUNT_GET
  }
};

export const bankAccountGetSuccess = (data: IbankAccount) => {
  return {
    type: transationTypes.BANK_ACCOUNT_GET_SUCCESS,
    data
  }
};

export const bankAccountGetError = (data: Error) => {
  return {
    type: transationTypes.BANK_ACCOUNT_SET_ERROR,
    data
  }
};


//GET BANK_ACCOUNT
export const bankAccountPatch = () => {
  return {
    type: transationTypes.BANK_ACCOUNT_PATCH
  }
};

export const bankAccountPatchSuccess = (data: IbankAccount) => {
  return {
    type: transationTypes.BANK_ACCOUNT_PATCH_SUCCESS,
    data
  }
};

export const bankAccountPatchError = (data: Error) => {
  return {
    type: transationTypes.BANK_ACCOUNT_SET_ERROR,
    data
  }
};
