import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import Table from "components/table/TableWithAccordion";
import { MdPageview, MdOutlineSaveAlt } from "react-icons/md";
import { useSettlement } from '../context/providers/SettlementsProvider';
import { useNavigate } from "react-router-dom";
import * as settlementActions from '../context/actions/SettlementActions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Input, Button, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { SortingState } from "@tanstack/react-table";
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';

enum CategoryValues {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
};

enum CategoryLabels {
  DEPOSIT = "Depósito",
  WITHDRAWAL = "Retiros",
};

enum statusesValues {
  pending = 'PENDING',
  approved = 'APPROVED',
  processing = 'PROCESSING',
  successful = 'SUCCESSFUL',
  failed = 'FAILED'
};

enum statusesLabel {
  pending = "Pendiente",
  approved = "Aprobado",
  processing = "Procesando",
  successful = "Exitoso",
  failed = "Fallido",
};

export default function List() {

  const navigate = useNavigate();
  const { state: { settlements, request }, dispatch } = useSettlement();
  const [toSearchDate, setToSearchDate] = useState<string>();
  const [fromSearchDate, setFromSearchDate] = useState<string>();
  const [searchState, setSearchState] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchType, setSearchType] = useState<string>('NETTING');


  const setCustomValue = (data: any) => {
    const value: CategoryValues = data.getValue();
    const categoryLabel = CategoryLabels[value];
    return categoryLabel || value;
  };

  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
    { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status', export: true },
    { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'totalAmount', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Categoría', name: 'category', setCustomValue: setCustomValue, export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'created', export: true },
    { type: COLUMN_TYPE.DATETIME, label: 'Fecha Actualización', name: 'updated', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', actions: [
        {
          title: 'Ver Detalles',
          onClick: (info: any) => navigate(`/admin/settlement/${info?.row?.original?.id}`),
          icon: <MdPageview color="#422afb" className="h-8 w-8" />,
          isLoading: request
        }
      ]
    }
  ];

  const totalItems = useMemo(() => { return settlements?.meta?.totalItems }, [settlements]);

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`funding/settlements/instant-funding/search?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(settlementActions.settlementsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}broker/funding/settlements/instant-funding/search?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchState?.length ? `filter.status=$eq:${searchState}&` : '';
    url += searchType?.length ? `filter.category=$eq:${searchType}&` : '';

    switch (true) {
      case !!fromSearchDate?.length && !!toSearchDate?.length:
        url += `filter.created=$btw:${fromSearchDate},${toSearchDate}T23:59:59`;
        break;
      case !!fromSearchDate?.length:
        url += `filter.created=$gte:${fromSearchDate}`;
        break;
      case !!toSearchDate?.length:
        url += `filter.created=$lte:${toSearchDate}T23:59:59`;
        break;
    }

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const onSearch = ({ searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType }: { searchText: string, searchState: string, sorting: SortingState, fromSearchDate: string, toSearchDate: string, searchType: string }) => {
    if (!searchText?.length && !searchState?.length && !fromSearchDate?.length && !toSearchDate?.length && !sorting?.length && !searchType.length) {
      return
    }

    let url = searchUrl;

    fetchData(`funding/settlements/instant-funding/search?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(settlementActions.settlementsSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  useEffect(() => {
    let url = 'funding/settlements/instant-funding/search?';

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'funding/settlements/instant-funding/search?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    if (!onSearch || (!searchText?.length && !searchState?.length && !fromSearchDate?.length && !toSearchDate?.length && !searchType?.length)) {
      fetchData(url)
        .then((data: any) => dispatch(settlementActions.settlementsSuccess(data)))
        .catch((error: any) => dispatch(settlementActions.settlementsError(new Error(error.statusText || error.message || error))));
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, searchState, sorting, fromSearchDate, toSearchDate, searchType]);

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${settlements?.meta?.totalItems}`;

    fetchData(`funding/settlements/instant-funding/search?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'lotes');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Desde</Text><Input defaultValue={fromSearchDate} onChange={(e) => setFromSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Desde" /></GridItem>
    <GridItem w='100%' display={'flex'} alignItems={'center'} gap={6}><Text>Hasta</Text><Input defaultValue={toSearchDate} onChange={(e) => setToSearchDate(e.target?.value ? new Date(e.target?.value).toISOString().split('T')[0] : null)} type="date" placeholder="Fecha Hasta" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchState} onChange={(e) => setSearchState(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por estado*'>
        <option value={statusesValues.approved}>{statusesLabel.approved}</option>
        <option value={statusesValues.pending}>{statusesLabel.pending}</option>
        <option value={statusesValues.processing}>{statusesLabel.processing}</option>
        <option value={statusesValues.successful}>{statusesLabel.successful}</option>
        <option value={statusesValues.failed}>{statusesLabel.failed}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Select value={searchType} onChange={(e) => setSearchType(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por Tipo'>
        <option value ='NETTING'>{'Neteo'}</option>
        <option value='DEPOSIT'>{'Deposito'}</option>
        <option value='WITHDRAWAL'>{'Retiro'}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
  </Grid>, [totalItems, searchText, searchState, searchType, fromSearchDate, toSearchDate, searchUrl, settlements]);

  const BoxContent = useMemo(() => <Grid display={'flex'} gap={6}>
    {settlements?.meta?.brief ?
      <>
        <GridItem><Text>Total: <b>{ `$${settlements.meta.brief.totalAmount?.toLocaleString()}` }</b></Text></GridItem>
        <GridItem>Depositos: <b>{ `$${settlements.meta.brief.totalDeposits?.toLocaleString()}` }</b></GridItem>
        <GridItem>Retiros: <b>{ `$${settlements.meta.brief.totalWithdrawals?.toLocaleString()}` }</b></GridItem>
      </>
      : null
    }

  </Grid>, [settlements]);


  return (<Table
    request={request}
    data={settlements}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    onPaginate={onPaginate}
    onSort={setSorting}
    boxContent={BoxContent}
  />);
}
