import React from "react";
import { TableProvider } from './context/providers/TableProvider';
import Table from './Table';
import { TABLE_TYPE } from './context/types/table'

const ViewFormView = (props: TABLE_TYPE) => {
  return (
    <TableProvider>
      <Table {...props}></Table>
    </TableProvider>
  );
};

export default ViewFormView;