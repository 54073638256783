import { redemptionContextType, Action } from './../types/redemption.d';
import { transationTypes } from '../actions/RedemptionActionTypes';

//the initial state of the user
export const initialState: redemptionContextType = {
  request: false,
  notification: null,
  error: null,
  redemptions: { meta: {}, links: {}, data: [] },
  redemption: null
};


//the reducer function note the parameter type annotations
export const reducer = (state: redemptionContextType, action: Action) => {

  switch (action.type) {

    //GET ALL
    case transationTypes.WITHDRAWALS_GET:
      return {
        ...state,
        request: true,
        error: null,
        redemption: null
      };
    case transationTypes.WITHDRAWALS_GET_SUCCESS:
      return {
        ...state,
        request: false,
        redemptions: action.data,
      };


    //GET
    case transationTypes.WITHDRAWAL_GET:
      return {
        ...state,
        request: true,
        redemption: null
      };
    case transationTypes.WITHDRAWAL_GET_SUCCESS:
      return {
        ...state,
        request: false,
        redemption: action.data,
      };


    //ADD
    case transationTypes.WITHDRAWAL_ADD:
      return {
        ...state,
        request: true,
        redemption: null
      };

    case transationTypes.WITHDRAWAL_ADD_SUCCESS:
      return {
        ...state,
        request: false,
        redemption: action.data,
      };




    //NOTIFY
    case transationTypes.WITHDRAWAL_NOTIFY:
      return {
        ...state,
        notification: action.data,
      };

    case transationTypes.WITHDRAWAL_NOTIFY_CLEAR:
      return {
        ...state,
        notification: null
      };

    //ERROR
    case transationTypes.WITHDRAWAL_SET_ERROR:
      return {
        ...state,
        request: false,
        error: action.data,
      };


    //DELETE
    case transationTypes.WITHDRAWAL_DELETE_GET:
      return {
        ...state,
        request: true,
        deleted: false
      };

    case transationTypes.WITHDRAWAL_DELETE_GET_SUCCESS:
      return {
        ...state,
        deleted: true,
        request: false
      };

    //UPDATE
    case transationTypes.WITHDRAWAL_UPDATE_GET:
      return {
        ...state,
        request: true,
        updated: false
      };

    case transationTypes.WITHDRAWAL_UPDATE_GET_SUCCESS:
      return {
        ...state,
        redemption: null,
        request: false,
        updated: true
      };


    default:
      return state;
  }
};