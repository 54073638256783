export enum transationTypes {
    ACCOUNT_NOTIFY_CLEAR = 'ACCOUNT_NOTIFY_CLEAR',
    ACCOUNT_NOTIFY = 'ACCOUNT_NOTIFY',

    ACCOUNTS_GET = 'ACCOUNTS_GET',
    ACCOUNTS_GET_SUCCESS = 'ACCOUNTS_GET_SUCCESS',
    ACCOUNTS_CLEAR = 'ACCOUNTS_CLEAR',

    ACCOUNT_GET = 'ACCOUNT_GET',
    ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS',
    ACCOUNT_CLEAR = 'ACCOUNT_CLEAR',

    ACCOUNT_ADD = 'ACCOUNT_ADD',
    ACCOUNT_ADD_SUCCESS = 'ACCOUNT_ADD_SUCCESS',

    ACCOUNT_DELETE_GET = 'ACCOUNT_DELETE_GET',
    ACCOUNT_DELETE_GET_SUCCESS = 'ACCOUNT_DELETE_GET_SUCCESS',

    ACCOUNT_UPDATE_GET = 'ACCOUNT_UPDATE_GET',
    ACCOUNT_UPDATE_GET_SUCCESS = 'ACCOUNT_UPDATE_GET_SUCCESS',

    ACCOUNT_PERFORMANCE_GET = 'ACCOUNT_PERFORMANCE_GET',
    ACCOUNT_PERFORMANCE_GET_SUCCESS = 'ACCOUNT_PERFORMANCE_GET_SUCCESS',
    ACCOUNT_PERFORMANCE_GET_ERROR = 'ACCOUNT_PERFORMANCE_GET_ERROR',

    ACCOUNT_STATEMENTS_GET = 'ACCOUNT_STATEMENTS_GET',
    ACCOUNT_STATEMENTS_GET_SUCCESS = 'ACCOUNT_STATEMENTS_GET_SUCCESS',

    ACCOUNT_TAXFORMS_GET = 'ACCOUNT_TAXFORMS_GET',
    ACCOUNT_TAXFORMS_GET_SUCCESS = 'ACCOUNT_TAXFORMS_GET_SUCCESS',

    ACCOUNT_DOCUMENT_URL_GET_SUCCESS = 'ACCOUNT_DOCUMENT_URL_GET_SUCCESS',
    ACCOUNT_DOCUMENT_URL_GET = '    ACCOUNT_DOCUMENT_URL_GET_SUCCESS = ',

    ACCOUNT_DEPOSITS_GET = 'ACCOUNT_DEPOSITS_GET',
    ACCOUNT_DEPOSITS_GET_SUCCESS = 'ACCOUNT_DEPOSITS_GET_SUCCESS',
    ACCOUNT_DEPOSITS_GET_ERROR = 'ACCOUNT_DEPOSITS_GET_ERROR',

    ACCOUNT_WITHDRAWALS_GET = 'ACCOUNT_WITHDRAWALS_GET',
    ACCOUNT_WITHDRAWALS_GET_SUCCESS = 'ACCOUNT_WITHDRAWALS_GET_SUCCESS',
    ACCOUNT_WITHDRAWALS_GET_ERROR = 'ACCOUNT_WITHDRAWALS_GET_ERROR',

    ACCOUNT_TRANSACTIONS_GET = 'ACCOUNT_TRANSACTIONS_GET',
    ACCOUNT_TRANSACTIONS_GET_SUCCESS = 'ACCOUNT_TRANSACTIONS_GET_SUCCESS',
    ACCOUNT_TRANSACTIONS_GET_ERROR = 'ACCOUNT_TRANSACTIONS_GET_ERROR',
    

    ACCOUNT_SET_ERROR = 'ACCOUNT_SET_ERROR',
    ACCOUNT_CLEAR_ERROR = 'ACCOUNT_CLEAR_ERROR',
};
