const baseUrl: string = process.env.REACT_APP_API_URL || 'http://localhost:3002/';

export default async function fetchData(url = '', method = 'GET', data = {}, headers: any = {}) {
  try {

    if(!headers?.Authorization){
      try {
        const stored = sessionStorage.getItem('AuthProvider');
        const authData = JSON.parse(stored);
        headers.Authorization = `Bearer ${authData.auth.access_token}`;
      } catch (error) {
      }
    }

    const response = await fetch(`${baseUrl}${url}${method !== 'GET' ? '' : new URLSearchParams(data)}`, {
      method,
      mode: "cors",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "quiena-client-app-key":"8df2d3da-09b3-4ea5-b2af-278fce083991",
        ...headers
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      ...(method === 'GET' ? {} : {body: JSON.stringify(data)}),
    })
    
    if(![200, 201].includes(response.status)) {
      throw response
    }

    if(method === 'DELETE') {
      return response;
    }

    return response.json(); // parses JSON response into native JavaScript objects

  } catch (error: any) {

    throw error?.message || error;
  }
}