import { authContextType, Action } from '../types/auth';


//the initial state of the user
export const initialState: authContextType = {
  auth: null,
  data: null
};


//the action we are going to take when we login that is set the user
export const actionTypes = {
  SET_USER: 'SET_USER',
  UNSET_USER: 'UNSET_USER',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_DRIVEWEALTH_USER: 'SET_USER_DRIVEWEALTH_USER',
};


//the reducer function note the parameter type annotations
export const reducer = (state: authContextType, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        auth: action.data,
      };
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.SET_USER_DRIVEWEALTH_USER:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.UNSET_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};