import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "components/card";
import { useCircuitTransfer } from '../context/providers/CircuitTransfersProvider';
import CircuitTransferForm from './Form';
import Spinner from "components/spinner";
import fetchData from './../../../../helpers/fetchData';
import * as circuitTransferActions from './../context/actions/CircuitTransferActions';
import { IcircuitTransfer } from "../context/types/circuitTransfer";
import BackButton from "components/button/Back";

export default function AddForm() {
    const navigate = useNavigate();

    const { state, dispatch } = useCircuitTransfer();

    React.useEffect(() => {
        if (!state?.circuitTransfer?.id) {
            return;
        }

        navigate(`/admin/circuit-transfer/edit/${state.circuitTransfer.id}`)
    }, [state, navigate]);


    const onSubmit = async (circuitTransferData: IcircuitTransfer) => {
        try {
            dispatch(circuitTransferActions.circuitTransferAdd());
            dispatch(circuitTransferActions.circuitTransferAddSuccess(await fetchData('circuit-transfer', 'POST', circuitTransferData)));
            dispatch(circuitTransferActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
        } catch (error: any) {
            dispatch(circuitTransferActions.circuitTransferAddError(new Error(error.statusText || error.message || error)))
        }
    };

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Completar la información de la Transacción
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!state.request} />

            <CircuitTransferForm onSubmit={onSubmit} />
        </Card>
    </>);
}