import EditForm from "./components/EditForm";
import { CircuitTransfersProvider } from './context/providers/CircuitTransfersProvider';

const EditFormView = () => {
  return (
    <CircuitTransfersProvider>
      <EditForm></EditForm>
    </CircuitTransfersProvider>
  );
};

export default EditFormView;
