import React, { useMemo } from "react";
import { useAccount } from '../context/providers/AccountsProvider';
import {
    Grid,
    GridItem,
    Center,
} from '@chakra-ui/react';
import * as accountActions from './../context/actions/AccountActions';
import fetchData from './../../../../helpers/fetchData';
import { COLUMN_TYPE } from "components/table/Table";
import Table from "components/table";

export default function DepositsForm() {
    const { state: { deposits, account, request }, dispatch } = useAccount();

    const submit = async () => {

        if (!account.id) {
            dispatch(accountActions.notifyError('Completar los datos requeridos'))
            return;
        }

        try {
            dispatch(accountActions.accountDepositsGet());
            dispatch(accountActions.accountDepositsSuccess(await fetchData(`accounts/${account.id}/funding/deposits`, 'GET')));

        } catch (error: any) {
            dispatch(accountActions.accountDepositsError(new Error(error.statusText || error.message || error)))
        }


    }

    React.useEffect(() => {
        if (!account.id) {
            return;
        }

        submit();
    }, [account]);

    const gridData = useMemo(() => {
        if (!Array.isArray(deposits)) {
            return [];
        }

        return deposits.map((depositData) => ({
            ...depositData,
            status: depositData.status.message
        }))
    }, [deposits])

    const tableColumnsData = [
        { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'Tipo', name: 'type', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'Nota', name: 'note', export: true },
        { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
        { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status', export: true },
        { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'timestamp', export: true }
    ];

    return (<Center>
        <Grid
            className="w-full"
            templateColumns={{
                base: 'repeat(1, 1fr)'
            }}
            gap={1}
            content="center"
        >
            <GridItem>
                <Table name={`depositos_${account.accountNo}`} request={request} data={gridData} columns={tableColumnsData} addExport={true} />
            </GridItem>
        </Grid>
    </Center>);
}