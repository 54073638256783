import React from "react";
import {
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { COLUMN_TYPE } from "components/table/Table";


export default function AddForm({ data }: { data?: any }) {
    
    const navigate = useNavigate();

    const tableColumnsData = [
        { type: COLUMN_TYPE.TEXT, label: 'ID Usuario', name: 'userID', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'ID Cuenta', name: 'accountID', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'Nro. Cuenta', name: 'accountNo', export: true, hidden: true },
        { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'paymentID', export: true },
        { type: COLUMN_TYPE.TEXT, label: 'Categoría', name: 'category', export: true },
        { type: COLUMN_TYPE.MONEY, label: 'Monto', name: 'amount', export: true },
        { type: COLUMN_TYPE.DATETIME, label: 'Fecha Creación', name: 'created', export: true },
    ];

    return (<>
        <Grid
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
            }}
            gap={6}
            rowGap={12}
        >
            <GridItem hidden={true} w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        ID:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.id}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Categoría:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.category}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Monto:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    ${data?.amount.toLocaleString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Estado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.status.message}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Comentario:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.status.comment}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Actualizado:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {new Date(data.status.updated).toLocaleDateString() + ' ' + new Date(data.status.updated).toLocaleTimeString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Cuenta No:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.accountDetails.accountNo}
                </Text>
            </GridItem>           
        </Grid>
    </>);
}