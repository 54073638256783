import ViewForm from "./components/ViewForm";
import { AccountsProvider } from './context/providers/AccountsProvider';

const ViewFormView = () => {
  return (
    <AccountsProvider>
      <ViewForm></ViewForm>
    </AccountsProvider>
  );
};

export default ViewFormView;
