import React from "react";
import { Link, useParams } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useRedemption } from '../context/providers/RedemptionProvider';
import RedemptionForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as redemptionActions from './../context/actions/RedemptionActions';
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, redemption, error } } = useRedemption();

    React.useEffect(() => {
        if (id && !request && redemption?.id !== id) {
            dispatch(redemptionActions.redemptionGet());
            fetchData(`funding/redemptions/${id}`)
                .then((data: any) => dispatch(redemptionActions.redemptionGetSuccess(data)))
                .catch((error: any) => dispatch(redemptionActions.redemptionGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, redemption, error]);

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Detalle Redemption - {redemption?.id}
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !redemption) } />
            {(!redemption) ? null : <RedemptionForm data={redemption} />}
        </Card>
    </>);
}