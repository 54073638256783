import React, { createContext, ReactNode } from 'react';
import { tableContextType } from '../types/table';
import { initialState, reducer } from '../reducers/TableReducer';
import { useSessionReducer } from './../../../../hooks/useSessionReducer';
import { useToast } from '@chakra-ui/react';
import * as tableActions from '../actions/TableActions';

export const tableContext = createContext<{
  state: tableContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useTable = () => {
  const context = React.useContext(tableContext)
  if (context === undefined) {
      throw new Error('useTable must be used within a TableProvider')
  }
  return context
};

export const TableProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'TableProvider');
  const toast = useToast();
  const { notification, error } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });

    dispatch(tableActions.tablesClear())


  }, [error, toast, dispatch]);

  React.useEffect(() => {
    if (!notification) {
      return;
    }

    toast({
      title: notification.title,
      status: notification.type,
      isClosable: true,
    });

    dispatch(tableActions.notifyClear())

  }, [notification, toast, dispatch]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <tableContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </tableContext.Provider>
  );
};
