import { transationTypes } from '../actions/FeeActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface IFee {
	id?: string;
	created: string;
	date: string;
	status: string;
	amount: string;
	type: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export type IFeeGrid = {
    data?: IFee[];
	links: any;
	meta: any;
};

export type FeeContextType = {
    fees: IFeeGrid;
	fee: IFee;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};