import { useUser } from "../context/providers/UsersProvider";
import BankAccountsTable from "./../../bankAccounts/index";

const BankAccounts = () => {
  const { state: { user } } = useUser();
  return (
    !!user?.qID ? <BankAccountsTable userId={user.qID} /> : null
  );
};

export default BankAccounts;
