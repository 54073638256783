import EditForm from "./components/EditForm";
import { BankAccountsProvider } from './context/providers/BankAccountsProvider';

const EditFormView = () => {
  return (
    <BankAccountsProvider>
      <EditForm></EditForm>
    </BankAccountsProvider>
  );
};

export default EditFormView;
