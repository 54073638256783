import { transationTypes } from './../actions/BankAccountActionTypes';

export enum NotifyType {
	SUCCESS = 'success',
	ERROR = 'error'
};

interface IbankAccount {
	id?: string;
	name: string;
	status: string;
	userDetails?: any;
	created: string;
	updated: string;
	evaluation?: string;
	evaluationDate?: string;
}

export type Action = {
    type: transationTypes;
    data?: any;
};

export interface IBankAccountGrid {
	data: IbankAccount[];
	meta: any;
	links:any;
}

export type bankAccountContextType = {
    bankAccounts: IBankAccountGrid;
	bankAccount: IbankAccount;
	request: boolean;
	error: Error | null;
	notification: {
		title: string,
		type: NotifyType
	};
};