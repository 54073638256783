
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useUser } from '../context/providers/UsersProvider';
import * as userActions from './../context/actions/UserActions';
import {
  MdOutlineSaveAlt,
  MdPageview,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import fetchData from './../../../../helpers/fetchData';
import { Select, Input, Button, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react'
import Table from "components/table/TableWithAccordion";
import { COLUMN_TYPE, downloadTableAsCSV } from "components/table/Table";
import { SortingState } from "@tanstack/react-table";
const baseB2BUrl: string = process.env.REACT_APP_API_B2B_URL || 'http://localhost:3003/';

enum statusesValues {
  pending = "PENDING",
  approved = "APPROVED",
};

enum statusesLabel {
  pending = "Pendiente",
  approved = "Aprobada",
};

export default function List({ userId }: { userId?: string }) {

  const navigate = useNavigate();
  const { state: { users, request }, dispatch } = useUser();
  const [searchText, setSearchText] = useState<string>();
  const [searchState, setSearchState] = useState<string>();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const tableColumnsData = [
    { type: COLUMN_TYPE.TEXT, label: 'ID', name: 'id', export: true, hidden: true },
    { type: COLUMN_TYPE.TEXT, label: 'Email', name: 'email', export: true },
    { type: COLUMN_TYPE.TEXT, label: 'Nombre', name: 'fullName', export: true },
    { type: COLUMN_TYPE.STATUS, label: 'Estado', name: 'status', export: true },
    { type: COLUMN_TYPE.DATE, label: 'Fecha Creación', name: 'createdAt', export: true },
    {
      type: COLUMN_TYPE.ACTIONS, label: '', name: 'actions', actions: [
        {
          title: 'Ver Detalles',
          onClick: (info: any) => navigate(`/admin/users/view/${info.row.original.id}`),
          icon: <MdPageview color="#422afb" className="h-8 w-8" />,
          isLoading: request
        }
      ]
    }
  ];

  const onPaginate = (url: string) => {
    if (!url) {
      return
    }

    fetchData(`dwusers?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(userActions.usersSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getSearchUrl = useCallback(() => {
    let url = `${`${baseB2BUrl}broker/users/search?`}`;
    url += searchText?.length ? `search=${searchText}&` : '';
    url += searchState?.length ? `filter.status=$eq:${searchState}&` : '';

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `&sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });


    return url;
  }, [searchText, searchState, sorting]);

  const searchUrl = useMemo(() => {
    return getSearchUrl();
  }, [getSearchUrl]);

  const onSearch = ({ searchText, searchState, sorting }: { searchText: string, searchState: string, sorting: SortingState }) => {
    if (!searchText?.length && !searchState?.length) {
      return
    }

    let url = searchUrl;

    fetchData(`dwusers?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        dispatch(userActions.usersSuccess(data));
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const exportData = () => {
    let url = searchUrl;
    url += `&limit=${users?.meta?.totalItems}`;

    fetchData(`dwusers?url=${encodeURIComponent(url)}`)
      .then((data: any) => {
        downloadTableAsCSV(tableColumnsData, data.data, 'usuarios');
      })
      .catch((error: any) => console.error(new Error(error.statusText || error.message || error)));
  };

  const getData = useCallback(() => {
    if (userId) {
      return;
    }

    let url = 'dwusers?'

    Array.isArray(sorting) && sorting.forEach((data: any) => {
      url += `${url === 'dwusers?' ? '' : '&'}sortBy=${data.id}:${data.desc ? 'DESC' : 'ASC'}`
    });

    dispatch(userActions.usersGet());
    fetchData(`${url}`)
      .then((data: any) => dispatch(userActions.usersSuccess(data)))
      .catch((error: any) => dispatch(userActions.usersError(new Error(error.statusText || error.message || error))));

  }, [dispatch, userId, sorting]);

  useEffect(() => {
    if (!onSearch || (!searchText?.length && !searchState?.length)) {
      getData();
      return;
    }

    const searchTimeout = setTimeout(() => {
      onSearch({ searchText, searchState, sorting })
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    }

  }, [searchText, getData, searchState, sorting]);

  const totalItems = useMemo(() => { return users?.meta?.totalItems }, [users]);

  const AccordionContent = useMemo(() => <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    }}
    gap={6}
    alignItems={'center'}
  >
    <GridItem w='100%'><Input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Búsqueda Rápida" /></GridItem>
    <GridItem w='100%'>
      <Select value={searchState} onChange={(e) => setSearchState(e.target?.selectedOptions?.[0]?.value)} placeholder='Filtrar por estado'>
        <option value={statusesValues.approved}>{statusesLabel.approved}</option>
        <option value={statusesValues.pending}>{statusesLabel.pending}</option>
      </Select>
    </GridItem>
    <GridItem w='100%'>
      <Button
        cursor={'pointer'}
        isLoading={request}
        title={'Descargar CSV'}
        variant={'link'}
        colorScheme="black"
        onClick={exportData}
      >
        <Text display={'flex'}>Descargar datos ({totalItems}) <MdOutlineSaveAlt className="h-5 w-5" /></Text>
      </Button>
    </GridItem>
    <GridItem w='100%' />
  </Grid>, [searchText, setSearchText, searchState, setSearchState, users, searchUrl]);

  return (<Table
    request={request}
    data={users}
    columns={tableColumnsData}
    accordionContent={AccordionContent}
    onPaginate={onPaginate}
    onSort={setSorting}
  />);
};
