export enum transationTypes {
    CIRCUIT_TRANSFER_NOTIFY_CLEAR = 'CIRCUIT_TRANSFER_NOTIFY_CLEAR',
    CIRCUIT_TRANSFER_NOTIFY = 'CIRCUIT_TRANSFER_NOTIFY',

    CIRCUIT_TRANSFERS_GET = 'CIRCUIT_TRANSFERS_GET',
    CIRCUIT_TRANSFERS_GET_SUCCESS = 'CIRCUIT_TRANSFERS_GET_SUCCESS',

    CIRCUIT_TRANSFER_GET = 'CIRCUIT_TRANSFER_GET',
    CIRCUIT_TRANSFER_GET_SUCCESS = 'CIRCUIT_TRANSFER_GET_SUCCESS',

    CIRCUIT_TRANSFER_ADD = 'CIRCUIT_TRANSFER_ADD',
    CIRCUIT_TRANSFER_ADD_SUCCESS = 'CIRCUIT_TRANSFER_ADD_SUCCESS',

    CIRCUIT_TRANSFER_DELETE_GET = 'CIRCUIT_TRANSFER_DELETE_GET',
    CIRCUIT_TRANSFER_DELETE_GET_SUCCESS = 'CIRCUIT_TRANSFER_DELETE_GET_SUCCESS',

    CIRCUIT_TRANSFER_UPDATE_GET = 'CIRCUIT_TRANSFER_UPDATE_GET',
    CIRCUIT_TRANSFER_UPDATE_GET_SUCCESS = 'CIRCUIT_TRANSFER_UPDATE_GET_SUCCESS',

    CIRCUIT_TRANSFER_SET_ERROR = 'CIRCUIT_TRANSFER_SET_ERROR',
};
