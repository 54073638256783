import { IFee, NotifyType } from './../types/fee.d';
import { transationTypes } from './FeeActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.FEE_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.FEE_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET FEES
export const feesGet = () => {
  return {
    type: transationTypes.FEES_GET
  }
};

export const feesSuccess = (data: IFee[]) => {
  return {
    type: transationTypes.FEES_GET_SUCCESS,
    data
  }
};

export const feesError = (data: Error) => {
  return {
    type: transationTypes.FEE_SET_ERROR,
    data
  }
};


//GET FEE
export const feeGet = () => {
  return {
    type: transationTypes.FEE_GET
  }
};

export const feeGetSuccess = (data: IFee) => {
  return {
    type: transationTypes.FEE_GET_SUCCESS,
    data
  }
};

export const feeGetError = (data: Error) => {
  return {
    type: transationTypes.FEE_SET_ERROR,
    data
  }
};


