import React, { useState, useEffect } from "react";
import { useBankAccount } from './../context/providers/BankAccountsProvider';
import * as bankAccountActions from './../context/actions/BankAccountActions';
import fetchData from '../../../../helpers/fetchData';
import { Select, Input, Text } from "@chakra-ui/react";
import Modal from "components/modal";
import { statusesValues, statusesLabel } from './List';
import * as userActions from './../../users/context/actions/UserActions';

export default function EvaluationModal({ title = "Evaluar Cuenta", comfirmIsOpen, setComfirmIsOpen, evaluationID, userID }: { title?: string, userID: string, evaluationID: string, comfirmIsOpen?: boolean, setComfirmIsOpen: Function }) {

  const { dispatch, state: { bankAccount } } = useBankAccount();
  const [evaluation, setEvaluation] = useState<string>();
  const [image, setImage] = useState<string>();

  const changeBankAccountEvaluation = async () => {
    try {
      if (!evaluationID) {
        return dispatch(bankAccountActions.notifyError('Error en el formulario'))
      }

      dispatch(bankAccountActions.bankAccountPatch());
      dispatch(bankAccountActions.bankAccountPatchSuccess(await fetchData(`bank-accounts/${evaluationID}`, 'PATCH', {
        evaluation: evaluation
      })));

      if(image) {
        dispatch(userActions.userDocumentsPost());
        dispatch(userActions.userDocumentsPostSuccess(await fetchData(`dwusers/documents`, 'POST', {
          type: "BENEFICIARY_DOCUMENT",
          document: image,
          side: "FRONT",
          userID,
        })));
      }

      dispatch(bankAccountActions.bankAccountGetSuccess({...bankAccount, id: '0'}));


      dispatch(bankAccountActions.notifySuccess('¡Felicitaciones por su increíble éxito!'));
      setComfirmIsOpen(false);
    } catch (error: any) {
      dispatch(bankAccountActions.notifyError(error.statusText || error.message || error))
    }
  };

  return (<>
    <Modal
      isOpen={comfirmIsOpen}
      onClose={(result) => {
        if (!result) {
          setComfirmIsOpen(false)
          return;
        }

        changeBankAccountEvaluation();
      }}
      children={<div>
        <Text mb='8px'>Archivo de declaración y resultado:</Text>
        <Input type="file" onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
              setImage(event.target.result.toString())
            };
            reader.readAsDataURL(file);
          }
        }} className="pt-2 mb-3" accept="image/*" />
        <Select placeContent={'Declaración'} value={evaluation} onChange={(e) => setEvaluation(e.target?.selectedOptions?.[0]?.value)} placeholder='Resultado*'>
          <option value={statusesValues.PENDING}>{statusesLabel.PENDING}</option>
          <option value={statusesValues.APPROVED}>{statusesLabel.APPROVED}</option>
          <option value={statusesValues.REJECTED}>{statusesLabel.REJECTED}</option>
          <option value={statusesValues.BENEFICIARY_MISMATCH}>{statusesLabel.BENEFICIARY_MISMATCH}</option>
          <option value={statusesValues.DATA_MISMATCH}>{statusesLabel.DATA_MISMATCH}</option>
          <option value={statusesValues.MISSING_STATEMENT}>{statusesLabel.MISSING_STATEMENT}</option>
          <option value={statusesValues.UNRELIABLE_STATEMENT}>{statusesLabel.UNRELIABLE_STATEMENT}</option>
          <option value={statusesValues.WRONG_STATEMENT}>{statusesLabel.WRONG_STATEMENT}</option>
        </Select>
      </div>}
      title={title}
    />
  </>);
};
