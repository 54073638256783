import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useTransaction } from '../context/providers/Provider';
import TransactionForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as actions from './../context/actions/Actions';
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, transaction, error } } = useTransaction();

    React.useEffect(() => {
        if (!request && transaction?.id != id) {
            dispatch(actions.transactionGet());
            fetchData(`transactions/${id}`)
                .then((data: any) => dispatch(actions.transactionGetSuccess(data)))
                .catch((error: any) => dispatch(actions.transactionGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, transaction]);

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Detalle de Transaction
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !transaction) } />
            {(!transaction) ? null : <TransactionForm data={transaction} />}
        </Card>
    </>);
}