import React, { createContext, ReactNode } from 'react';
import { bankAccountContextType } from '../types/bankAccount';
import { initialState, reducer } from '../reducers/BankAccountsReducer';
import { useSessionReducer } from '../../../../../hooks/useSessionReducer';
import * as bankAccountActions from '../actions/BankAccountActions';
import { useToast } from '@chakra-ui/react';

export const bankAccountsContext = createContext<{
  state: bankAccountContextType;
  dispatch: React.Dispatch<any>;
  clearValue: Function;
}>({
  state: initialState,
  dispatch: () => null,
  clearValue: () => { }
});


export const useBankAccount = () => {
  const context = React.useContext(bankAccountsContext)
  if (context === undefined) {
      throw new Error('useBankAccount must be used within a BankAccountProvider')
  }
  return context
};

export const BankAccountsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //bring the useReducer hook and feed it with the reducer function and the initial state 
  const [state, dispatch, clearValue] = useSessionReducer(reducer, initialState, 'BankAccountsProvider');
  const toast = useToast();
  const { error, notification } = state;

  React.useEffect(() => {

    const toastTitle: String = error?.message;

    if (!toastTitle) {
      return;
    }

    toast({
      title: toastTitle,
      status: 'error',
      isClosable: true,
    });


  }, [error, toast]);

  React.useEffect(() => {
    if (!notification) {
      return;
    }

    toast({
      title: notification.title,
      status: notification.type,
      isClosable: true,
    });

  }, [notification, toast, dispatch]);

  return (
    //update the value with the state and dispatch thus you are able to access this values in any component or page
    <bankAccountsContext.Provider value={{ state, dispatch, clearValue }}>
      {children}
    </bankAccountsContext.Provider>
  );
};
