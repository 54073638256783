import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useBankAccount } from '../context/providers/BankAccountsProvider';
import BankAccountForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as bankAccountActions from './../context/actions/BankAccountActions';
import BackButton from "components/button/Back";
import { MdPersonOutline, MdOutlineLoupe } from "react-icons/md";
import { authContext } from "views/auth/context/Provider";
import EvaluationModal from "./EvaluationModal";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, bankAccount, error } } = useBankAccount();
    const navigate = useNavigate();
    const [comfirmIsOpen, setComfirmIsOpen] = useState<boolean>(false);
    const { state: { data, auth } } = React.useContext(authContext);

    React.useEffect(() => {
        if (!request && bankAccount?.id != id && !error?.message) {
            dispatch(bankAccountActions.bankAccountGet());
            fetchData(`bank-accounts/${id}`)
                .then((data: any) => dispatch(bankAccountActions.bankAccountGetSuccess(data)))
                .catch((error: any) => dispatch(bankAccountActions.bankAccountGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, bankAccount, error]);

    const comfirmApprobed = (info: any) => {
        setComfirmIsOpen(true);
    };

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white relative flex items-center justify-between">
                    Detalles de la cuenta

                    <EvaluationModal
                        evaluationID={bankAccount?.id}
                        userID={bankAccount?.userDetails?.userID}
                        setComfirmIsOpen={setComfirmIsOpen}
                        comfirmIsOpen={comfirmIsOpen}

                    />

                    <button
                        hidden={!bankAccount?.userDetails}
                        disabled={request}
                        onClick={() => navigate(`/admin/users/view/${bankAccount?.userDetails?.userID}`)}
                        className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                            : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                            } linear justify-center rounded-lg font-bold transition duration-200`}
                    >
                        <MdPersonOutline className="h-6 w-6" />
                    </button>

                    <button
                        hidden={data.rol !== 'admin'}
                        disabled={request}
                        onClick={comfirmApprobed}
                        className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
                            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
                            : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                            } linear justify-center rounded-lg font-bold transition duration-200`}
                    >
                        <MdOutlineLoupe color="brown" className="h-8 w-8 green" />
                    </button>

                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !bankAccount)} />
            {(!bankAccount) ? null : <BankAccountForm data={bankAccount} />}
        </Card>
    </>);
}