import { Isettlement, NotifyType, ITransactionGrid } from './../types/settlement.d';
import { transationTypes } from './SettlementActionTypes';


//NOTIFY
export const notifyError = (title: string) => {
  return {
    type: transationTypes.SETTLEMENT_NOTIFY,
    data: {
      title,
      type: NotifyType.ERROR
    }
  }
};

export const notifySuccess = (title: string) => {
  return {
    type: transationTypes.SETTLEMENT_NOTIFY,
    data: {
      title,
      type: NotifyType.SUCCESS
    }
  }
};


//GET SETTLEMENTS
export const settlementsGet = () => {
  return {
    type: transationTypes.SETTLEMENTS_GET
  }
};

export const settlementsSuccess = (data: ITransactionGrid) => {
  return {
    type: transationTypes.SETTLEMENTS_GET_SUCCESS,
    data
  }
};

export const settlementsError = (data: Error) => {
  return {
    type: transationTypes.SETTLEMENT_SET_ERROR,
    data
  }
};


//GET SETTLEMENT
export const settlementGet = () => {
  return {
    type: transationTypes.SETTLEMENT_GET
  }
};

export const settlementGetSuccess = (data: Isettlement) => {
  return {
    type: transationTypes.SETTLEMENT_GET_SUCCESS,
    data
  }
};

export const settlementGetError = (data: Error) => {
  return {
    type: transationTypes.SETTLEMENT_SET_ERROR,
    data
  }
};


