export enum transationTypes {
    WITHDRAWAL_NOTIFY_CLEAR = 'WITHDRAWAL_NOTIFY_CLEAR',
    WITHDRAWAL_NOTIFY = 'WITHDRAWAL_NOTIFY',

    WITHDRAWALS_GET = 'WITHDRAWALS_GET',
    WITHDRAWALS_GET_SUCCESS = 'WITHDRAWALS_GET_SUCCESS',

    WITHDRAWAL_GET = 'WITHDRAWAL_GET',
    WITHDRAWAL_GET_SUCCESS = 'WITHDRAWAL_GET_SUCCESS',

    WITHDRAWAL_ADD = 'WITHDRAWAL_ADD',
    WITHDRAWAL_ADD_SUCCESS = 'WITHDRAWAL_ADD_SUCCESS',

    WITHDRAWAL_DELETE_GET = 'WITHDRAWAL_DELETE_GET',
    WITHDRAWAL_DELETE_GET_SUCCESS = 'WITHDRAWAL_DELETE_GET_SUCCESS',

    WITHDRAWAL_UPDATE_GET = 'WITHDRAWAL_UPDATE_GET',
    WITHDRAWAL_UPDATE_GET_SUCCESS = 'WITHDRAWAL_UPDATE_GET_SUCCESS',

    WITHDRAWAL_SET_ERROR = 'WITHDRAWAL_SET_ERROR',

    WITHDRAWAL_PATCH = 'WITHDRAWAL_PATCH',
    WITHDRAWAL_PATCH_SUCCESS = 'WITHDRAWAL_PATCH_SUCCESS'
};
