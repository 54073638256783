import React from "react";
import {
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react';


export default function AddForm({ data }: { data?: any }) {



    return (<>
        <Grid
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(8, 1fr)',
                lg: 'repeat(12, 1fr)',
            }}
            gap={6}
            rowGap={12}
        >
            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        ID:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.id}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Tipo:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.type}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Monto:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    ${data?.amount.toLocaleString()}
                </Text>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <GridItem w='100%'>

                    <Text mr='2' pt='2' fontSize='sm'>
                        Usuario:
                    </Text>
                </GridItem>
                <GridItem w='100%'>

                    <Text pt='2' as='b' fontSize='sm'>
                        {data.brokerUser?.fullName} - {data.brokerUser?.email}
                    </Text>
                </GridItem>
            </GridItem>

            <GridItem w='100%' colSpan={2}>
                <Text mr='2' pt='2' fontSize='sm'>
                    Cuenta:
                </Text>
                <Text pt='2' as='b' fontSize='sm'>
                    {data.brokerAccount?.id} - {data.brokerAccount?.accountNo}
                </Text>
            </GridItem>
            
        </Grid>
    </>);
}