import List from "./components/List";
import { TransactionsProvider } from './context/providers/Provider';

const Tables = () => {
  return (
    <TransactionsProvider>
      <List />
    </TransactionsProvider>
  );
};

export default Tables;
