import React, { useEffect } from "react";
import Card from "components/card";
import {
  MdCancel,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
  MdEditDocument,
  MdDeleteSweep,
  MdPageview,
  MdFilterList,
  MdFilterListOff,
  MdPersonOutline,
  MdAccountBalanceWallet
} from "react-icons/md";
import { IcircuitTransfer } from '../context/types/circuitTransfer';
import { useCircuitTransfer } from '../context/providers/CircuitTransfersProvider';
import { Link, useNavigate } from "react-router-dom";
import * as circuitTransferActions from './../context/actions/CircuitTransferActions';
import fetchData from './../../../../helpers/fetchData';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import Spinner from "components/spinner";
import Comfirm from "components/comfirm";
import { useDisclosure, Select, Box, Input } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'

const columnHelper = createColumnHelper<IcircuitTransfer>();

enum statusesValues {
  pending = "pending",
  requested = "requested",
  executed = "executed",
  refused = "refused",
};

enum statusesLabel {
  pending = "Pendiente",
  requested = "Solicitada",
  executed = "Ejecutada",
  refused = "Rechazada",
};

export default function ComplexTable() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state: { circuitTransfers, request }, dispatch } = useCircuitTransfer();
  const [sorting, setSorting] = React.useState<SortingState>([]);


  useEffect(() => {
    dispatch(circuitTransferActions.circuitTransfersGet());
    fetchData(`circuit-transfer`)
      .then((data: any) => dispatch(circuitTransferActions.circuitTransfersSuccess(data)))
      .catch((error: any) => dispatch(circuitTransferActions.circuitTransfersError(new Error(error.statusText || error.message || error))));
  }, [dispatch]);

  const [dataForDelete, setDataForDelete] = React.useState<IcircuitTransfer>(undefined);
  const openDeleteComfirm = (data: IcircuitTransfer) => {
    setDataForDelete(data);
    onOpen();
  };

  const onCloseDeleteComfirm = async (result: boolean) => {
    if (result) {
      try {
        dispatch(circuitTransferActions.circuitTransferDelete());
        const data = await fetchData(`circuit-transfer/${dataForDelete.id}`, "DELETE");
        dispatch(circuitTransferActions.circuitTransferDeleteSuccess({}));
        dispatch(circuitTransferActions.circuitTransfersGet());
        dispatch(circuitTransferActions.circuitTransfersSuccess(await fetchData(`circuit-transfer`)))
      } catch (error: any) {
        dispatch(circuitTransferActions.circuitTransferDeleteError(new Error(error.statusText || error.message || error)))
      }
    }

    onClose();
    setDataForDelete(undefined);
  };



  const columns = [
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Fecha Creación
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {new Date(info.getValue()).toLocaleDateString() + ' ' + new Date(info.getValue()).toLocaleTimeString()}
        </div>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Monto
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          ${info.getValue().toLocaleString()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Estado</p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue() !== 'rejected' ? (
            <p className="text-sm font-bold text-navy-700 dark:text-white flex flex-row items-center"><MdCheckCircle className="text-green-500 me-1 dark:text-green-300" /> {info.getValue()}</p>
          ) : (
            <p className="text-sm font-bold text-navy-700 dark:text-white flex flex-row items-center"><MdCancel className="text-red-500 me-1 dark:text-red-300" />{info.getValue()}</p>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("transactionType", {
      id: "transactionType",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Tipo
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
            Fecha Actualización
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue() ? new Date(info.getValue()).toLocaleDateString() + ' ' + new Date(info.getValue()).toLocaleTimeString() : ''}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: () => (<div>
        <p className="text-sm font-bold text-gray-600 dark:text-white">
        </p>
      </div>),
      cell: (info) => (
        <div className="flex items-center justify-center">
          <button
            hidden={!info?.row?.original?.accountID}
            disabled={request}
            onClick={() => navigate(`/admin/accounts/view/${info.row.original.accountID}`)}
            className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-400 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdAccountBalanceWallet className="h-6 w-6" />
          </button>

          <button
            hidden={!info?.row?.original?.id}
            disabled={request}
            onClick={() => navigate(`/admin/circuit-transfer/edit/${info.row.original.id}`)}
            className={`flex items-center text-xl hover:cursor-pointer p-2 mr-2 ${request
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-400 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdPageview className="h-6 w-6" />
          </button>
          <button
            hidden={!info?.row?.original?.id}
            disabled={request}
            onClick={() => openDeleteComfirm(info.row.original)}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${request
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-400 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdDeleteSweep className="h-6 w-6 " />
          </button>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  const table = useReactTable({
    data: circuitTransfers?.data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
        </div>
        <Link
          to='/admin/circuit-transfer/add'
          className={`flex items-center text-xl hover:cursor-pointer py-2 px-4 ${false
            ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
            : "bg-brand-500 text-lightPrimary hover:bg-brand-700 dark:bg-lightPrimary/40 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-medium`}
        >
          Agregar
        </Link>
      </div>

      <h1 hidden={!!circuitTransfers?.data?.length}>No se encontraron resultados</h1>

      <Accordion hidden={!circuitTransfers?.data?.length || true} allowToggle className="my-5">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  </Box>
                  {isExpanded ? (
                    <MdFilterListOff fontSize='24px' />
                  ) : (
                    <MdFilterList fontSize='24px' />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>

                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(4, 1fr)',
                  }}
                  gap={6}
                >
                  <GridItem w='100%'><Input type="text" placeholder="Cuenta" /></GridItem>
                  <GridItem w='100%'>
                    <Select placeholder='Filtrar por Tipo'>
                      <option value='deposit'>{'Deposito'}</option>
                      <option value='withdraw'>{'Retiro'}</option>
                    </Select>
                  </GridItem>
                  <GridItem w='100%'>
                    <Select placeholder='Filtrar por estado'>
                      <option value={statusesValues.requested}>{statusesLabel.requested}</option>
                      <option value={statusesValues.pending}>{statusesLabel.pending}</option>
                      <option value={statusesValues.executed}>{statusesLabel.executed}</option>
                      <option value={statusesValues.refused}>{statusesLabel.refused}</option>
                    </Select>
                  </GridItem>
                  <GridItem w='100%' />
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <Comfirm isOpen={isOpen} text={`Esta seguro que desea eliminar la transaccion ID: ${dataForDelete?.id} ?`} onClose={onCloseDeleteComfirm} />

      <Spinner hidden={!request} />

      <div hidden={!circuitTransfers?.data?.length} className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="divider"></div>
        <div className="flex items-center gap-2 justify-center m-2 mt-10">
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${!table.getCanPreviousPage()
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdFirstPage className="h-6 w-6 " />
          </button>

          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${!table.getCanPreviousPage()
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdChevronLeft className="h-6 w-6 " />
          </button>

          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${!table.getCanNextPage()
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdChevronRight className="h-6 w-6 " />
          </button>


          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            className={`flex items-center text-xl hover:cursor-pointer p-2 ${!table.getCanNextPage()
              ? "!bg-gray-100 dark:!bg-white/5 text-gray-600 dark:!text-white/4"
              : "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <MdLastPage className="h-6 w-6 " />
          </button>

          <span className="flex items-center gap-1">
            <div>Página</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </strong>
          </span>

          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
            className="h-[40px] p-2 linear rounded-lg transition duration-200 flex items-center justify-center text-sm font-bold bg-lightPrimary text-gray hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Ver {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Card>
  );
}
