import React from "react";
import { Link, useParams } from "react-router-dom";
import Card from "components/card";
import Spinner from "components/spinner";
import { useDeposit } from '../context/providers/DepositsProvider';
import DepositForm from './Form';
import fetchData from './../../../../helpers/fetchData';
import * as depositActions from './../context/actions/DepositActions';
import BackButton from "components/button/Back";

export default function EditForm() {
    const { id } = useParams();
    const { dispatch, state: { request, deposit, error } } = useDeposit();

    React.useEffect(() => {
        if (id && !request && deposit?.id !== id) {
            dispatch(depositActions.depositGet());
            fetchData(`funding/deposits/${id}`)
                .then((data: any) => dispatch(depositActions.depositGetSuccess(data)))
                .catch((error: any) => dispatch(depositActions.depositGetError(new Error(error.statusText || error.message || error))));
        }

    }, [dispatch, id, request, deposit, error]);

    return (<>

        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4 mb-5">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Detalle Deposito - {deposit?.id}
                </div>
                <BackButton request={false} />
            </div>

            <Spinner hidden={!(request || !deposit) } />
            {(!deposit) ? null : <DepositForm data={deposit} />}
        </Card>
    </>);
}