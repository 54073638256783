export enum transationTypes {
    USER_NOTIFY_CLEAR = 'USER_NOTIFY_CLEAR',
    USER_NOTIFY = 'USER_NOTIFY',

    USERS_GET = 'USERS_GET',
    USERS_GET_SUCCESS = 'USERS_GET_SUCCESS',

    USER_GET = 'USER_GET',
    USER_GET_SUCCESS = 'USER_GET_SUCCESS',

    USER_ADD = 'USER_ADD',
    USER_ADD_SUCCESS = 'USER_ADD_SUCCESS',

    USER_DELETE_GET = 'USER_DELETE_GET',
    USER_DELETE_GET_SUCCESS = 'USER_DELETE_GET_SUCCESS',

    USER_UPDATE_GET = 'USER_UPDATE_GET',
    USER_UPDATE_GET_SUCCESS = 'USER_UPDATE_GET_SUCCESS',

    USER_PERFORMANCE_GET = 'USER_PERFORMANCE_GET',
    USER_PERFORMANCE_GET_SUCCESS = 'USER_PERFORMANCE_GET_SUCCESS',
    USER_PERFORMANCE_GET_ERROR = 'USER_PERFORMANCE_GET_ERROR',

    USER_DOCUMENTS_GET = 'USER_DOCUMENTS_GET',
    USER_DOCUMENTS_GET_SUCCESS = 'USER_DOCUMENTS_GET_SUCCESS',

    USER_DOCUMENT_URL_GET = 'USER_DOCUMENT_URL_GET',
    USER_DOCUMENT_URL_GET_SUCCESS = 'USER_DOCUMENT_URL_GET_SUCCESS',

    USER_KYC_GET = 'USER_KYC_GET',
    USER_KYC_GET_SUCCESS = 'USER_KYC_GET_SUCCESS',


    USER_SET_ERROR = 'USER_SET_ERROR',

    USER_DOCUMENTS_POST = 'USER_DOCUMENTS_POST',
    USER_DOCUMENTS_POST_SUCCESS = 'USER_DOCUMENTS_POST_SUCCESS',
};
